<template>
    <main class="ui-view" id="show-folder-view" v-if="folder">
        <div class="empty-folder-background-container">
            <img v-if="isEmpty" src="@/assets/images/emptyfolder.png" class="empty-folder-background" alt="" />
        </div>
        <ui-view-header
            :title="folder.name"
            :editable-title="isCTE || isClientAdmin"
            @title-updated="renameFolder"
            :enable-search="true"
            v-model="searchFilter"
        >
            <template #header-tools>
                <ui-dropdown
                    id="view-header-actions-list"
                    class="view-header-action"
                    toggle-class="ui-action-button"
                    :icon="true"
                    :caret="false"
                    menu-placement="bottom-end"
                >
                    <template #dropdown-toggle>
                        <svg-icon icon="menu-icon" />
                    </template>
                    <ui-dropdown-item @click="!!selectedVideos.length ? unselectAll() : selectAll()">
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="check-all-icon" />
                            {{ !!selectedVideos.length ? $t('Unselect all') : $t('Select all') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item :disabled="!selectedVideos.length" @click="produceAll">
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="rocket-icon" />
                            {{ $t('Produce') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item :disabled="!selectedVideos.length" @click="translate">
                        <template #dropdown-menu-item-label>
                            <svg-icon style="width: 1.5rem" icon="language-ia-regular-icon" />
                            {{ $t('actions.translate') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item :disabled="!selectedVideos.length" @click="duplicateAll">
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="clone-icon" />
                            {{ $t('Duplicate') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item
                        v-if="isCTE || isClientAdmin"
                        :disabled="!selectedVideos.length"
                        @click="deleteAll"
                    >
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="trash-icon" />
                            {{ $t('Delete videos') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item
                        v-if="isCTE || isClientAdmin"
                        classes="ui-dropdown-item-danger"
                        @click="deleteFolder"
                    >
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="trash-icon" />
                            {{ $t('Delete folder') }}
                        </template>
                    </ui-dropdown-item>
                </ui-dropdown>
            </template>
        </ui-view-header>
        <div v-if="isEmpty" class="empty-folder-container">
            <div class="empty-folder-text">{{ $t('Your folder is empty!') }}</div>
            <div class="empty-folder-create-video">
                <empty-folder-create-video-button />
            </div>
        </div>
        <div class="ui-view-items-list">
            <ui-infinite-scroll-paginator
                v-model="page"
                :max-page="totalPages"
                :loading="isLoading"
                :use-scrollable="false"
            >
                <selectable-videos-list v-model="videos" :folder="folder" />
                <template v-slot:loading>
                    <div class="folder-loader-container">
                        <LoadingMarker v-if="isLoading" />
                    </div>
                </template>
            </ui-infinite-scroll-paginator>
        </div>
    </main>
</template>

<script setup>
import '@/sass/views/show-folder-view.scss';
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import { onBeforeRouteUpdate, useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { UiDropdown, UiDropdownItem } from '@/js/components/index.js';
import UiViewHeader from '@/js/components/UiViewHeader.vue';
import FoldersDataService from '@/js/application/services/folders/FoldersDataService.js';
import { subscribeToChannel } from '@/js/utils.js';
import { FOLDER_CHANNEL, VIDEO_PRODUCTION_UPDATED } from '@/js/constants/index.js';
import FetchVideoDataService from '@/js/application/services/video/FetchVideoDataService.js';
import { useFolders } from '@/js/composables/useFolders.js';
import EmptyFolderCreateVideoButton from '@/views/ShowFolderView/components/EmptyFolderCreateVideoButton.vue';
import SelectableVideosList from '@/views/ShowFolderView/components/SelectableVideosList.vue';
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
import { useVideos } from '@/js/composables/useVideos.js';
import { useModal } from '@/js/composables/useModal.js';
import ProduceVideoService from '@/js/application/services/video/ProduceVideoService.js';
import LoadingMarker from '@/js/components/LoadingMarker.vue';
import { debounce } from 'lodash';
import { useAuth } from '@/js/composables/useAuth.js';
import FolderSubscribeService from '@/js/application/services/folders/FolderSubscribeService.js';
import VideoDTO from '@/js/dto/VideoDTO.js';

const route = useRoute();
const router = useRouter();
const store = useStore();
const { isCTE, isClientAdmin } = useAuth();

const { openModal } = useModal();

const folder = ref(route.meta.folder);
const { currentActiveFolder } = useFolders();
const searchFilter = ref('');

const { page, totalPages, videos, isLoading, fetchVideos, resetDataForVideos } = useVideos();

const options = computed(() => ({
    page: page.value,
    search: searchFilter.value
}));

const isEmpty = computed(() => videos.value.length === 0 && !isLoading.value && !searchFilter.value);

const selectedVideos = computed(() => videos.value.filter((v) => v.selected));

const subscribeFolderChannel = () => {
    subscribeToChannel(FOLDER_CHANNEL, { id: folder.value.publicId }, 'private', {
        [VIDEO_PRODUCTION_UPDATED]: (event) => {
            new FetchVideoDataService(store).getVideoData(event.producible_id).then((newVideo) => {
                refreshVideoData(newVideo);
            });
        }
    });
};

const subscribeHardResetChannel = () => {
    new FolderSubscribeService().subscribeVideos(folder.value.publicId, function (event) {
        if (event.action === 'insert') {
            new FetchVideoDataService(store).getVideoData(event.content).then((newVideo) => {
                insertVideo(newVideo);
            });
        } else if (event.action === 'hard_refresh') {
            resetDataForVideos();
            fetchVideos(folder.value.publicId, options.value);
        }
    });
};

const refreshVideoData = (video) => {
    videos.value = videos.value.map((v) => (v.id === video.id ? video : v));
};

const unselectAll = () => {
    videos.value.map((video) => {
        video.selected = false;
    });
};

const selectAll = () => {
    videos.value.map((video) => {
        video.selected = true;
    });
};

const deleteAll = () => {
    openModal('ConfirmDeleteVideosModal', {
        videos: selectedVideos.value,
        callback: (deletedVideo) => {
            videos.value = videos.value.filter((v) => v.id !== deletedVideo.id);
        },
        finalCallback: unselectAll
    });
};

const deleteFolder = () => {
    openModal('ConfirmDeleteFolderModal', { shortname: folder.value.shortname });
};

const duplicateAll = () => {
    openModal('ConfirmDuplicateVideosModal', {
        videos: selectedVideos.value,
        callback: (newVideo) => {
            videos.value = [newVideo, ...videos.value];
        }
    });
};

const insertVideo = (video) => {
    videos.value = [video, ...videos.value];
};

const renameFolder = (name) => {
    new FoldersDataService(store).updateFolderName(folder.value.shortname, name);
};

const produceVideo = (video) => {
    video.isProducing = true;
    new ProduceVideoService(store).handle(video.id).catch(() => {
        video.isProducing = false;
    });
};

const produceAll = () => {
    selectedVideos.value.forEach((video) => {
        produceVideo(video);
    });
};

const translate = () => {
    openModal('TranslateModal', {
        type: 'videos',
        elements: selectedVideos.value
    });
};

const debouncedSearch = debounce(() => {
    resetDataForVideos();
    fetchVideos(folder.value.publicId, options.value);
}, 500);

onBeforeRouteUpdate(async (to, from) => {
    new FoldersDataService(store)
        .fetchByShortname(to.params.shortname)
        .then((newFolder) => {
            folder.value = newFolder;
            currentActiveFolder.value = newFolder.publicId;
        })
        .catch(() => {
            router.push({ name: '404' });
        });
});

watch(page, () => {
    fetchVideos(folder.value.publicId, options.value);
});

watch(folder, () => {
    subscribeFolderChannel();
    subscribeHardResetChannel();
    resetDataForVideos();
    fetchVideos(folder.value.publicId, options.value);
});

watch(searchFilter, () => {
    debouncedSearch();
});

onMounted(() => {
    subscribeFolderChannel();
    subscribeHardResetChannel();

    currentActiveFolder.value = folder.value.publicId;
    fetchVideos(folder.value.publicId, options.value);
});

onUnmounted(() => {
    currentActiveFolder.value = null;
});
</script>
