<template>
    <div class="ui-main-modal-container" :class="{ 'ui-main-modal-lock': disableClose }" v-if="opened">
        <div class="ui-main-modal-overlay" @click="close()" />
        <div class="ui-main-modal">
            <dynamic-modal-loader />
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import DynamicModalLoader from '@/js/components/modals/DynamicModalLoader.vue';

const store = useStore();
const opened = computed(() => store.getters['modals/opened']);
const disableClose = computed(() => store.getters['modals/disableClose']);

const close = () => {
    if (!disableClose.value) {
        store.dispatch('modals/closeModal');
    }
};
</script>
