<template>
    <div class="preview-action-produce" v-tooltip.light="!!tooltipKey && $t(tooltipKey)">
        <button
            class="ui-action-button preview-action preview-action-alt"
            :disabled="!canProduce"
            @click="manageProduction"
        >
            <span class="action-icon">
                <svg-icon v-if="isProducing" class="loader" icon="circle-loader" />
                <fa-icon v-else class="icon" icon="fa-regular fa-arrow-down-to-line" />
            </span>
        </button>
        <div :class="previewProductionStatusClass"></div>
    </div>
</template>

<script setup>
import {
    PRODUCTION_STATUS_PENDING,
    PRODUCTION_STATUS_PRODUCED,
    PRODUCTION_STATUS_PRODUCING,
    STATUS_SAVED,
    STATUS_SAVING,
    VALIDATION_STATUS_NONE,
    VALIDATION_STATUS_REJECTED,
    VALIDATION_STATUS_SUBMITTED,
    VALIDATION_STATUS_VALIDATED
} from '../../../../constants';
import { computed, onMounted, onUnmounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import FetchVideoDataService from '../../../../application/services/video/FetchVideoDataService.js';
import ProduceVideoService from '../../../../application/services/video/ProduceVideoService';
import SubscribeVideoChannelService from '../../../../application/services/video/SubscribeVideoChannelService';
import { useModal } from '@/js/composables/useModal.js';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import { useAuth } from '@/js/composables/useAuth.js';

const vTooltip = UsesTooltip.directives.tooltip;
const store = useStore();
const route = useRoute();
const { user } = useAuth();
const { openModal } = useModal();

const saveStatus = computed(() => store.state.ui.saveStatus);
const videoId = computed(() => route.params.id);
const video = computed(() => store.getters['videos/currentVideo']);

const isProducing = computed(() => {
    if (!video.value) return false;
    return (
        video.value.isProducing ||
        video.value.productionStatus === PRODUCTION_STATUS_PENDING ||
        video.value.productionStatus === PRODUCTION_STATUS_PRODUCING
    );
});

const canProduce = computed(
    () =>
        !isProducing.value &&
        (!user.value.needsValidation || video.value?.validationStatus === VALIDATION_STATUS_VALIDATED)
);

const tooltipKey = computed(() => {
    if (!video.value) return null;
    if (isProducing.value) return 'production.in-progress';
    if (isProduced.value) return productionIsUpToDate.value ? 'production.download' : 'production.video-edited';

    if (user.value.needsValidation) {
        if (video.value.validationStatus === VALIDATION_STATUS_NONE) return 'video.validation.required';
        if (video.value.validationStatus === VALIDATION_STATUS_SUBMITTED) return 'video.validation.awaiting';
        if (video.value.validationStatus === VALIDATION_STATUS_REJECTED) return 'video.validation.rejected';
    }

    return 'production.produce';
});

const previewProductionStatusClass = computed(() => {
    if (productionIsUpToDate.value) {
        return 'preview-production-status preview-production-status--latest-version';
    } else if (isProduced.value) {
        return 'preview-production-status preview-production-status--old-version';
    }
    return 'preview-production-status';
});

const isProduced = computed(() => {
    return !!(video.value && video.value.productionStatus === PRODUCTION_STATUS_PRODUCED);
});

const productionIsUpToDate = computed(() => {
    return !!(isProduced.value && video.value.updatedAt <= video.value.producedAt);
});

const fetchVideoData = () => {
    if (videoId.value) {
        new FetchVideoDataService(store).handle(videoId.value);
    }
};

const manageProduction = () => {
    if (isProduced.value) {
        openExportModal();
    } else if (!isProducing.value) {
        produceVideo();
    }
};

const produceVideo = () => {
    if (video.value.isProducing) return;

    video.value.isProducing = true;
    new ProduceVideoService(store).handle(video.value.id).catch(() => {
        video.value.isProducing = false;
    });
};

const initChannels = () => {
    if (videoId.value) {
        new SubscribeVideoChannelService(store).handle(videoId.value);
    }
};

const openExportModal = () => {
    openModal('ExportVideoModal', { video });
};

watch(saveStatus, (newStatus, oldStatus) => {
    if (newStatus === STATUS_SAVED && oldStatus === STATUS_SAVING) {
        fetchVideoData();
    }
});

onMounted(() => {
    fetchVideoData();
    initChannels();
});

onUnmounted(() => {
    store.dispatch('videos/clearCurrentVideo');
});
</script>
