<template>
    <div class="play-button-container">
        <button class="play-button" @click="handlePlayToggle" :aria-label="buttonLabel">
            <svg-icon :icon="buttonIcon"></svg-icon>
        </button>
    </div>
</template>

<script setup lang="ts">
import { computed, watch, inject, withDefaults, Ref, ref } from 'vue';
import { usePreview } from '@/js/videos/composables/usePreview';
import { useDisplay } from '@/js/videos/composables/useDisplay';
import { useTimeline } from '@/js/videos/composables/useTimeline';
import type { VideoStudio } from '@/js/video-studio/types';

interface PlayButtonProps {
    currentTime: number;
    videoDuration: number;
    startTime?: number;
    isDragging?: boolean;
}

const props = withDefaults(defineProps<PlayButtonProps>(), {
    startTime: 0.001,
    isDragging: false
});

const videoStudio = inject<Ref<VideoStudio>>('$videoStudio');
if (!videoStudio) throw new Error('VideoStudio not provided');

const { currentTimelineId, isMainTimeline } = usePreview();
const { playing, labels } = useDisplay();
const { isEndSequencePause, setEndSequencePause, toggleTimeline } = useTimeline(currentTimelineId, labels);

const buttonIcon = computed(() => (playing.value ? 'pause-icon' : 'play-icon'));
const buttonLabel = computed(() => (playing.value ? 'Pause' : 'Play'));

watch(
    () => props.currentTime,
    () => {
        if (props.isDragging) return;

        if (props.currentTime >= props.videoDuration) {
            videoStudio!.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, props.startTime);
            setEndSequencePause(false);
            toggleTimeline();
        }
    }
);

function handlePlayToggle(): void {
    if (!playing.value && !isMainTimeline.value && isEndSequencePause()) {
        videoStudio!.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, props.startTime);
    }
    setEndSequencePause(false);
    toggleTimeline();
}
</script>

<style scoped>
.play-button-container {
    padding-top: 44px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.play-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
}

.icon {
    width: 24px;
    height: 24px;
    filter: brightness(0) invert(1);
}
</style>
