import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const API_URL = import.meta.env.VITE_EDITION_API_URL;

export default class TranslateApiService {
    constructor(store) {
        this.store = store;
    }

    /**
     * Translate multiple elements of different types
     * @param {string} type - Type of elements to translate (templates, sequenceTemplates, videos)
     * @param {Array} elements - List of elements to translate
     * @param {string} languageId - Target language ID
     * @param {string} [brandId=null] - Optional brand ID (required for templates)
     * @returns {Promise<Array>} - Array of translation promises
     */
    async translateBatchedElements(type, elements, languageId, brandId = null) {
        const translationMethods = {
            templates: (element) => this.translateTemplate(element.id, languageId, brandId),
            sequenceTemplates: (element) => this.translateSequenceTemplate(element.id, languageId),
            videos: (element) => this.translateVideo(element.id, languageId)
        };

        // Validate input
        if (!translationMethods[type]) {
            throw new Error(`Unsupported translation type: ${type}`);
        }

        // Use Promise.all for concurrent translations with error handling
        return Promise.allSettled(elements.map((element) => translationMethods[type](element)));
    }

    /**
     * Fetch available translation options
     * @returns {Promise<Object>} Translation options
     */
    async fetchTranslateOptions() {
        return await executeRequest(this.store, {
            request: async (client) => client.get(`${API_URL}/api/translate/options`),
            onSuccess: (response) => response.data,
            actionType: 'fetchTranslateOptions'
        });
    }

    /**
     * Translate a template to a specific language
     * @param {string} templateId - ID of the template to translate
     * @param {string} languageId - Target language ID
     * @param {string} brandId - Brand ID associated with the template
     * @returns {Promise<Object>} Translation result
     */
    async translateTemplate(templateId, languageId, brandId) {
        if (!brandId) {
            throw new Error('Brand ID is required for template translation');
        }

        return await executeRequest(this.store, {
            request: async (client) =>
                client.post(`${API_URL}/api/brands/${brandId}/templates/${templateId}/translate?lang=${languageId}`),
            onSuccess: (response) => response.data,
            onError: (error) => {
                console.error('Error translating template', { templateId, languageId, error });
                throw error;
            },
            actionType: 'translateTemplate'
        });
    }

    /**
     * Translate a video to a specific language
     * @param {string} videoId - ID of the video to translate
     * @param {string} languageId - Target language ID
     * @returns {Promise<Object>} Translation result
     */
    async translateVideo(videoId, languageId) {
        return await executeRequest(this.store, {
            request: async (client) => client.post(`${API_URL}/api/videos/${videoId}/translate?lang=${languageId}`),
            onSuccess: (response) => response.data,
            onError: (error) => {
                console.error('Error translating video', { videoId, languageId, error });
                throw error;
            },
            actionType: 'translateVideo'
        });
    }

    /**
     * Translate a sequence template to a specific language
     * @param {string} sequenceId - ID of the sequence template to translate
     * @param {string} languageId - Target language ID
     * @returns {Promise<Object>} Translation result
     */
    async translateSequenceTemplate(sequenceId, languageId) {
        console.log('translateSequenceTemplate', sequenceId, languageId);
        return await executeRequest(this.store, {
            request: async (client) =>
                client.post(`${API_URL}/api/sequences/${sequenceId}/translate?lang=${languageId}`),
            onSuccess: (response) => response.data,
            onError: (error) => {
                console.error('Error translating sequence template', { sequenceId, languageId, error });
                throw error;
            },
            actionType: 'translateSequenceTemplate'
        });
    }
}
