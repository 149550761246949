import { inject } from 'vue';

export function useAssetMedia() {
    const videoStudio = inject('$videoStudio');

    function getAssetMedia(mediaType, sequenceId, elementId = null) {
        const stage = videoStudio.value.studio.$stage;

        if (['visual', 'recordingVisual'].includes(mediaType)) {
            const visualElement = stage.$el.querySelector(`[data-visual-id="${elementId}"]`);
            return visualElement?.querySelector('.asset');
        }

        if (['backgroundVideo', 'recording'].includes(mediaType)) {
            const backgroundElement = stage.getBackgroundElement(sequenceId);
            return backgroundElement?.$refs.$bgVideo;
        }

        if (['audio'].includes(mediaType)) {
            const audioElement = stage.$el.querySelector(`[data-audio-id="$seqTrack-${sequenceId}"]`);
            return audioElement;
        }

        if (['tts'].includes(mediaType)) {
            const ttsElement = stage.$el.querySelector(`[data-audio-id="$seqTTS-${sequenceId}"]`);
            return ttsElement;
        }

        throw new Error('Type de média invalide');
    }

    return {
        getAssetMedia
    };
}
