<template>
    <div class="ui-main-modal-header">
        <div class="ui-main-modal-title">{{ title }}</div>
        <div class="ui-main-modal-nav">
            <slot name="nav-tabs"></slot>
        </div>
        <div class="ui-main-modal-close">
            <div class="ui-main-modal-close-button" @click="close()">
                <fa-icon class="icon" icon="fa-solid fa-xmark" />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useStore } from 'vuex';

defineProps({
    title: {
        type: String,
        default: ''
    }
});

const store = useStore();

const close = () => {
    store.dispatch('modals/closeModal');
};
</script>
