<template>
    <ui-card :id="cardElementId" :class="'ui-card-' + id" :title="$t('Audio')" :added-card-id="addedCardId">
        <template #header-icon>
            <svg-icon icon="audio-icon" />
        </template>
        <template #menu-content>
            <ui-dropdown-item :disabled="!canCopyCard" @click.prevent="copyCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-copy" />
                    {{ $t('Copy') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item :disabled="!canPasteCard" @click.prevent="pasteCard">
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-clipboard" />
                    {{ $t('Paste') }}
                </template>
            </ui-dropdown-item>

            <ui-dropdown-item
                v-if="isEmotionMode"
                classes="ui-remove-item"
                :disabled="!canRemoveAudio"
                @click.prevent="removeCard"
            >
                <template #dropdown-menu-item-label="{ instance }">
                    <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                    {{ $t('Remove') }}
                </template>
            </ui-dropdown-item>
        </template>

        <ui-media-selector
            ref="$mediaSelector"
            :id="cardElementId + '-track'"
            class="ui-card-row"
            key="media-selector-sequence-track"
            v-model:media="track"
            :type="mediaTypeAudio"
            :label="$t('Additional audio')"
            :disabled="readOnly"
            icon="fa-solid fa-volume"
            @[mediaSelectorShowEvent]="openMediaLibrary"
        />

        <ui-range-input
            :id="cardElementId + '-track-volume'"
            class="ui-card-row"
            v-model="trackVolume"
            :label="$t('Volume')"
            :disabled="readOnly"
        />

        <template #footer>
            <ui-caption-button
                :id="cardElementId + '-track-captions'"
                v-model="trackCaptions"
                :captioning="trackCaptioning"
                :status="sequenceState.audio.track.captioningStatus"
                :modifier="sequenceStoreModulePath + '/setTrackCaptions'"
                :label="$t('Captions')"
                :disabled="readOnly || isMediaConverting || !track.src"
                :timerange="trackRange"
                :tooltip="
                    trackCaptioning
                        ? $t('Captioning in progress...')
                        : isMediaConverting
                          ? $t('media.preparation.warning')
                          : null
                "
                @[captionButtonShowEvent]="handleCaptioning"
                @[captionButtonCaptioningChangeEvent]="trackCaptioning = $event"
                :toggle-style="toggleCaptionAudioStyle"
            />

            <!-- TODO: this causes a bug to toggleClass where the dropdown stays shown after closing so we use toggleStyle -->
            <button
                class="ui-caption-button ui-caption-button-icon-only"
                :style="toggleQuickCutStyle"
                :disabled="readOnly || isMediaConverting || !track.src"
                v-tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
                @click="openQuickCutTimeline"
            >
                <div style="margin-left: 0.5rem">
                    <span>{{ $t('Quick cut') }}</span>
                </div>
                <svg-icon icon="scissors-icon" />
            </button>

            <ui-dropdown
                :id="cardElementId + '-track-timing'"
                class="centered timing-dropdown"
                :menu="false"
                :caret="false"
                :scrollable="false"
                :disabled="readOnly || isMediaConverting || !track.src"
                :tooltip="isMediaConverting ? $t('media.preparation.warning') : ''"
                menu-placement="bottom"
                :toggle-style="toggleTimingAudioStyle"
            >
                <template #dropdown-toggle>
                    <fa-icon class="icon" icon="fa-regular fa-stopwatch" />
                    <span>{{ $t('Timing') }}</span>
                </template>
                <ui-number-input
                    :id="cardElementId + '-track-start'"
                    class="ui-content-row"
                    v-model="trackStart"
                    :default-value="trackStartDefault"
                    :min="0"
                    :label="$t('Starting time')"
                    :disabled="readOnly"
                />
            </ui-dropdown>
        </template>
    </ui-card>
</template>

<script>
import { mapGetters } from 'vuex';
import { Duration } from 'cte-video-studio';
import { AUDIO_ELEMENT_ID } from '../../../../../constants';
import { getPrefixedUuid } from '../../../../../utils';
import { SequenceCard } from '../../../../mixins';
import { UsesTooltip } from '@/js/mixins/index.js';

export default {
    mixins: [SequenceCard, UsesTooltip],

    components: {
        //
    },

    props: {
        id: {
            type: String,
            default: AUDIO_ELEMENT_ID
        },

        addedCardId: {
            type: String,
            default: ''
        }
    },

    data() {
        return {
            trackStartDefault: Duration.START_DEFAULT
        };
    },

    computed: {
        ...mapGetters({
            getMediaSrc: 'ui/quickcut/getMediaSrc'
        }),

        track: {
            get() {
                return {
                    src: this.sequenceState.audio.track.src,
                    id: this.sequenceState.audio.track.src__id
                };
            },
            set({ src, id }) {
                // reset  timerange segments and timerange if the video source is changed for UiQuickCut
                if (this.getMediaSrc !== src) {
                    this.$store.commit(`${this.sequenceStoreModulePath}/setTrackTimerangeSegments`, []);
                    this.$store.commit(`${this.sequenceStoreModulePath}/setTrackTimerange`, { start: 0, end: 0 });
                }
                this.saveHistoryStep(() => {
                    if (!this.sequenceState.audio.track.src__ref) {
                        this.$store.commit(
                            this.sequenceStoreModulePath + '/setTrackReference',
                            getPrefixedUuid(this.prefixes.mediaReference)
                        );
                    }
                    this.$store.dispatch(this.sequenceStoreModulePath + '/updateTrack', { src, src__id: id });
                });
            }
        },

        trackVolume: {
            get() {
                return Math.round(100 * this.$store.getters[this.sequenceStoreModulePath + '/trackVolume']);
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setTrackVolume', {
                        value: (value / 100).toFixed(2),
                        custom: null
                    });
                });
            }
        },

        trackCaptions: {
            get() {
                return this.sequenceState.audio.track.captions || [];
            },
            set(value) {
                this.saveHistoryStep(() => {
                    this.$store.commit(this.sequenceStoreModulePath + '/setTrackCaptions', value);
                });
            }
        },

        trackCaptioning: {
            get() {
                return this.sequenceState.audio.track.captioning;
            },
            set(value) {
                if (!!value) {
                    this.$store.dispatch(this.sequenceStoreModulePath + '/startTrackCaptioning', value);
                } else {
                    this.$store.dispatch(this.sequenceStoreModulePath + '/cancelTrackCaptioning');
                }
                this.$store.commit(this.sequenceStoreModulePath + '/setTrackCaptioningStatus', null);
                this.saveVideo();
            }
        },

        trackStart: {
            get() {
                return this.$store.getters[this.sequenceStoreModulePath + '/trackStart'];
            },
            set(value) {
                if (value != this.trackStart) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setTrackStart', {
                            value,
                            custom: Duration.NONE
                        });
                    });
                }
            }
        },

        trackRange: {
            get() {
                return {
                    start: this.sequenceState.audio.track.timerange?.start || 0,
                    end: this.sequenceState.audio.track.timerange?.end || -1
                };
            },
            set({ start, end }) {
                if (start !== this.trackRange.start || end !== this.trackRange.end) {
                    this.saveHistoryStep(() => {
                        this.$store.commit(this.sequenceStoreModulePath + '/setTrackTimerange', { start, end });
                    });
                }
            }
        },

        isActiveTimerange() {
            if (this.sequenceState.audio.track.timerangeSegments.length > 0) {
                return (
                    this.sequenceState.audio.track.timerangeSegments[0].start > 0 ||
                    this.sequenceState.audio.track.timerangeSegments[0].end !==
                        this.sequenceState.audio.track.totalDuration()
                );
            }
            return false;
        },

        hasCaptions() {
            return this.trackCaptions.length > 0 && this.trackCaptions[0].text.length > 0;
        },

        toggleCaptionAudioStyle() {
            if (this.sequenceState.audio.track.captions && this.track.src) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        },

        toggleTimingAudioStyle() {
            if (this.trackStart !== this.trackStartDefault) {
                return { color: 'var(--edit-mode-color)' };
            } else return {};
        },

        toggleQuickCutStyle() {
            return this.isActiveTimerange ? { color: 'var(--edit-mode-color)' } : null;
        },

        isMediaConverting() {
            return this.$store.getters['loading/isConverting'](this.track.src);
        }
    },

    watch: {
        addedCardId(val) {
            if (this.cardElementId === val) this.openMediaLibrary(this.$refs.$mediaSelector);
        }
    },

    methods: {
        removeCard() {
            this.saveHistoryStep(() => {
                this.$store.dispatch(this.sequenceStoreModulePath + '/showAudioCard', false);
            });
        },

        openCaptionEditor(button) {
            this.$store.commit('ui/setCurrentCaptionButton', button);
        },

        startCaptioning() {
            this.$store.dispatch(this.sequenceStoreModulePath + '/startTrackCaptioning');
        },

        handleCaptioning(button) {
            if (this.hasCaptions) this.openCaptionEditor(button);
            else this.startCaptioning();
        },

        openQuickCutTimeline() {
            this.$store.dispatch('ui/quickcut/openQuickCutForAudio', { seqId: this.seqId });
        }
    },

    mounted() {
        if (this.cardElementId === this.addedCardId) this.openMediaLibrary(this.$refs.$mediaSelector);
    }
};
</script>
