<template>
    <div class="ui-video-list-item-container" :class="{ 'is-loading': loading }">
        <div class="ui-video-list-item">
            <div class="video-wrapper" :style="videoWrapperStyle">
                <UiVideoPreview :video="video" />
                <div v-if="loading" class="loading-overlay"></div>
            </div>
            <div class="ui-video-list-item-name">{{ name || video.name }}</div>
            <div class="ui-video-list-item-description" v-if="showDescription">
                <span v-for="(category, index) in video.categories" :key="index">
                    {{ $t(`brands.categories.templates.${category}`) ?? '' }}
                    <span v-if="index < video.categories.length - 1">- </span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent } from 'vue';
import VideoDTO from '@/js/dto/VideoDTO.js';
import TemplateDTO from '@/js/dto/TemplateDTO.js';
import UiVideoPreview from '@/js/components/UiVideoPreview.vue';

export default defineComponent({
    name: 'UiVideoListItem',
    components: { UiVideoPreview },
    props: {
        video: {
            type: [VideoDTO, TemplateDTO],
            required: true
        },
        type: {
            type: String,
            default: 'video'
        },
        showDescription: {
            type: Boolean,
            default: false
        },
        loading: {
            type: Boolean,
            default: false
        },
        name: {
            type: String,
            default: ''
        }
    },
    setup(props) {
        const aspectRatio = computed(() => {
            return (props.video.format.height / props.video.format.width) * 100;
        });

        const videoWrapperStyle = computed(() => {
            return {
                paddingBottom: `${aspectRatio.value}%`
            };
        });

        return {
            videoWrapperStyle
        };
    }
});
</script>

<style scoped>
.ui-video-list-item-container {
    position: relative;
    overflow: visible;
    z-index: 1;
}

.video-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
}

.video-wrapper :deep(.ui-video-preview) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.video-wrapper :deep(.ui-video-preview img),
.video-wrapper :deep(.ui-video-preview video) {
    object-fit: contain;
    width: 100%;
    height: 100%;
}

.is-loading .ui-video-image-preview-wrapper {
    opacity: 0.7;
}

.is-loading .ui-video-list-item-name,
.is-loading .ui-video-list-item-description {
    opacity: 0.5;
}
</style>
