import { subscribeToChannel } from '@/js/utils.js';
import { FOLDER_CHANNEL, FOLDER_VIDEOS_CHANNEL } from '@/js/constants/index.js';

export default class FolderSubscribeService {
    subscribeVideos(folderId, callback) {
        subscribeToChannel(FOLDER_CHANNEL, { id: folderId }, 'private', {
            [FOLDER_VIDEOS_CHANNEL]: (data) => callback(data)
        });
    }
}
