/**
 * Edit video Branding libraries Vuex store extension
 */

import axios from 'axios';
import {
    DEFAULT_TEMPLATE_LANGUAGE,
    LIBRARY_SORT_UPDATED_AT,
    LIBRARY_STATUS_ACTIVABLE,
    LIBRARY_STATUS_ACTIVE,
    LIBRARY_STATUS_HIDDEN,
    ZOOM_TARGET_LIBRARY
} from '@/js/constants';
import { maybeRefreshLogin, requestWithServiceTokenRefresh } from '@/js/utils';

const originalState = () => ({
    logoAnimations: [],

    backgroundAnimations: [],

    overlays: [],

    panels: [],

    transitions: [],

    fonts: [],

    messages: [],

    visuals: [],

    musics: [],

    zoomTargets: [...ZOOM_TARGET_LIBRARY],

    sequenceTemplates: [],

    templates: [],

    voices: [],

    tagOrders: {
        logoAnimations: [],
        backgroundAnimations: [],
        overlays: [],
        panels: [],
        transitions: [],
        fonts: [],
        messages: [],
        visuals: [],
        musics: [],
        zoomTargets: [],
        sequenceTemplates: [],
        voices: []
    },

    loaded: {
        logoAnimations: false,
        backgroundAnimations: false,
        overlays: false,
        panels: false,
        transitions: false,
        fonts: false,
        messages: false,
        visuals: false,
        musics: false,
        zoomTargets: true,
        sequenceTemplates: false
    },

    filters: {
        sequenceTemplates: '',
        templates: ''
    },

    sortTypes: {
        sequenceTemplates: LIBRARY_SORT_UPDATED_AT,
        templates: LIBRARY_SORT_UPDATED_AT
    },

    languages: {
        templates: DEFAULT_TEMPLATE_LANGUAGE
    },

    selections: {
        sequenceTemplates: [],
        templates: []
    }
});

const state = {
    ...originalState()
};

const getters = {
    libraryIsLoaded: (state) => (library) => !!state.loaded[library],

    libraryItems: (state) => (library, category) => {
        if (!state[library]) return [];

        return state[library].filter((item) => !category || item.categories.includes(category));
    },

    findLibraryItem: (state, getters) => (library, category, name) => {
        if (!state[library]) return;

        return state[library].find((item) => (!category || item.categories.includes(category)) && item.name == name);
    },

    findLibraryItemById: (state) => (library, category, id) => {
        if (!state[library]) return;

        return state[library].find((item) => (!category || item.categories.includes(category)) && item.id == id);
    },

    librarySortedByTag: (state) => (library, category) => {
        if (!state[library]) return {};

        return state[library].reduce((sorted, item) => {
            if (!category || item.categories.includes(category)) {
                item.tags.forEach((tag) => {
                    if (!sorted[tag]) sorted[tag] = [];
                    sorted[tag].push(item);
                });
            }

            return sorted;
        }, {});
    },

    libraryFiltered:
        (state) =>
        (library, category, properties = []) => {
            if (!state[library]) return [];

            let filters = /\S/.test(state.filters[library])
                ? state.filters[library].split(/\s+/).filter((f) => f.length > 1)
                : [];

            return state[library].filter(
                (item) =>
                    (!category || item.categories.includes(category)) &&
                    (!state.languages[library] || item.language === state.languages[library]) &&
                    filters.every((filter) =>
                        properties.some((prop) => item[prop]?.toLowerCase().includes(filter.toLowerCase()))
                    )
            );
        },

    libraryItemIsSelected: (state) => (library, id) =>
        !!state.selections[library] && state.selections[library].includes(id),

    libraryItemsCount: (state) => (libraries, status, languages) => {
        return libraries.reduce((count, library) => {
            return (
                count +
                (!state[library]
                    ? 0
                    : state[library].filter(
                          (item) =>
                              (status === undefined || item.status == status) &&
                              (languages === undefined ||
                                  !state.languages.hasOwnProperty(library) ||
                                  languages.includes(item.language))
                      ).length)
            );
        }, 0);
    },

    libraryTags: (state, getters) => (library, category) => Object.keys(getters.librarySortedByTag(library, category)),

    libraryAvailableLanguages: (state, getters, rootState) => (library, category) => {
        if (!state[library]) return [DEFAULT_TEMPLATE_LANGUAGE];

        if (!state.languages[library]) return [];

        let availableLanguages = state[library].reduce((languages, item) => {
            if (
                (!category || item.categories.includes(category)) &&
                !!item.language &&
                !languages.includes(item.language) &&
                rootState.branding.enabledTemplateLanguages.includes(item.language)
            ) {
                languages.push(item.language);
            }
            return languages;
        }, []);

        return !!availableLanguages.length
            ? availableLanguages
            : [
                  rootState.branding.enabledTemplateLanguages.includes(DEFAULT_TEMPLATE_LANGUAGE)
                      ? DEFAULT_TEMPLATE_LANGUAGE
                      : rootState.branding.enabledTemplateLanguages[0]
              ];
    }
};

const mutations = {
    setLibrary(state, { name, library }) {
        state[name] = library;
        state.loaded[name] = true;
    },

    unloadLibrary(state, name) {
        state[name] = [];
        state.loaded[name] = false;
    },

    setLibraryItemStatus(state, { library, id, status }) {
        let index = state[library].findIndex((item) => item.id == id);
        if (index != -1) state[library].splice(index, 1, { ...state[library][index], status });
    },

    setLibrarySelection(state, { library, selection }) {
        state.selections[library] = selection;
    },

    setLibraryItemSelection(state, { library, id, selected }) {
        let index = state.selections[library].indexOf(id);
        if (index == -1 && selected) state.selections[library].push(id);
        else if (index != -1 && !selected) state.selections[library].splice(index, 1);
    },

    addLibraryItem(state, { library, item }) {
        state[library].push(item);
    },

    removeLibraryItem(state, { library, id }) {
        let index = state[library].findIndex((item) => item.id == id);
        if (index != -1) state[library].splice(index, 1);
    },

    updateLibraryItem(state, { library, id, data }) {
        let index = state[library].findIndex((item) => item.id == id);
        if (index != -1) state[library].splice(index, 1, { ...state[library][index], ...data });
    },

    setTagOrder(state, { name, order }) {
        state.tagOrders[name] = order;
    },

    setFilter(state, { name, filter }) {
        state.filters[name] = filter;
    },

    setSortType(state, { name, type }) {
        state.sortTypes[name] = type;
    },

    setLanguage(state, { name, language }) {
        state.languages[name] = language;
    }
};

const actions = {
    init({ commit, state, rootState, getters }, libraries) {
        Object.keys(libraries).forEach((name) => {
            commit('setLibrary', { name, library: libraries[name] });
        });
        Object.keys(state.languages).forEach((name) => {
            let languages = getters.libraryAvailableLanguages(name, false);
            commit('setLanguage', {
                name,
                language:
                    !!rootState.ui && languages.includes(rootState.ui.shortLanguage)
                        ? rootState.ui.shortLanguage
                        : languages[0]
            });
        });
    },

    async loadLibrary(context, name) {
        let { state, rootState, rootGetters, commit } = context;

        if (rootState.ui && !!rootState.ui.urls.libraries[name]) {
            commit('unloadLibrary', name);

            let params = {
                format: rootGetters['display/formatString']
            };

            return await requestWithServiceTokenRefresh(
                async () => {
                    let response = await axios.get(rootState.ui.urls.libraries[name], {
                        params,
                        withCredentials: true
                    });
                    commit('setLibrary', { name, library: response.data });
                },
                (refreshError) => {
                    console.log('[Error] Retrieving brand ' + name + ' library: ' + refreshError.message);
                    if (!!refreshError.response?.data?.token) {
                        maybeRefreshLogin(context, refreshError.response.data.token);
                    } else if (!!refreshError.response?.data?.redirect) {
                        commit('setAuthError', refreshError.response.data, { root: true });
                        window.location.assign(refreshError.response.data.redirect);
                    } else {
                        // TODO: Display an error message on library component?
                    }

                    throw refreshError;
                },
                context
            );
        }
    },

    hideItem({ commit }, { library, id }) {
        commit('setLibraryItemStatus', { library, id, status: LIBRARY_STATUS_HIDDEN });
    },

    deactivateItem({ commit }, { library, id }) {
        commit('setLibraryItemStatus', { library, id, status: LIBRARY_STATUS_ACTIVABLE });
    },

    activateItem({ commit }, { library, id }) {
        commit('setLibraryItemStatus', { library, id, status: LIBRARY_STATUS_ACTIVE });
    },

    toggleItem({ commit, getters }, { library, id, force }) {
        commit('setLibraryItemSelection', {
            library,
            id,
            selected: force !== undefined ? force : !getters.libraryItemIsSelected(library, id)
        });
    },

    selectItem({ commit }, { library, id }) {
        commit('setLibraryItemSelection', { library, id, selected: true });
    },

    unselectItem({ commit }, { library, id }) {
        commit('setLibraryItemSelection', { library, id, selected: false });
    },

    addItem({ rootState, commit }, { library, item, applyToOriginal }) {
        commit('addLibraryItem', { library, item });

        if (applyToOriginal) {
            let original = rootState.ui.originalState();
            original.libraries[library].push(item);
        }
    },

    removeItem({ rootState, commit, dispatch }, { library, id, applyToOriginal }) {
        commit('removeLibraryItem', { library, id });

        if (applyToOriginal) {
            let original = rootState.ui.originalState(),
                itemIndex = original.libraries[library].findIndex((item) => item.id == id);

            if (itemIndex != -1) {
                original.libraries[library].splice(itemIndex, 1);
                dispatch('ui/updateOriginalState', original, { root: true });
            }
        }
    },

    updateItem({ rootState, commit, dispatch }, { library, id, data, applyToOriginal }) {
        commit('updateLibraryItem', { library, id, data });

        if (applyToOriginal) {
            let original = rootState.ui.originalState(),
                itemIndex = original.libraries[library].findIndex((item) => item.id == id);

            if (itemIndex != -1) {
                original.libraries[library].splice(itemIndex, 1, {
                    ...original.libraries[library][itemIndex],
                    ...data
                });
                dispatch('ui/updateOriginalState', original, { root: true });
            }
        }
    },

    setTagOrders({ commit }, tagOrders) {
        Object.keys(tagOrders).forEach((name) => {
            commit('setTagOrder', { name, order: tagOrders[name] });
        });
    },

    clearState({ state }) {
        Object.assign(state, originalState());
    }
};

export default {
    namespaced: true,

    modules: {
        //
    },

    state,
    getters,
    mutations,
    actions
};
