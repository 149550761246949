import { useStore } from 'vuex';
import { computed } from 'vue';

export function useEditingElement() {
    const store = useStore();

    const editingElement = computed(() => store.state.edition.editingElement);

    const isVideo = computed(() => editingElement.value && !!editingElement.value.videoSrc);

    const isConverting = computed(
        () => editingElement.value && store.getters['loading/isConverting'](editingElement.value.videoSrc)
    );

    return {
        editingElement,
        isVideo,
        isConverting
    };
}
