import UserDTO from '@/js/dto/UserDTO.js';
import UserApiService from '@/js/infrastructure/api/user/UserApiService.js';
import PaginatedResultDTO from '@/js/dto/PaginatedResultDTO.js';

export default class UserDataService {
    constructor(store) {
        this.userApiService = new UserApiService(store);
    }

    async update(params) {
        let response = await this.userApiService.put(params);
        return new UserDTO(response.data);
    }

    async getFolders(userId, options) {
        let data = (await this.userApiService.getFolders(userId, options)).data;
        return new PaginatedResultDTO(data);
    }

    async authorizeFolder(uuid, folderShortname, isAllowed) {
        return await this.userApiService.postAuthorizeFolder(uuid, folderShortname, isAllowed);
    }

    async getNbVideos(id) {
        return (await this.userApiService.getNbVideos(id)).data;
    }

    async loginAs(id) {
        return await this.userApiService.loginAs(id);
    }

    async invite(data) {
        try {
            return await this.userApiService.invite(data);
        } catch (err) {
            return { error: true };
        }
    }

    async delete(userUuid) {
        return await this.userApiService.delete(userUuid);
    }

    async setValidators(userUuid, userIds) {
        return await this.userApiService.setValidators(userUuid, userIds);
    }
}
