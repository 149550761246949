// Formats time in seconds to "mm:ss.ms" format
interface TimeFormatOptions {
    includeFrames?: boolean;
    padFrames?: boolean;
}

export function formatTime(time: number, rate?: number, frames?: number, options: TimeFormatOptions = {}): string {
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = Math.floor(time % 60)
        .toString()
        .padStart(2, '0');

    if (!options.includeFrames) {
        const ms = Math.floor((time % 1) * 100)
            .toString()
            .padStart(2, '0');
        return `${minutes}:${seconds}.${ms}`;
    }

    if (frames === undefined && rate) {
        frames = Math.round((time % 1) * rate);
    }

    if (frames !== undefined) {
        const frameStr =
            options.padFrames && rate && rate > 100
                ? frames.toString().padStart(3, '0')
                : frames.toString().padStart(2, '0');
        return `${minutes}:${seconds}.${frameStr}`;
    }

    return `${minutes}:${seconds}.00`;
}
