// UI constants

export const REFERENCE_KEY_SUFFIX = '__ref';
export const REFERENCE_ID_KEY_SUFFIX = '__id';

export const EMOTION_EDITION_MODE = 'emotion';
export const CONTENT_EDITION_MODE = 'content';

export const isEditionMode = function (mode) {
    return mode == EMOTION_EDITION_MODE || mode == CONTENT_EDITION_MODE;
};

export const EDITION_MODE_CLASS_PREFIX = 'edit-mode-';
export const UI_COMPONENT_PREFIX = 'ui-';

export const STATUS_NO_CHANGE = 'no-change';
export const STATUS_UNSAVED = 'unsaved';
export const STATUS_SAVING = 'saving';
export const STATUS_SAVED = 'saved';
export const STATUS_SAVE_ERROR = 'save-error';
export const STATUS_REFRESHING_TOKEN = 'refreshing-token';
export const STATUS_AUTH_ERROR = 'auth-error';

export const isSaveStatus = function (status) {
    return (
        [
            STATUS_NO_CHANGE,
            STATUS_UNSAVED,
            STATUS_SAVING,
            STATUS_SAVED,
            STATUS_SAVE_ERROR,
            STATUS_REFRESHING_TOKEN,
            STATUS_AUTH_ERROR
        ].indexOf(status) != -1
    );
};

export const SETTINGS_BRAND_PANEL_ID = 'settings';
export const COMPONENTS_BRAND_PANEL_ID = 'components';
export const SEQUENCES_BRAND_PANEL_ID = 'sequences';
export const TEMPLATES_BRAND_PANEL_ID = 'templates';

export const isBrandPanel = function (panel) {
    return (
        [
            SETTINGS_BRAND_PANEL_ID,
            COMPONENTS_BRAND_PANEL_ID,
            SEQUENCES_BRAND_PANEL_ID,
            TEMPLATES_BRAND_PANEL_ID
        ].indexOf(panel) != -1
    );
};

export const DRAGGING_CLASS = 'edit-dragging';

export const SETTINGS_ITEM_ID = 'settings';

export const SEQUENCE_ELEMENT_ID = 'sequence';
export const MESSAGE_ELEMENT_ID = 'message';
export const VISUAL_ELEMENT_ID = 'visual';
export const PANEL_ELEMENT_ID = 'panel';
export const AUDIO_ELEMENT_ID = 'audio';
export const TRANSITION_ELEMENT_ID = 'transition';
export const FOOTER_ELEMENT_ID = 'footer-settings';
export const TTS_ELEMENT_ID = 'tts';
export const VIDEO_ELEMENT_ID = 'video';
export const CARTOON_ELEMENT_ID = 'cartoon';
export const STICKER_ELEMENT_ID = 'sticker';
export const ICON_ELEMENT_ID = 'icon';
export const MOSAIC_ELEMENT_ID = 'mosaic';
export const IMAGE_ELEMENT_ID = 'image';
export const RECORDING_ELEMENT_ID = 'recording';

export const SEQUENCE_CLIPBOARD_TYPE = 'sequence';
export const CARD_CLIPBOARD_TYPE = 'card:';

export const MEDIA_CAPTIONING_STATUS_POLLING_TIME = 5000; // Unit: ms
export const CAPTIONS_STATUS_DISPLAY_TIME = 5000; // Unit: ms
export const CAPTIONS_STATUS_COMPLETED = 'captions-completed';
export const CAPTIONS_STATUS_ERROR = 'captions-error';

export const TTS_STATUS_COMPLETED = 'tts-completed';
export const TTS_STATUS_ERROR = 'tts-error';
export const TTS_STATUS_PROCESSING = 'tts-processing';
export const TTS_STATUS_TEXT_ERROR = 'text';
export const TTS_STATUS_VOICE_ID_ERROR = 'voice_id';

export const isCaptionsStatus = function (status) {
    return [CAPTIONS_STATUS_COMPLETED, CAPTIONS_STATUS_ERROR].indexOf(status) != -1;
};

export const SEQUENCE_TEMPLATE_STATUS_DISPLAY_TIME = 5000; // Unit: ms
export const SEQUENCE_TEMPLATE_STATUS_COMPLETED = 'sequence-template-completed';
export const SEQUENCE_TEMPLATE_STATUS_ERROR = 'sequence-template-error';

export const isSequenceTemplateStatus = function (status) {
    return [SEQUENCE_TEMPLATE_STATUS_COMPLETED, SEQUENCE_TEMPLATE_STATUS_ERROR].indexOf(status) != -1;
};

export const CAPTIONING_PROCESS_TYPE = 'captioning';
export const BREAKPOINT_LG = 992;

export const MAX_NB_OF_MESSAGES_PER_SEQUENCES = 10;
export const MAX_NB_OF_VISUALS_PER_SEQUENCES = 10;

export const QUICK_CUT_UPDATE_TIMELINE_DELTA = 2; // Unit: seconds

export const RECORDING_TYPE_SCREEN = 'screen';
export const RECORDING_TYPE_CAMERA = 'camera';
export const RECORDING_TYPE_BOTH = 'both';
export const RECORDING_TYPE_MICROPHONE = 'microphone';

export const RECORDING_INPUT_DEVICE_VIDEO = 'video';
export const RECORDING_INPUT_DEVICE_AUDIO = 'audio';

export const USER_MIN_PASSWORD_LENGTH = 8;

export const QUICKCUT_BUTTON_CLASS = 'ui-quickcut-button';
export const INTEGRATION_VIDEO_PLAYER_SIZES = { width: 640, height: 360 };
