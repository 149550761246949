<template>
    <div class="selectable-videos-list">
        <ui-card
            :key="video"
            v-for="video of videos"
            :hide-header="true"
            class="ui-view-list-item ui-video-image-preview-container"
        >
            <router-link
                v-if="user.role?.canEditDatas"
                class="card-item-link"
                :to="{ name: 'EditVideo', params: { id: video.id } }"
            ></router-link>
            <div class="card-item-data">
                <div class="card-item-checkbox">
                    <input type="checkbox" class="ui-checkbox" :checked="video.selected" @change="selectVideo(video)" />
                </div>
                <div class="card-item-image">
                    <ui-video-image-preview :video="video" />
                </div>
                <div class="card-item-info">
                    <div class="card-item-name">{{ video.name }}</div>
                    <div class="card-item-update">{{ formatDate(video.updatedAt) }}</div>
                </div>
                <div class="card-item-team"></div>
            </div>
            <div class="card-item-status">
                <div v-if="video.ownerName" class="card-item-owner">{{ video.ownerName }}</div>
            </div>
            <div class="card-item-actions">
                <video-validation-actions
                    :needs-validation="user.needsValidation"
                    :validating-user-ids="user.validatingUserIds"
                    :video="video"
                    :is-producing="isProducing(video)"
                    @status-updated="(status) => updateValidationStatus(video, status)"
                />
                <button
                    class="video-action-button ui-simple-button ui-simple-button-select-reversed"
                    :disabled="!canProduce(video)"
                    v-tooltip="produceButtonTooltip(video)"
                    @click="produceVideo(video)"
                >
                    <svg-icon icon="rocket-icon" />
                    {{ $t('Produce') }}
                </button>
                <button
                    @click="exportVideo(video)"
                    class="video-download-button"
                    :disabled="!video.latestProducedUrl || null"
                    :class="{ loading: isProducing(video) }"
                >
                    <svg-icon class="download-icon" icon="download-icon" />
                    <svg-icon class="loader" icon="circle-loader" />
                </button>
                <ui-dropdown
                    id="video-actions-list"
                    class="video-actions-list"
                    toggle-class="ui-action-button"
                    :icon="true"
                    :caret="false"
                    menu-placement="bottom-end"
                >
                    <template #dropdown-toggle>
                        <svg-icon icon="menu-icon" />
                    </template>
                    <ui-dropdown-item @click.prevent="duplicateVideo(video)" :disabled="!video.permissions.copy">
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="clone-icon" />
                            {{ $t('Duplicate') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item @click.prevent="copyVideo(video)" :disabled="!video.permissions.move">
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="file-export-icon" />
                            {{ $t('Copy to...') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item @click.prevent="translateVideo(video)">
                        <template #dropdown-menu-item-label>
                            <svg-icon style="width: 1.5rem" icon="language-ia-regular-icon" />
                            {{ $t('actions.translate') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item
                        @click.prevent="transformTemplate(video)"
                        :disabled="!video.permissions.createTemplateFrom || !user.role.canTransferToTemplateContainers"
                    >
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="clapperboard-play-icon" />
                            {{ $t('Transform into template') }}
                        </template>
                    </ui-dropdown-item>
                    <ui-dropdown-item @click.prevent="deleteVideo(video)" :disabled="!video.permissions.delete">
                        <template #dropdown-menu-item-label>
                            <svg-icon icon="trash-icon" />
                            {{ $t('Delete') }}
                        </template>
                    </ui-dropdown-item>
                </ui-dropdown>
            </div>
        </ui-card>
    </div>
</template>

<script setup>
import { UiDropdown, UiDropdownItem } from '@/js/components/index.js';
import UiCard from '@/js/components/UiCard.vue';
import UiVideoImagePreview from '@/js/components/UiVideoImagePreview.vue';
import FormatUpdatedVideoDateService from '@/js/application/services/video/FormatUpdatedVideoDateService.js';
import ProduceVideoService from '@/js/application/services/video/ProduceVideoService.js';
import DuplicateVideoService from '@/js/application/services/video/DuplicateVideoService.js';
import {
    PRODUCTION_STATUS_PENDING,
    PRODUCTION_STATUS_PRODUCING,
    VALIDATION_STATUS_NONE,
    VALIDATION_STATUS_REJECTED,
    VALIDATION_STATUS_SUBMITTED,
    VALIDATION_STATUS_VALIDATED
} from '@/js/constants/index.js';
import { useModal } from '@/js/composables/useModal.js';
import { computed } from 'vue';
import FolderDTO from '@/js/dto/FolderDTO.js';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import VideoValidationActions from '@/views/ShowFolderView/components/VideoValidationActions.vue';

const { t } = useI18n();
const store = useStore();

const vTooltip = UsesTooltip.directives.tooltip;

const user = computed(() => store.getters['auth/user']);
const videos = defineModel();

const props = defineProps({
    folder: {
        type: FolderDTO,
        required: true
    }
});

const { openModal } = useModal();

const formatDate = (timestamp) => {
    return new FormatUpdatedVideoDateService().handle(timestamp);
};

const produceVideo = (video) => {
    if (video.isProducing) return;

    video.isProducing = true;
    new ProduceVideoService(store).handle(video.id).catch(() => {
        video.isProducing = false;
    });
};

const duplicateVideo = (video) => {
    new DuplicateVideoService(store).handle(video.id).then((video) => {
        videos.value = [video, ...videos.value];
    });
};

const deleteVideo = (video) => {
    openModal('ConfirmDeleteVideosModal', {
        videos: [video],
        callback: () => {
            videos.value = videos.value.filter((v) => v.id !== video.id);
        },
        finalCallback: () => {}
    });
};

const copyVideo = (video) => {
    openModal('CopyVideoModal', { video });
};

const translateVideo = (video) => {
    openModal('TranslateModal', { type: 'videos', elements: [video] });
};

const transformTemplate = (video) => {
    openModal('TransformTemplateModal', { video, folder: props.folder });
};

const exportVideo = (video) => {
    if (video.latestProducedUrl && !isProducing(video)) {
        openModal('ExportVideoModal', { video });
    }
};

const isProducing = (video) => {
    return (
        video.isProducing ||
        video.productionStatus === PRODUCTION_STATUS_PENDING ||
        video.productionStatus === PRODUCTION_STATUS_PRODUCING
    );
};

const produceButtonTooltip = (video) => {
    if (isProducing(video)) return t('Video currently producing');
    if (user.value.needsValidation && video.validationStatus === VALIDATION_STATUS_NONE)
        return t('video.validation.required');

    return null;
};
const canProduce = (video) =>
    !isProducing(video) && (!user.value.needsValidation || video.validationStatus === VALIDATION_STATUS_VALIDATED);

const selectVideo = (video) => {
    video.selected = !video.selected;
};

const updateValidationStatus = (video, status) => {
    video.validationStatus = status;
    if (status === VALIDATION_STATUS_SUBMITTED) video.permissions.submit = false;
    else video.permissions.validate = false;
};
</script>
