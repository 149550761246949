<template>
    <div class="timeline-slider-outer">
        <div class="timeline-slider-scroll" ref="sliderOuterContainer">
            <div
                class="timeline-slider-content"
                ref="sliderWrapper"
                @mousedown="handleMouseDown"
                @mousemove="handleMouseMove"
                @mouseleave="handleMouseLeave"
            >
                <div class="slider-container" ref="sliderContainer" :style="containerStyle">
                    <GapRenderer
                        :initial-gap-style="getInitialGapStyle"
                        :final-gap-style="getFinalGapStyle"
                        :segments="segments"
                        :get-gap-style="getGapStyle"
                    />
                    <slot name="segments" />
                    <HoverBar v-show="showHoverBar" v-bind="hoverBarProps" />
                    <CurrentTimeBar
                        :position="currentTimePos"
                        :canvas-width="canvasWidth"
                        :is-dragging="currentTimeIsDragging"
                        :is-scrolling="isScrolling"
                        @start-drag="handleCurrentTimeDrag"
                    />
                    <slot name="canvas" />
                    <slot name="audio-waveform" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { defineComponent, ref, onMounted, onBeforeUnmount, watch, computed } from 'vue';
import type { Segment, GapStyle } from '@/js/videos/types/segments';
import CurrentTimeBar from './CurrentTimeBar.vue?inline';
import GapRenderer from './GapRenderer.vue?inline';
import HoverBar from './HoverBar.vue?inline';

import { useClickThreshold } from '../composables/useClickThreshold';
import { useDisplay } from '@/js/videos/composables/useDisplay';
import { useScrolling } from '../composables/useScrolling';
import { useHoverBar } from '../composables/useHoverBar';

// Types
interface TimelineProps {
    canvasWidth: number;
    segments: Segment[];
    currentTimePos: number;
    getInitialGapStyle: GapStyle;
    getGapStyle: (gap: { start: number; end: number }) => GapStyle;
    getFinalGapStyle: GapStyle;
    currentTimeIsDragging: boolean;
    isDragging: boolean;
    segmentIsDragging: boolean;
    endTime: number;
    rate?: number;
}

// Props & Emits
const props = withDefaults(defineProps<TimelineProps>(), {
    rate: 100
});

const emit = defineEmits<{
    (e: 'startSliderDrag', event: MouseEvent, wrapper: HTMLElement): void;
    (e: 'startCurrentTimeDrag', event: MouseEvent): void;
    (e: 'elementsMount', elements: { sliderWrapper: HTMLElement; sliderContainer: HTMLElement }): void;
    (e: 'startSegmentDrag', event: MouseEvent, index: number): void;
}>();

// Refs & State
const sliderWrapper = ref<HTMLElement | null>(null);
const sliderContainer = ref<HTMLElement | null>(null);
const sliderOuterContainer = ref<HTMLElement | null>(null);
const elementsAreDragging = ref(false);

// Composables
const { playing } = useDisplay();
const { startTracking, checkThreshold, resetTracking } = useClickThreshold();

// Computed
const containerStyle = computed(() => ({ width: `${props.canvasWidth}px` }));
const hoverBarProps = computed(() => ({
    position: hoverBarPosition.value,
    'hover-time': hoverTime.value,
    x: hoverDisplayX.value,
    y: hoverDisplayY.value,
    rate: props.rate,
    'is-dragging': props.isDragging
}));

// Composables setup
const { startContinuousScroll, stopContinuousScroll, scrollToCurrentTime, isScrolling } = useScrolling(
    sliderOuterContainer,
    computed(() => props.currentTimePos),
    elementsAreDragging
);

const { showHoverBar, hoverBarPosition, hoverTime, hoverDisplayX, hoverDisplayY, handleMouseMove, handleMouseLeave } =
    useHoverBar(
        sliderWrapper,
        computed(() => props.canvasWidth),
        computed(() => props.endTime)
    );

// Methods
function getSegmentIndexAtPosition(clientX: number): number {
    if (!sliderWrapper.value) return -1;

    const { left, width } = sliderWrapper.value.getBoundingClientRect();
    const position = (clientX - left) / width;
    const time = position * props.endTime;

    return props.segments.findIndex((segment) => time >= segment.start && time <= segment.end);
}

function handleMouseDown(event: MouseEvent) {
    startTracking(event);
    const segmentIndex = getSegmentIndexAtPosition(event.clientX);

    function handleMouseMove(moveEvent: MouseEvent) {
        if (checkThreshold(moveEvent)) {
            if (segmentIndex !== -1 && !props.currentTimeIsDragging) {
                emit('startSegmentDrag', moveEvent, segmentIndex);
            } else {
                emit('startSliderDrag', moveEvent, sliderWrapper.value!);
            }
            cleanupMouseListeners();
        }
    }

    function handleMouseUp() {
        cleanupMouseListeners();
        resetTracking();
    }

    function cleanupMouseListeners() {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
}

function handleCurrentTimeDrag(event: MouseEvent) {
    emit('startCurrentTimeDrag', event);
    elementsAreDragging.value = true;
}

// Watchers
watch(
    () => playing.value,
    (isPlaying) => {
        isPlaying ? startContinuousScroll() : stopContinuousScroll();
    }
);

watch(
    () => props.currentTimePos,
    () => {
        if (!playing.value) {
            scrollToCurrentTime();
        }
    }
);

watch(
    () => props.isDragging,
    (newValue) => {
        elementsAreDragging.value = newValue;
    }
);

// Lifecycle
onMounted(() => {
    emit('elementsMount', {
        sliderWrapper: sliderWrapper.value!,
        sliderContainer: sliderContainer.value!
    });
});

onBeforeUnmount(() => {
    stopContinuousScroll();
});
</script>

<style scoped>
.hover-bar-container {
    position: absolute;
    top: 0;
    height: 100%;
    pointer-events: none;
    z-index: 15;
}

.hover-timecode {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    padding: 2px 5px;
    border-radius: 3px;
    font-size: 12px;
}

.hover-bar {
    width: 1px;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
}
.timeline-slider-outer {
    width: 100%;
    overflow: hidden;
}

.timeline-slider-scroll {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}

.timeline-slider-content {
    display: inline-block;
    min-width: 100%;
    cursor: grab;
    padding-top: 36px;
    padding-left: 8px;
    width: max-content;
}

.timeline-slider-content:active {
    cursor: grabbing;
}

.slider-container {
    position: relative;
    height: 62px;
    white-space: nowrap;
    border-top: 2px solid rgba(216, 0, 85, 0.5);
    border-bottom: 2px solid rgba(216, 0, 85, 0.5);
}

.timeline-slider-scroll::-webkit-scrollbar {
    height: 8px;
    margin-top: 8px;
}

.timeline-slider-scroll::-webkit-scrollbar-track {
    background: #2c343a;
}

.timeline-slider-scroll::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
}

.timeline-slider-scroll::-webkit-scrollbar-thumb:hover {
    background: white;
}

.vertical-bar {
    margin-top: 16px;
    margin-left: 6px;
    position: absolute;
    border-left: 2px solid white;
    height: 39 px;
}
</style>
