// useTimelineControls.js
import { debounce } from 'lodash';
import { ref, computed } from 'vue';

export function useTimelineControls(
    { FRAME_RATE, FRAME_WIDTH },
    { segments, videoEndTime, updateCurrentTimeAndPos, zoomLevel, isUpdating, sequenceProps }
) {
    const currentTimePos = ref(0);
    const currentTimeDrag = ref(false);

    const canvasWidth = computed(() => {
        return videoEndTime.value * FRAME_RATE * FRAME_WIDTH * zoomLevel.value;
    });

    const startCurrentTimeDrag = (event) => {
        currentTimeDrag.value = true;
    };

    const getCurrentRealTime = () => {
        return (currentTimePos.value / canvasWidth.value) * videoEndTime.value;
    };

    const onCurrentTimeDrag = (event, sliderContainer) => {
        if (!currentTimeDrag.value) return;
        const rect = sliderContainer.getBoundingClientRect();
        const newX = event.clientX - rect.left;
        const newTime = (newX / canvasWidth.value) * videoEndTime.value;
        currentTimePos.value = newX;
        updateCurrentTimeAndPos(newTime);
    };

    const stopCurrentTimeDrag = () => {
        currentTimeDrag.value = false;
    };

    const updateCurrentTimeAndPosition = (newTime) => {
        if (isUpdating.value) return;
        try {
            isUpdating.value = true;

            let targetTime = 0;
            let accumulatedDuration = sequenceProps.startTime;
            const containerWidth = canvasWidth.value;
            const playbackRate = sequenceProps.playbackRate || 1;

            // Parcourir les segments pour trouver le bon segment et la position correcte
            for (const segment of segments.value) {
                const segmentDuration = (segment.end - segment.start) / playbackRate;
                if (newTime <= accumulatedDuration + segmentDuration) {
                    // Position relative dans le segment actuel
                    const relativeTime = newTime - accumulatedDuration;
                    targetTime = segment.start + relativeTime * playbackRate;
                    break;
                }
                accumulatedDuration += segmentDuration;
            }

            // Si on dépasse la durée totale, on se positionne à la fin du dernier segment
            if (targetTime === 0 && segments.value.length > 0) {
                const lastSegment = segments.value[segments.value.length - 1];
                targetTime = lastSegment.end;
            }

            currentTimePos.value = (targetTime / videoEndTime.value) * containerWidth;
        } finally {
            isUpdating.value = false;
        }
    };

    return {
        currentTimePos,
        canvasWidth,
        updateCurrentTimeAndPosition,
        startCurrentTimeDrag,
        onCurrentTimeDrag,
        stopCurrentTimeDrag,
        getCurrentRealTime,
        currentTimeDrag
    };
}
