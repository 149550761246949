<template>
    <button
        v-if="video?.permissions.submit && user.needsValidation"
        class="ui-action-button preview-action preview-action-alt"
        @click="askValidation"
        v-tooltip.light="$t('Ask validation')"
    >
        <span class="action-icon">
            <fa-icon icon="fa-regular fa-user-check" class="icon" />
        </span>
    </button>

    <template v-else-if="video?.permissions.validate && user.validatingUserIds.includes(Number(video.ownerId))">
        <button
            class="ui-action-button preview-action preview-action-success"
            @click="approveVideo"
            v-tooltip.light="$t('Approve')"
        >
            <span class="action-icon">
                <fa-icon icon="fa-regular fa-check" class="icon" />
            </span>
        </button>

        <button
            class="ui-action-button preview-action preview-action-danger"
            @click="rejectVideo"
            v-tooltip.light="$t('Reject')"
        >
            <span class="action-icon">
                <fa-icon icon="fa-regular fa-xmark" class="icon" />
            </span>
        </button>
    </template>
</template>

<script setup>
import { computed } from 'vue';
import { useRoute } from 'vue-router';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import { useModal } from '@/js/composables/useModal.js';
import {
    VALIDATION_STATUS_REJECTED,
    VALIDATION_STATUS_SUBMITTED,
    VALIDATION_STATUS_VALIDATED
} from '@/js/constants/index.js';
import { useStore } from 'vuex';
import { useAuth } from '@/js/composables/useAuth.js';

const vTooltip = UsesTooltip.directives.tooltip;

const store = useStore();
const { user } = useAuth();
const { openModal } = useModal();
const route = useRoute();

const video = computed(() => store.getters['videos/currentVideo']);

const askValidation = () =>
    openModal('SubmitVideoValidationModal', {
        videoId: route.params.id,
        successCallback: () => {
            store.commit('videos/updateVideoData', {
                validationStatus: VALIDATION_STATUS_SUBMITTED,
                permissions: { submit: false }
            });
            store.commit('ui/setReadOnly', true);
        }
    });
const approveVideo = () =>
    openModal('ActionVideoValidationModal', {
        videoId: route.params.id,
        action: 'approve',
        successCallback: () => {
            store.commit('videos/updateVideoData', {
                validationStatus: VALIDATION_STATUS_VALIDATED,
                permissions: { validate: false }
            });
        }
    });
const rejectVideo = () =>
    openModal('ActionVideoValidationModal', {
        videoId: route.params.id,
        action: 'reject',
        successCallback: () => {
            store.commit('videos/updateVideoData', {
                validationStatus: VALIDATION_STATUS_REJECTED,
                permissions: { validate: false }
            });
        }
    });
</script>
