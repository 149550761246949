<template>
    <div class="timeline-visualizer" ref="visualizer" :style="position" @mousedown="startDrag">
        <div class="resize-handle top-left" @mousedown="startResize('top-left')"></div>
        <div class="resize-handle top-right" @mousedown="startResize('top-right')"></div>
        <div class="resize-handle bottom-left" @mousedown="startResize('bottom-left')"></div>
        <div class="resize-handle bottom-right" @mousedown="startResize('bottom-right')"></div>
        <div class="resize-handle right" @mousedown="startResize('right')"></div>
        <div class="resize-handle bottom" @mousedown="startResize('bottom')"></div>
        <div class="timeline-header">
            <div class="timeline-time">{{ displayTime }}s / {{ totalDuration.toFixed(2) }}s</div>
            <div class="zoom-controls">
                <button @click="zoomOut" class="zoom-button">
                    <span>-</span>
                </button>
                <div class="zoom-info">{{ zoomPercentage }}%</div>
                <button @click="zoomIn" class="zoom-button">
                    <span>+</span>
                </button>
                <button @click="resetZoom" class="zoom-reset">Reset</button>
            </div>
            <div class="timeline-controls">
                <button @click="play" :disabled="isPlaying">Play</button>
                <button @click="pause" :disabled="!isPlaying">Pause</button>
                <button @click="restart">Restart</button>
                <button
                    @click="toggleAutoScroll"
                    :class="{ active: autoScroll }"
                    class="auto-scroll-button"
                    :title="autoScroll ? 'Désactiver le défilement automatique' : 'Activer le défilement automatique'"
                >
                    <span class="auto-scroll-icon">⟷</span>
                </button>
                <button @click="reloadTimeline" class="reload-button"><span class="reload-icon">↻</span> Reload</button>
            </div>
        </div>

        <div
            class="timeline-container"
            ref="container"
            @wheel="handleWheel"
            @mousedown="handleMouseDown"
            @mousemove="handleMouseMove"
            @mouseup="handleMouseUp"
            @mouseleave="handleMouseUp"
            @click="onTimelineClick"
        >
            <div class="timeline-content" :style="contentStyles">
                <!-- Time markers -->
                <div class="time-markers">
                    <div
                        v-for="marker in timeMarkers"
                        :key="marker.time"
                        class="time-marker"
                        :style="{ left: `${marker.position}px` }"
                    >
                        <div class="marker-line"></div>
                        <div class="marker-label">{{ marker.time }}s</div>
                    </div>
                </div>

                <!-- Main Timeline -->
                <div class="timeline-track main-track">
                    <!-- Control Sequences -->
                    <div class="control-sequences">
                        <div
                            v-for="sequence in controlSequences"
                            :key="sequence.id"
                            class="control-marker"
                            :class="getControlMarkerClass(sequence)"
                            :style="getControlMarkerStyle(sequence)"
                            :title="getControlMarkerTooltip(sequence)"
                        >
                            <div class="marker-icon">
                                <span v-if="sequence.type === 'start'">◆</span>
                                <span v-else-if="sequence.type === 'pause'">▮▮</span>
                                <span v-else-if="sequence.type === 'end'">◆</span>
                            </div>
                            <div class="marker-label">{{ sequence.label }}</div>
                        </div>
                    </div>

                    <!-- Content Sequences -->
                    <div
                        v-for="(sequence, index) in contentSequences"
                        :key="sequence.id"
                        class="sequence-track"
                        :class="{
                            active: sequence.isActive,
                            'has-transition': sequence.transition,
                            'has-background': sequence.background
                        }"
                        :style="getSequenceStyles(sequence, index)"
                    >
                        <!-- Sequence Header -->
                        <div class="sequence-header">
                            <div class="sequence-info">
                                <div class="sequence-id" :title="sequence.id">
                                    {{ formatSequenceId(sequence.id) }}
                                </div>
                                <div class="sequence-timing">
                                    {{ formatTime(sequence.startTime) }} - {{ formatTime(sequence.endTime) }}
                                </div>
                            </div>
                            <div class="sequence-meta">
                                <span class="time-scale">×{{ sequence.timeScale.toFixed(2) }}</span>
                                <span v-if="sequence.immediateRender" class="immediate-render">⚡</span>
                            </div>
                        </div>

                        <!-- Sequence Content -->
                        <div class="sequence-content">
                            <!-- Transition -->
                            <div
                                v-if="sequence.transition"
                                class="timeline-element transition-zone"
                                :style="getTransitionStyles(sequence.transition)"
                            >
                                <span class="element-label">
                                    Transition {{ sequence.transition.duration.toFixed(2) }}s
                                </span>
                            </div>

                            <!-- Background -->
                            <template v-if="sequence.background">
                                <div
                                    class="timeline-element background-track"
                                    :style="getBackgroundStyles(sequence.background)"
                                >
                                    <span class="element-label">
                                        Background {{ sequence.background.duration.toFixed(2) }}s
                                    </span>

                                    <!-- Video/Animation child timelines -->
                                    <template v-if="sequence.background.children">
                                        <div
                                            v-for="child in sequence.background.children"
                                            :key="child.id"
                                            class="timeline-element"
                                            :class="child.type"
                                            :style="getChildTimelineStyles(child)"
                                        >
                                            <span class="element-label">{{ child.type }}</span>
                                        </div>
                                    </template>
                                </div>
                            </template>

                            <!-- Content Elements -->
                            <template v-if="sequence.content">
                                <div
                                    v-for="element in sequence.content"
                                    :key="element.id"
                                    class="timeline-element"
                                    :class="[
                                        element.type,
                                        {
                                            'has-sequence-duration': element.hasSequenceDuration,
                                            'has-out-timeline': hasEndTiming(element)
                                        }
                                    ]"
                                    :style="getElementStyles(element)"
                                    :title="getElementTooltip(element)"
                                >
                                    <span class="element-label">
                                        {{ element.type }}
                                        <span v-if="element.hasSequenceDuration">📏</span>
                                        <span v-if="hasEndTiming(element)">⇥</span>
                                    </span>
                                    <div
                                        v-if="hasEndTiming(element)"
                                        class="element-end-timing"
                                        :style="getElementEndTimingStyles(element)"
                                    ></div>
                                </div>
                            </template>

                            <!-- Sequence Labels -->
                            <div
                                v-for="label in sequence.labels"
                                :key="label.name"
                                class="sequence-label-marker"
                                :class="getLabelClasses(label)"
                                :style="{ left: `${label.time * pixelsPerSecond}px` }"
                                :title="label.name"
                            >
                                <span class="label-marker">▼</span>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Playhead -->
                <div class="playhead" ref="playhead" :style="{ left: `${playheadPosition}px` }">
                    <div class="playhead-line"></div>
                    <div class="playhead-handle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { gsap } from 'gsap';
import Draggable from 'gsap/Draggable';
import { Timeline } from '../constants';
import { mapState, mapGetters } from 'vuex';
import _throttle from 'lodash/throttle';
import { STAGE_LOADING_ENDED_EVENT, STAGE_LOADING_STARTED_EVENT, STAGE_TIMELINE_UPDATED_EVENT } from '../utils';

gsap.registerPlugin(Draggable);

export default {
    name: 'TimelineVisualizer',
    inject: ['$videoStudio'],

    props: {
        timeline: {
            type: Object,
            required: true
        },
        currentTime: {
            type: Number,
            required: false,
            default: 0
        }
    },

    data() {
        return {
            autoScroll: true,
            basePixelsPerSecond: 20, // Réduit de 100 à 20 pour un zoom initial plus dézoomé
            contentPadding: 8,
            dragStartScrollLeft: 0,
            dragStartX: 0,
            draggable: null,
            elementHeight: 20,
            error: null,
            extractedSequences: [],
            headerHeight: 25,
            isDragging: false,
            isPlaying: false,
            maxPixelsPerSecond: 500,
            minPixelsPerSecond: 1,
            mouseX: 0,
            mouseY: 0,
            pixelsPerSecond: 20, // Également réduit à 20 pour correspondre au basePixelsPerSecond
            temporaryZoom: 1,
            timelineHeight: 200,
            trackHeight: 30,
            trackSpacing: 10,
            rowHeights: {
                transition: 20,
                background: 25,
                content: 20
            },
            zoomStep: 1.2,
            x: window.innerWidth / 2 - 400, // centre par défaut
            y: window.innerHeight / 2 - 200,
            dragStartX: 0,
            dragStartY: 0,
            width: 800,
            height: 400,
            minWidth: 400,
            minHeight: 200,
            isResizing: false,
            resizeHandle: null,
            resizeStartX: 0,
            resizeStartY: 0,
            resizeStartWidth: 0,
            resizeStartHeight: 0,
            localCurrentTime: 0
        };
    },

    computed: {
        ...mapGetters({
            isMainTimeline: 'preview/isMainTimeline',
            currentEditedItem: 'ui/currentEditedItem'
        }),
        ...mapState({
            totalTime: (state) => state.display.timeline.totalTime
        }),

        controlSequences() {
            return this.extractedSequences
                .filter((seq) => this.isControlSequence(seq))
                .map((seq) => ({
                    ...seq,
                    type: this.getControlSequenceType(seq),
                    label: this.getControlSequenceLabel(seq)
                }));
        },

        contentSequences() {
            return this.extractedSequences.filter((seq) => !this.isControlSequence(seq));
        },
        contentStyles() {
            return {
                width: `${this.totalWidth}px`,
                height: `${this.totalHeight}px`,
                transform: `scale(${this.temporaryZoom})`,
                transformOrigin: 'left top'
            };
        },
        zoomPercentage() {
            return Math.round((this.pixelsPerSecond / this.basePixelsPerSecond) * 100);
        },
        totalDuration() {
            return this.totalTime || 0;
        },
        totalWidth() {
            return Math.max(this.totalDuration * this.pixelsPerSecond, 1000);
        },
        totalHeight() {
            let height = this.contentPadding;
            this.extractedSequences.forEach((sequence, index) => {
                height += this.calculateSequenceHeight(sequence);
                if (index < this.extractedSequences.length - 1) {
                    height += this.trackSpacing;
                }
            });
            height += this.contentPadding;
            return height;
        },

        currentSequence() {
            return this.extractedSequences.find((s) => s.isActive);
        },
        zoomLevel() {
            return this.pixelsPerSecond / this.basePixelsPerSecond;
        },
        playheadPosition() {
            return this.localCurrentTime * this.pixelsPerSecond;
        },
        timeMarkers() {
            const markers = [];
            const step = Math.max(1, Math.floor(this.totalDuration / 20));

            for (let i = 0; i <= this.totalDuration; i += step) {
                markers.push({
                    time: i,
                    position: i * this.pixelsPerSecond
                });
            }

            return markers;
        },
        timelineLabels() {
            if (!this.timeline) return [];

            const labels = [];
            const allLabels = this.timeline.labels || {};

            Object.entries(allLabels).forEach(([name, time]) => {
                labels.push({
                    name,
                    time,
                    position: time * this.pixelsPerSecond
                });
            });

            return labels;
        },
        position() {
            return {
                left: `${this.x}px`,
                top: `${this.y}px`,
                width: `${Math.max(this.width, this.minWidth)}px`,
                height: `${Math.max(this.height, this.minHeight)}px`,
                transform: 'none',
                minWidth: `${this.minWidth}px`,
                minHeight: `${this.minHeight}px`
            };
        },
        displayTime() {
            return this.localCurrentTime.toFixed(2);
        }
    },
    watch: {
        timeline: {
            immediate: true,
            deep: true,
            handler(newTimeline, oldTimeline) {
                if (newTimeline !== oldTimeline) {
                    this.extractTimelineData(newTimeline);
                }
            }
        },
        currentTime: {
            immediate: true,
            handler(newValue) {
                this.localCurrentTime = newValue;
            }
        }
    },

    methods: {
        isControlSequence(sequence) {
            // Vérifie si c'est une séquence de contrôle basée sur l'ID
            return sequence.id.startsWith('sequence-') && (sequence.content.length === 0 || sequence.duration === 0);
        },

        getControlSequenceType(sequence) {
            if (sequence.startTime === 0) return 'start';
            if (sequence.startTime === this.totalDuration) return 'end';
            return 'pause';
        },

        getControlSequenceLabel(sequence) {
            switch (this.getControlSequenceType(sequence)) {
                case 'start':
                    return 'Start';
                case 'end':
                    return 'End';
                case 'pause':
                    return `Pause ${sequence.startTime.toFixed(2)}s`;
            }
        },

        getControlMarkerClass(sequence) {
            return {
                'marker-start': sequence.type === 'start',
                'marker-pause': sequence.type === 'pause',
                'marker-end': sequence.type === 'end'
            };
        },

        getControlMarkerStyle(sequence) {
            return {
                left: `${sequence.startTime * this.pixelsPerSecond}px`,
                position: 'absolute'
            };
        },

        getControlMarkerTooltip(sequence) {
            switch (sequence.type) {
                case 'start':
                    return `Timeline Start: ${sequence.startTime.toFixed(3)}s`;
                case 'end':
                    return `Timeline End: ${sequence.startTime.toFixed(3)}s`;
                case 'pause':
                    return `Pause Point: ${sequence.startTime.toFixed(3)}s${
                        sequence.duration > 0 ? `\nDuration: ${sequence.duration.toFixed(3)}s` : ''
                    }`;
            }
        },

        getLabelType(name) {
            if (name === 'start') return 'start';
            if (name === 'end') return 'end';
            if (name.includes('transition')) return 'transition';
            return 'custom';
        },

        getLabelClasses(label) {
            return {
                'label-start': label.isStart,
                'label-end': label.isEnd,
                'label-transition': label.isTransition,
                'label-custom': !label.isStart && !label.isEnd && !label.isTransition
            };
        },
        extractTimelineData(timeline) {
            try {
                if (!timeline) {
                    this.extractedSequences = [];
                    return;
                }

                const mainChildren = timeline.getChildren() || [];

                this.extractedSequences = mainChildren
                    .filter((child) => child?.vars?.id || (child?.targets && child?.targets()[0]))
                    .map((child) => {
                        // Récupérer la timeline de séquence
                        const sequenceTimeline = child.targets?.()[0] || child;

                        // Récupérer les différentes timelines
                        let contentTimeline, backgroundTimeline, transitionTimeline;

                        // Vérifier si nous avons une timeline ou un tween
                        if (sequenceTimeline.getById) {
                            contentTimeline = sequenceTimeline.getById('sequence_' + child.vars?.id);
                            backgroundTimeline = sequenceTimeline.getById('background_' + child.vars?.id);
                            transitionTimeline = sequenceTimeline.getById('transition_' + child.vars?.id);
                        }

                        // Extraire les éléments de contenu
                        const content = [];
                        if (contentTimeline) {
                            // Si contentTimeline est une timeline (a la méthode getChildren)
                            if (contentTimeline.getChildren) {
                                const contentChildren = contentTimeline.getChildren() || [];
                                contentChildren.forEach((contentChild) => {
                                    const element = this.extractContentElement(contentChild);
                                    if (element) {
                                        content.push(element);
                                    }
                                });
                            }
                            // Si contentTimeline est un Tween
                            else if (contentTimeline.vars?.id) {
                                const element = this.extractContentElement(contentTimeline);
                                if (element) {
                                    content.push(element);
                                }
                            }
                        }

                        return {
                            id: child.vars?.id || `sequence-${child.startTime()}`,
                            startTime: child.startTime() || 0,
                            endTime: child.endTime() || 0,
                            duration: child.duration() || 0,
                            timeScale: child.timeScale() || 1,
                            isActive: child.isActive?.(),
                            immediateRender: child.vars?.immediateRender || false,
                            transition: transitionTimeline
                                ? {
                                      duration: transitionTimeline.duration(),
                                      startTime: transitionTimeline.startTime()
                                  }
                                : null,
                            background: backgroundTimeline
                                ? {
                                      duration: backgroundTimeline.duration(),
                                      startTime: backgroundTimeline.startTime(),
                                      children: this.extractBackgroundChildren(backgroundTimeline)
                                  }
                                : null,
                            content: content,
                            labels: this.extractLabels(sequenceTimeline)
                        };
                    });
            } catch (err) {
                console.error('Error extracting timeline data:', err);
                this.error = err;
            }
        },

        extractContentElement(contentChild) {
            if (!contentChild?.vars?.id) return null;

            const id = contentChild.vars.id;
            const startTime = contentChild.startTime();
            let duration = contentChild.duration();
            let endTime;

            // Chercher le label de fin (_end) dans la timeline parente
            const parentTimeline = contentChild.parent;
            const hasOutTiming = parentTimeline?.labels && `${id}_end` in parentTimeline.labels;

            if (hasOutTiming) {
                // Cas 2: L'élément a un timing de disparition
                endTime = parentTimeline.labels[`${id}_end`];
                duration = endTime - startTime;
            } else if (duration > 0) {
                // Cas 3: L'élément a une durée mais pas de timing de disparition
                endTime = startTime + duration;
            } else {
                // Cas 1: L'élément n'a ni durée ni timing de disparition
                // On utilise la fin de la séquence parente
                endTime = parentTimeline.duration();
                duration = endTime - startTime;
            }

            // S'assurer que la durée n'est jamais nulle
            if (duration <= 0) {
                duration = endTime - startTime;
            }

            return {
                id: id,
                type: this.getTimelineType(id),
                startTime: startTime,
                duration: duration,
                endTime: endTime,
                timeScale: contentChild.timeScale(),
                hasSequenceDuration: contentChild.data?.hasSequenceDuration || false,
                hasOutTimeline: hasOutTiming
            };
        },

        getTimelineType(id) {
            // Amélioration de l'extraction du type
            const parts = id.split('_');
            if (parts[0] === 'visual') {
                // Pour les éléments visuels, on combine avec le sous-type
                return parts.length > 1 ? `${parts[0]}-${parts[1]}` : parts[0];
            }
            return parts[0] || 'unknown';
        },

        extractBackgroundChildren(backgroundTimeline) {
            if (!backgroundTimeline) return [];

            const children = backgroundTimeline.getChildren();
            return children
                .filter((child) => child?.vars?.id)
                .map((child) => {
                    const childData = {
                        id: child.vars.id,
                        type: this.getTimelineType(child.vars.id),
                        startTime: child.startTime(),
                        duration: child.duration(),
                        timeScale: child.timeScale()
                    };
                    return childData;
                });
        },

        extractContentElements(contentTimeline) {
            if (!contentTimeline) return [];
            const elements = [];
            const processChild = (child) => {
                if (!child) return;

                const timeline = child.targets?.[0] || child;
                if (timeline.vars?.id) {
                    const elementType = this.getTimelineType(timeline.vars.id);
                    const startTime = timeline.startTime();
                    let duration = 0;
                    let hasOutTimeline = false;
                    let hasSequenceDuration = false;

                    // Gestion spéciale pour les éléments visuels (images, vidéos, etc.)
                    if (elementType === 'visual') {
                        // Récupérer toutes les timelines enfants
                        const childTimelines = timeline.getChildren();

                        // Trouver la timeline principale et la timeline de sortie
                        const mainTimeline = childTimelines.find((t) => t.vars?.id === Timeline.VISUAL_TIMELINE_ID);
                        const outTimeline = childTimelines.find(
                            (t) =>
                                t.vars?.id?.includes('_out') || // Cherche un ID contenant '_out'
                                (t.data && t.data.isOutTimeline) || // Vérifie la propriété data
                                (!t.vars?.id && t.totalDuration() > 0) // Timeline sans ID avec une durée
                        );

                        if (mainTimeline && outTimeline) {
                            // La durée totale est la différence entre la fin de la timeline de sortie
                            // et le début de la timeline principale
                            duration = outTimeline.endTime() - startTime;
                            hasOutTimeline = true;
                        } else if (mainTimeline) {
                            // Si pas de timeline de sortie, utiliser la durée totale de la timeline principale
                            duration = mainTimeline.totalDuration();
                            hasSequenceDuration = mainTimeline.data?.hasSequenceDuration || false;
                        }

                        // Fallback si aucune durée n'a été trouvée
                        if (duration === 0) {
                            duration = timeline.totalDuration();
                        }
                    } else {
                        // Pour les autres types d'éléments
                        duration = timeline.duration();
                    }

                    // Assurer une durée minimale pour l'affichage
                    duration = Math.max(duration, 0.01);

                    elements.push({
                        id: timeline.vars.id,
                        type: elementType,
                        startTime,
                        duration,
                        timeScale: timeline.timeScale(),
                        hasSequenceDuration,
                        hasOutTimeline
                    });
                }

                // Traiter les enfants récursivement
                const children = timeline.getChildren?.() || [];
                children.forEach(processChild);
            };

            const children = contentTimeline.getChildren?.() || [];
            children.forEach(processChild);

            return elements;
        },

        getElementTooltip(element) {
            return `${element.type}
            Start: ${element.startTime.toFixed(2)}s
            End: ${element.endTime.toFixed(2)}s
            Duration: ${element.duration.toFixed(2)}s
            ${element.hasSequenceDuration ? '📏 Has sequence duration' : ''}
            ${element.hasOutTimeline ? '⇥ Has out timeline' : ''}`;
        },

        getElementStyles(element) {
            // Calcul de la largeur basée sur la durée effective
            const width = Math.max(element.duration * this.pixelsPerSecond, 1);
            const left = element.startTime * this.pixelsPerSecond;
            const verticalSpacing = 30;

            let baseOffset = 0;
            if (element.type === 'background' || element.type.includes('background')) {
                baseOffset = 0;
            } else if (element.type.includes('visual')) {
                baseOffset = verticalSpacing;
            } else if (element.type === 'video' || element.type.includes('video')) {
                baseOffset = verticalSpacing * 2;
            }

            return {
                position: 'absolute',
                width: `${width}px`,
                left: `${left}px`,
                height: `${this.rowHeights.content}px`,
                top: `${this.headerHeight + element.rowIndex * verticalSpacing + baseOffset}px`,
                zIndex: this.getElementZIndex(element.type)
            };
        },

        isElementVisible(timeline) {
            if (!timeline || !timeline.targets) return true;

            const targets = timeline.targets();
            if (!targets || !targets.length) return true;

            const target = targets[0];

            // Vérifie les propriétés qui pourraient indiquer que l'élément est caché
            const opacity = parseFloat(gsap.getProperty(target, 'opacity'));
            const display = gsap.getProperty(target, 'display');
            const visibility = gsap.getProperty(target, 'visibility');

            // L'élément est considéré visible sauf si explicitement caché
            return !(opacity === 0 || display === 'none' || visibility === 'hidden');
        },

        findParentSequence(timeline) {
            let current = timeline;
            while (current) {
                if (current.vars?.id?.includes('sequence')) {
                    return current;
                }
                current = current.parent;
            }
            return null;
        },

        extractLabels(timeline) {
            if (!timeline?.labels) return [];

            const rawLabels = timeline.labels;

            const labels = Object.entries(rawLabels).map(([name, time]) => {
                const label = {
                    name,
                    time,
                    isStart: name === 'start',
                    isEnd: name === 'end',
                    isTransition: name.includes('transition'),
                    position: time * this.pixelsPerSecond,
                    type: this.getLabelType(name)
                };
                return label;
            });

            return labels.sort((a, b) => a.time - b.time);
        },

        getSequenceStyles(sequence, index) {
            const totalHeight = this.calculateSequenceHeight(sequence);
            const topPosition = this.calculateSequenceTop(index);

            return {
                top: `${topPosition}px`,
                left: `${sequence.startTime * this.pixelsPerSecond}px`,
                width: `${sequence.duration * this.pixelsPerSecond}px`,
                height: `${totalHeight}px`,
                position: 'absolute'
            };
        },

        calculateSequenceHeight(sequence) {
            let height = this.headerHeight;

            if (sequence.transition) {
                height += this.rowHeights.transition + this.trackSpacing;
            }

            if (sequence.background) {
                height += this.rowHeights.background + this.trackSpacing;
            }

            if (sequence.content && sequence.content.length > 0) {
                const rows = this.organizeContentInRows(sequence.content);
                // Augmente la hauteur en fonction du nombre de rangées
                height += rows.length * (this.rowHeights.content + this.trackSpacing);
            }

            return height + this.contentPadding * 2;
        },

        calculateSequenceTop(index) {
            let top = this.contentPadding;
            for (let i = 0; i < index; i++) {
                top += this.calculateSequenceHeight(this.extractedSequences[i]) + this.trackSpacing;
            }
            return top;
        },

        organizeContentInRows(contentElements) {
            // Trier d'abord les éléments par type pour une meilleure organisation
            const sortedElements = [...contentElements].sort((a, b) => {
                const typeOrder = {
                    background: 0,
                    visual: 1,
                    video: 2,
                    image: 3,
                    animation: 4,
                    message: 5,
                    panel: 6,
                    footer: 7,
                    audio: 8
                };

                const typeA = a.type.split('-')[0];
                const typeB = b.type.split('-')[0];

                return (typeOrder[typeA] || 99) - (typeOrder[typeB] || 99);
            });

            const rows = [];
            const elementSpacing = 2;
            const verticalSpacing = 30; // Augmenté pour plus d'espace entre les lignes

            sortedElements.forEach((element) => {
                const elementStart = element.startTime * this.pixelsPerSecond;
                const elementEnd = (element.startTime + element.duration) * this.pixelsPerSecond;

                // Force certains types d'éléments à être sur des lignes spécifiques
                let startingRow = 0;
                if (element.type === 'background' || element.type.includes('background')) {
                    startingRow = 0;
                } else if (element.type.includes('visual')) {
                    startingRow = 1;
                } else if (element.type === 'video' || element.type.includes('video')) {
                    startingRow = 2;
                }

                let rowIndex = startingRow;
                let foundRow = false;

                while (!foundRow) {
                    if (!rows[rowIndex]) {
                        rows[rowIndex] = [element];
                        element.rowIndex = rowIndex;
                        foundRow = true;
                    } else {
                        const hasOverlap = rows[rowIndex].some((existingElement) => {
                            const existingStart = existingElement.startTime * this.pixelsPerSecond;
                            const existingEnd =
                                (existingElement.startTime + existingElement.duration) * this.pixelsPerSecond;

                            return !(
                                elementEnd + elementSpacing <= existingStart ||
                                elementStart >= existingEnd + elementSpacing
                            );
                        });

                        if (!hasOverlap) {
                            rows[rowIndex].push(element);
                            element.rowIndex = rowIndex;
                            foundRow = true;
                        } else {
                            rowIndex++;
                        }
                    }
                }
            });

            return rows;
        },

        formatTime(time) {
            return time.toFixed(2) + 's';
        },

        getRenderedSequences() {
            if (!this.timeline) return [];

            const sequences = [];
            let currentTop = 40;

            const mainChildren = this.timeline.getChildren() || [];

            mainChildren.forEach((child) => {
                if (!child || !child.vars || !child.vars.id) return;

                try {
                    // Pour un tween créé avec tweenFromTo, la timeline est dans vars.timeline
                    const sequenceTimeline = child.vars.timeline || child;
                    if (!sequenceTimeline) return;

                    // Récupérer la timeline de contenu
                    let contentTimeline;
                    if (sequenceTimeline.getById) {
                        contentTimeline = sequenceTimeline.getById(child.vars.id);
                    }

                    // Extraire les informations de la séquence en gérant les cas où certaines propriétés pourraient être undefined
                    const sequence = {
                        id: child.vars.id,
                        start: child.startTime() || 0,
                        duration: child.duration() || 0,
                        top: currentTop,
                        active: this.isSequenceActive(child),
                        transition: this.getTransitionDuration(sequenceTimeline),
                        background: this.getBackgroundInfo(sequenceTimeline),
                        content: this.getSequenceContent(contentTimeline),
                        labels: this.getSequenceLabels(sequenceTimeline),
                        timeScale: child.timeScale() || 1,
                        immediateRender: child.vars.immediateRender || false,
                        startTime: this.getSequenceStartTime(sequenceTimeline),
                        endTime: this.getSequenceEndTime(sequenceTimeline, child)
                    };

                    sequences.push(sequence);
                    currentTop += this.trackHeight * 2;
                } catch (error) {
                    console.warn(`Error processing sequence ${child.vars.id}:`, error);
                }
            });

            return sequences;
        },

        getSequenceStartTime(timeline) {
            try {
                if (timeline && timeline.labels && timeline.labels.start !== undefined) {
                    return timeline.labels.start;
                }
                return 0;
            } catch (error) {
                return 0;
            }
        },

        getSequenceEndTime(timeline, child) {
            try {
                if (timeline && timeline.labels && timeline.labels.end !== undefined) {
                    return timeline.labels.end;
                }
                return child.duration() || 0;
            } catch (error) {
                return child.duration() || 0;
            }
        },

        getSequenceContent(timeline) {
            if (!timeline) return [];

            try {
                const content = [];
                const children = timeline.getChildren() || [];

                children.forEach((child) => {
                    if (!child.vars?.id) return;

                    content.push({
                        id: child.vars.id,
                        type: this.getTimelineType(child.vars.id),
                        startTime: child.startTime() || 0,
                        duration: child.duration() || 0,
                        endTime: child.endTime() || child.duration() || 0,
                        timeScale: child.timeScale() || 1,
                        hasSequenceDuration: child.data?.hasSequenceDuration || false,
                        hasOutTimeline: child.data?.hasOutTimeline || false
                    });
                });

                return content;
            } catch (error) {
                console.warn('Error getting sequence content:', error);
                return [];
            }
        },

        getTransitionDuration(timeline) {
            try {
                // Essayons d'abord de récupérer directement depuis la timeline
                if (timeline && timeline.labels && timeline.labels.transition_end) {
                    return timeline.labels.transition_end;
                }

                // Recherche dans les children
                if (timeline && timeline.getChildren) {
                    const transitionTimeline = timeline.getById && timeline.getById(Timeline.TRANSITION_TIMELINE_ID);
                    if (transitionTimeline) {
                        return transitionTimeline.duration() || 0;
                    }
                }

                return 0;
            } catch (error) {
                return 0;
            }
        },

        getSequenceLabels(timeline) {
            if (!timeline?.labels) return [];

            return Object.entries(timeline.labels).map(([name, time]) => ({
                name,
                time,
                isStart: name === 'start',
                isEnd: name === 'end',
                isTransition: name.includes('transition')
            }));
        },

        getElementTop(element) {
            let top = this.headerHeight + this.contentPadding;

            // Ajoute l'espace pour la transition si présente
            if (this.currentSequence?.transition) {
                top += this.rowHeights.transition + this.trackSpacing;
            }

            // Ajoute l'espace pour le background si présent
            if (this.currentSequence?.background) {
                top += this.rowHeights.background + this.trackSpacing;
            }

            // Ajoute l'espace pour les rangées précédentes
            top += element.rowIndex * (this.rowHeights.content + this.trackSpacing);

            return top;
        },

        getTransitionStyles(transition) {
            return {
                position: 'absolute',
                top: `${this.headerHeight + this.contentPadding}px`,
                left: '0px',
                width: `${transition.duration * this.pixelsPerSecond}px`,
                height: `${this.rowHeights.transition}px`
            };
        },

        getBackgroundStyles(background) {
            return {
                position: 'absolute',
                top: `${
                    this.headerHeight +
                    this.contentPadding +
                    (this.currentSequence?.transition ? this.rowHeights.transition + this.trackSpacing : 0)
                }px`,
                left: `${background.startTime * this.pixelsPerSecond}px`,
                width: `${background.duration * this.pixelsPerSecond}px`,
                height: `${this.rowHeights.background}px`
            };
        },

        // Pour les styles des enfants background
        getChildTimelineStyles(child) {
            return {
                width: `${child.duration * this.pixelsPerSecond}px`,
                left: `${child.startTime * this.pixelsPerSecond}px`
            };
        },

        // Pour les styles de la ligne de temps
        getTimelineStyles() {
            return {
                width: `${this.totalWidth}px`,
                height: `${this.totalHeight}px`
            };
        },

        // Pour formater l'ID de séquence
        formatSequenceId(id) {
            // Pour un UUID comme "seq-43b1e91d-03dc-415f..."
            // Retourne "seq" pour plus de lisibilité
            if (id.includes('-')) {
                return id.split('-')[0];
            }
            return id.slice(0, 10);
        },

        reloadTimeline() {
            this.$emit('reload-timeline');
        },

        getTransitionDuration(timeline) {
            // Essayons d'abord de récupérer la transition via l'ID
            const transitionTimeline = timeline.getById && timeline.getById(Timeline.TRANSITION_TIMELINE_ID);

            if (transitionTimeline) {
                return transitionTimeline.duration();
            }

            // Si on ne trouve pas via l'ID, cherchons dans les children
            if (timeline.getChildren) {
                const children = timeline.getChildren();
                const transitionChild = children.find(
                    (child) =>
                        child.vars &&
                        (child.vars.id === Timeline.TRANSITION_TIMELINE_ID || child.vars.id?.includes('transition'))
                );

                if (transitionChild) {
                    return transitionChild.duration();
                }
            }

            // Si toujours rien, essayons de regarder dans les cibles
            const targets = timeline.targets && timeline.targets();
            if (targets && targets[0]) {
                const sequenceTimeline = targets[0];
                const transitionInTarget =
                    sequenceTimeline.getById && sequenceTimeline.getById(Timeline.TRANSITION_TIMELINE_ID);

                if (transitionInTarget) {
                    return transitionInTarget.duration();
                }
            }

            return 0;
        },
        // formatSequenceId(id) {
        //     // Si l'ID est un numéro de séquence (ex: "sequence1")
        //     if (id.match(/^sequence\d+$/)) {
        //         return id.replace('sequence', 'S');
        //     }

        //     // Si l'ID est un identifiant plus complexe
        //     // On prend les premiers caractères de chaque partie
        //     return id
        //         .split(/[_-]/)
        //         .map((part, index) => {
        //             if (index === 0) return part.slice(0, 3);
        //             return part.slice(0, 2);
        //         })
        //         .join('-')
        //         .slice(0, 8); // Limite la longueur totale
        // },

        formatDuration(duration) {
            return `${duration.toFixed(1)}s`;
        },
        isSequenceActive(timeline) {
            return timeline.isActive && timeline.isActive();
        },

        // getTransitionDuration(timeline) {
        //     const transitionTimeline = timeline.getById && timeline.getById('transition');
        //     return transitionTimeline ? transitionTimeline.duration() : 0;
        // },

        getBackgroundInfo(timeline) {
            const bgTimeline = timeline.getById && timeline.getById('background');
            if (!bgTimeline) return null;

            return {
                start: bgTimeline.startTime(),
                duration: bgTimeline.duration()
            };
        },

        getAudioInfo(timeline) {
            const audioTimeline = timeline.getById && timeline.getById('audio');
            if (!audioTimeline) return null;

            return {
                duration: audioTimeline.duration(),
                volume: audioTimeline.vars.volume || 1
            };
        },

        initDraggable() {
            if (this.$refs.playhead) {
                this.draggable = Draggable.create(this.$refs.playhead, {
                    type: 'x',
                    bounds: this.$refs.container,
                    onDrag: this.onDrag,
                    onDragEnd: this.onDragEnd
                })[0];
            }
        },

        onDrag() {
            if (!this.draggable) return;

            const progress = this.draggable.x / this.totalWidth;
            const time = progress * this.totalDuration;

            this.localCurrentTime = time;

            this.$videoStudio.studio.$stage.seekTimeline(time);
        },

        onDragEnd() {
            if (this.isPlaying) {
                this.timeline.play();
            }
        },

        onTimelineClick(event) {
            if (event.target.closest('.playhead')) return;

            const container = this.$refs.container;
            const rect = container.getBoundingClientRect();

            // Calcul de la position en tenant compte du zoom et du scroll
            const clickX = (event.clientX - rect.left + container.scrollLeft) / this.temporaryZoom;
            const time = clickX / this.pixelsPerSecond;

            // Utilisation du videoStudio pour le seek
            this.$videoStudio.studio.$stage.seekTimeline(time);
        },

        updatePlayhead() {
            if (!this.isPlaying) return;

            // Mettre à jour le temps local depuis la timeline
            if (this.timeline) {
                this.localCurrentTime = this.timeline.time();

                // Utiliser requestAnimationFrame pour synchroniser avec le rafraîchissement de l'écran
                requestAnimationFrame(() => {
                    this.scrollToPlayhead();
                    this.updatePlayhead();
                });
            }
        },

        scrollToPlayhead() {
            if (!this.autoScroll) return;

            const container = this.$refs.container;
            if (!container) return;

            const containerWidth = container.offsetWidth;
            const playheadPosition = this.playheadPosition;

            // Ajuster la zone visible pour garder la tête de lecture dans le viewport
            const margin = containerWidth * 0.3; // 30% de marge
            const currentScroll = container.scrollLeft;
            const rightEdge = currentScroll + containerWidth;

            // Scroll seulement si la tête de lecture s'approche des bords
            if (playheadPosition < currentScroll + margin || playheadPosition > rightEdge - margin) {
                const targetScroll = playheadPosition - containerWidth / 2;

                // Calcul de la vitesse de déplacement basée sur le timeScale de la timeline
                const timeScale = this.timeline ? this.timeline.timeScale() : 1;
                const scrollBehavior = timeScale > 2 ? 'auto' : 'smooth';

                // Utilisation de requestAnimationFrame pour un scroll plus fluide
                const startScroll = container.scrollLeft;
                const distance = Math.max(0, targetScroll) - startScroll;
                const duration = scrollBehavior === 'smooth' ? 300 : 0; // 300ms pour smooth, 0 pour auto
                const startTime = performance.now();

                const animate = (currentTime) => {
                    const elapsed = currentTime - startTime;
                    const progress = Math.min(elapsed / duration, 1);

                    // Fonction d'easing pour un mouvement plus naturel
                    const easeProgress =
                        scrollBehavior === 'smooth'
                            ? 1 - Math.pow(1 - progress, 3) // easeOutCubic
                            : 1;

                    container.scrollLeft = startScroll + distance * easeProgress;

                    if (progress < 1 && this.isPlaying) {
                        requestAnimationFrame(animate);
                    }
                };

                requestAnimationFrame(animate);
            }
        },

        play() {
            if (!this.isPlaying && !this.isMainTimeline && this._isEndSequencePause) {
                this.$videoStudio.studio.$stage.seekSequenceTimeline(this.currentEditedItem, 0.001);
            }
            this._isEndSequencePause = false;
            this.$videoStudio.studio.$stage.toggleTimeline();

            // Démarrer l'animation frame
            this.isPlaying = true;
            this.updatePlayhead();
        },

        pause() {
            this.$videoStudio.studio.$stage.pauseTimeline();
            this.isPlaying = false;
        },

        restart() {
            this.$videoStudio.studio.$stage.seekTimeline(0);
            this.$videoStudio.studio.$stage.pauseTimeline();
        },

        seek(time) {
            if (this.isMainTimeline) {
                this.$videoStudio.studio.$stage.seekTimeline(time);
            } else {
                this.$videoStudio.studio.$stage.seekSequenceTimeline(this.currentEditedItem, Math.max(0.001, time));
                this._isEndSequencePause = false;
            }
        },

        onTimelineClick(event) {
            if (event.target.closest('.playhead')) return;
            console.log('onTimelineClick', event);

            const rect = this.$refs.container.getBoundingClientRect();
            const clickX = event.clientX - rect.left + this.$refs.container.scrollLeft;
            const time = clickX / this.pixelsPerSecond;

            // Utilisation du videoStudio pour le seek
            this.$videoStudio.studio.$stage.seekTimeline(time);
        },

        handleSequenceEnd() {
            this.$videoStudio.studio.$stage.pauseTimeline();
            this._isEndSequencePause = true;
        },

        handleWheel(event) {
            if (event.ctrlKey || event.metaKey) {
                event.preventDefault();

                const container = this.$refs.container;
                const rect = container.getBoundingClientRect();
                const mouseX = event.clientX - rect.left + container.scrollLeft;

                // Calcul du facteur de zoom
                const delta = event.deltaY > 0 ? 1 / this.zoomStep : this.zoomStep;
                this.zoom(delta, mouseX);
            }
        },

        zoom(factor, pivotX = null) {
            const container = this.$refs.container;
            const oldPixelsPerSecond = this.pixelsPerSecond;

            // Calcul de la nouvelle échelle
            let newPixelsPerSecond = oldPixelsPerSecond * factor;
            newPixelsPerSecond = Math.min(
                Math.max(newPixelsPerSecond, this.minPixelsPerSecond),
                this.maxPixelsPerSecond
            );

            if (newPixelsPerSecond === oldPixelsPerSecond) return;

            // Ajustement du scroll pour maintenir la position relative du point de pivot
            if (pivotX !== null) {
                const pivotTime = pivotX / oldPixelsPerSecond;
                const newPivotX = pivotTime * newPixelsPerSecond;
                const scrollAdjustment = newPivotX - pivotX;
                container.scrollLeft += scrollAdjustment;
            }

            this.pixelsPerSecond = newPixelsPerSecond;
            this.updateTimelineView();
        },

        zoomIn() {
            this.zoom(this.zoomStep);
        },

        zoomOut() {
            this.zoom(1 / this.zoomStep);
        },

        resetZoom() {
            this.pixelsPerSecond = this.basePixelsPerSecond;
            this.updateTimelineView();
        },

        handleMouseDown(event) {
            if (event.button === 1 || (event.button === 0 && event.altKey)) {
                event.preventDefault();
                this.isDragging = true;
                this.dragStartX = event.clientX;
                this.dragStartScrollLeft = this.$refs.container.scrollLeft;
            }
        },

        handleMouseMove(event) {
            if (this.isDragging) {
                const dx = event.clientX - this.dragStartX;
                this.$refs.container.scrollLeft = this.dragStartScrollLeft - dx;
            }

            // Stockage de la position de la souris pour le zoom
            this.mouseX = event.clientX;
            this.mouseY = event.clientY;
        },

        handleMouseUp() {
            this.isDragging = false;
        },

        updateTimelineView() {
            // Mise à jour des marqueurs et autres éléments visuels
            this.$nextTick(() => {
                this.scrollToPlayhead();
            });
        },

        startDrag(e) {
            // Vérifie si on clique sur le header
            if (!e.target.closest('.timeline-header')) return;

            this.isDragging = true;
            this.dragStartX = e.clientX - this.x;
            this.dragStartY = e.clientY - this.y;

            window.addEventListener('mousemove', this.onDrag);
            window.addEventListener('mouseup', this.stopDrag);
        },

        onDrag(e) {
            if (!this.isDragging) return;

            this.x = e.clientX - this.dragStartX;
            this.y = e.clientY - this.dragStartY;

            // Empêche de sortir de la fenêtre
            const bounds = this.$refs.visualizer.getBoundingClientRect();
            if (this.x < 0) this.x = 0;
            if (this.y < 0) this.y = 0;
            if (this.x + bounds.width > window.innerWidth) {
                this.x = window.innerWidth - bounds.width;
            }
            if (this.y + bounds.height > window.innerHeight) {
                this.y = window.innerHeight - bounds.height;
            }
        },

        stopDrag() {
            this.isDragging = false;
            window.removeEventListener('mousemove', this.onDrag);
            window.removeEventListener('mouseup', this.stopDrag);
        },

        // Ajout d'une méthode pour mettre à jour les dimensions
        updateDimensions() {
            const visualizer = this.$refs.visualizer;
            if (visualizer) {
                this.width = Math.max(visualizer.offsetWidth, this.minWidth);
                this.height = Math.max(visualizer.offsetHeight, this.minHeight);
            }
        },

        // Modification de la méthode de redimensionnement
        startResize(handle) {
            if (this.isResizing) return;

            this.isResizing = true;
            this.resizeHandle = handle;
            this.resizeStartX = event.clientX;
            this.resizeStartY = event.clientY;
            this.resizeStartWidth = this.width;
            this.resizeStartHeight = this.height;

            window.addEventListener('mousemove', this.handleResize);
            window.addEventListener('mouseup', this.stopResize);
        },

        stopResize() {
            this.isResizing = false;
            this.resizeHandle = null;
            window.removeEventListener('mousemove', this.handleResize);
            window.removeEventListener('mouseup', this.stopResize);

            // Met à jour les dimensions finales
            this.updateDimensions();
        },

        // Ajout d'un gestionnaire pour les mises à jour de la timeline
        handleTimelineChange(event) {
            // Attendre que la timeline soit complètement mise à jour
            this.$nextTick(async () => {
                // Récupérer la timeline mise à jour depuis le stage
                const timeline = this.$videoStudio.studio.$stage.getTimeline();

                if (timeline) {
                    // Forcer la timeline à recalculer sa durée
                    timeline.invalidate();

                    // Émettre un événement pour informer le parent de la mise à jour
                    this.$emit('timeline-update', timeline);

                    // Extraire les nouvelles données
                    this.extractTimelineData(timeline);

                    // Forcer la mise à jour du composant
                    this.$forceUpdate();

                    // Mettre à jour les dimensions
                    this.updateDimensions();

                    // Mettre à jour le playhead si nécessaire
                    if (this.autoScroll && this.isPlaying) {
                        this.scrollToPlayhead();
                    }
                }
            });
        },

        // Ajoute un toggle pour l'auto-scroll dans la barre d'outils
        toggleAutoScroll() {
            this.autoScroll = !this.autoScroll;
        },

        // Ajout d'une méthode pour vérifier si un élément a un timing de fin
        hasEndTiming(element) {
            return element.hasOutTimeline || element.endTime > element.startTime + element.duration;
        },

        handleTimelineUpdate() {
            // Mettre à jour les données de la timeline
            this.extractTimelineData(this.timeline);
        },

        getElementEndTimingStyles(element) {
            const baseDuration = element.duration;
            const totalDuration = element.endTime - element.startTime;
            const endTimingWidth = (totalDuration - baseDuration) * this.pixelsPerSecond;

            return {
                left: `${baseDuration * this.pixelsPerSecond}px`,
                width: `${Math.max(endTimingWidth, 0)}px`
            };
        },

        // Nouvelle fonction pour gérer les z-index
        getElementZIndex(type) {
            const zIndexes = {
                background: 1,
                visual: 2,
                video: 3,
                image: 4,
                animation: 5,
                message: 6,
                panel: 7,
                footer: 8,
                audio: 9
            };

            const baseType = type.split('-')[0];
            return zIndexes[baseType] || 1;
        }
    },

    created() {
        // Créer une version throttled de handleTimelineChange
        this.throttledHandleTimelineChange = _throttle(this.handleTimelineChange, 100);
    },

    mounted() {
        this.initDraggable();
        this._isEndSequencePause = false;

        // Utiliser nextTick pour s'assurer que le composant est complètement monté
        this.$nextTick(() => {
            // Ajouter l'écouteur d'événement directement sur window
            window.addEventListener(STAGE_TIMELINE_UPDATED_EVENT, this.handleTimelineChange);

            console.log('TimeLineVisualiser mounted, adding event listener');
        });

        // Ajouter un observateur de redimensionnement
        const resizeObserver = new ResizeObserver(() => {
            this.updateDimensions();
        });

        resizeObserver.observe(this.$refs.visualizer);

        // Stocker l'observateur pour le nettoyage
        this._resizeObserver = resizeObserver;
    },

    beforeDestroy() {
        if (this.draggable) {
            this.draggable.kill();
        }
        if (this.timeline) {
            this.timeline.eventCallback('onUpdate', null);
        }

        // Nettoyer l'écouteur d'événement sur window
        window.removeEventListener(STAGE_TIMELINE_UPDATED_EVENT, this.handleTimelineChange);

        if (this._resizeObserver) {
            this._resizeObserver.disconnect();
        }
    }
};
</script>
<style scoped>
.control-sequences {
    position: relative;
    height: 24px;
    margin-bottom: 8px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.control-marker {
    position: absolute;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    z-index: 60;
}

.marker-icon {
    font-size: 12px;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    margin-bottom: 2px;
}

.marker-label {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
    white-space: nowrap;
}

.marker-start .marker-icon {
    color: #4caf50;
    background: rgba(76, 175, 80, 0.2);
}

.marker-pause .marker-icon {
    color: #ffc107;
    background: rgba(255, 193, 7, 0.2);
}

.marker-end .marker-icon {
    color: #f44336;
    background: rgba(244, 67, 54, 0.2);
}

.control-marker:hover .marker-icon {
    transform: scale(1.1);
}

.control-marker:hover .marker-label {
    color: white;
}
.timeline-visualizer {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px; /* ou une largeur adaptée */
    height: 400px;
    background: #2f2f2f;
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    cursor: move;
    resize: both;
    overflow: hidden;
}

/* Ajout d'une barre de titre pour faciliter le drag */
.timeline-header {
    cursor: move;
    user-select: none;
}

/* Container de la timeline - devient scrollable */
.timeline-container {
    position: relative;
    overflow: auto;
    flex-grow: 1; /* Prend tout l'espace restant */
    height: calc(100% - 40px); /* Hauteur restante après le header */
    background: #2a2a2a;
}

.timeline-time {
    font-family: monospace;
    font-size: 14px;
    color: #fff;
}

/* Contrôles de zoom */
.zoom-controls {
    display: flex;
    align-items: center;
    gap: 8px;
}

.zoom-button {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    border: 1px solid #666;
    background: #333;
    color: #fff;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    transition: background-color 0.2s;
}

.zoom-button:hover {
    background: #444;
}

.zoom-info {
    font-family: monospace;
    font-size: 12px;
    color: #999;
    min-width: 50px;
    text-align: center;
}

.zoom-reset {
    padding: 4px 8px;
    border-radius: 4px;
    border: 1px solid #666;
    background: #333;
    color: #fff;
    cursor: pointer;
    font-size: 11px;
    transition: background-color 0.2s;
}

.zoom-reset:hover {
    background: #444;
}

/* Contrôles de la timeline */
.timeline-controls {
    display: flex;
    gap: 8px;
}

.timeline-controls button {
    padding: 4px 12px;
    background: #3498db;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s;
}

.timeline-controls button:hover {
    background: #2980b9;
}

.timeline-controls button:disabled {
    background: #95a5a6;
    cursor: not-allowed;
}

.timeline-content {
    position: relative;
    min-height: 100px;
    padding-top: 30px;
    will-change: transform;
}

.timeline-container::-webkit-scrollbar {
    width: 12px;
    height: 12px;
}

.timeline-container::-webkit-scrollbar-track {
    background: #1f1f1f;
}

.timeline-container::-webkit-scrollbar-thumb {
    background-color: #666;
    border-radius: 6px;
    border: 3px solid #1f1f1f;
}

/* Pour Firefox */
.timeline-container {
    scrollbar-width: thin;
    scrollbar-color: #666 #1f1f1f;
}

/* Marqueurs de temps */
.time-markers {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: #1f1f1f;
    z-index: 50;
    border-bottom: 1px solid #333;
}

.time-marker {
    position: absolute;
    transform: translateX(-50%);
}

.marker-line {
    width: 1px;
    height: 10px;
    background: #666;
}

.marker-label {
    font-size: 10px;
    color: #999;
    text-align: center;
    margin-top: 2px;
}

/* Tracks et séquences */
.sequence-track {
    position: relative;
    background: rgba(52, 152, 219, 0.1);
    border: 1px solid rgba(52, 152, 219, 0.2);
    border-radius: 4px;
    margin-top: 12px; /* Augmenté pour plus d'espace entre les séquences */
    margin-bottom: 4px;
    padding: 0;
    z-index: 51;
}

.sequence-track:hover {
    background: rgba(52, 152, 219, 0.15);
}

.sequence-track.active {
    background: rgba(52, 152, 219, 0.2);
    border-color: rgba(52, 152, 219, 0.4);
}

/* En-tête de séquence */
.sequence-header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 25px;
    padding: 4px 8px;
    background: rgba(52, 152, 219, 0.2);
    border-bottom: 1px solid rgba(52, 152, 219, 0.3);
    border-radius: 4px 4px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 52;
}

.sequence-label {
    font-family: monospace;
    font-size: 11px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
}

.sequence-timing {
    font-size: 10px;
    color: rgba(255, 255, 255, 0.7);
    margin-left: 8px;
}

.sequence-meta {
    display: flex;
    align-items: center;
    gap: 4px;
}

.time-scale {
    font-size: 10px;
    padding: 2px 4px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    color: #ddd;
}

/* Contenu de la séquence */
.sequence-content {
    position: relative;
    width: 100%;
    height: calc(100% - 25px);
    margin-top: 25px;
    padding-top: 4px; /* Ajout d'un padding en haut */
    z-index: 51;
}
/* Éléments de la timeline */
.timeline-element {
    position: absolute;
    border-radius: 3px;
    font-size: 11px;
    padding: 4px 6px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 0.2s;
    z-index: 53;
    min-height: 24px; /* Augmenté pour plus d'espace */
    margin-bottom: 4px; /* Ajoute un espace entre les éléments */
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
}

.timeline-element:hover {
    z-index: 54;
    filter: brightness(1.3);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.timeline-element .element-label {
    font-size: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgba(255, 255, 255, 0.9);
    line-height: 16px; /* Ajout d'une hauteur de ligne fixe */
}

/* Types d'éléments */
.timeline-element.transition-zone {
    background: rgba(255, 196, 0, 0.2);
    border: 1px solid rgba(255, 196, 0, 0.3);
    z-index: 2;
}

.timeline-element.background-track {
    background: rgba(149, 165, 166, 0.15);
    border: 1px solid rgba(149, 165, 166, 0.2);
    z-index: 51;
    height: auto;
    min-height: 60px; /* Augmenté pour plus d'espace */
    padding: 24px 8px 8px; /* Augmenté le padding top pour le label */
}

.timeline-element.background-track .element-label {
    position: absolute;
    top: 6px;
    left: 8px;
    font-size: 11px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: 500; /* Légèrement plus gras pour meilleure lisibilité */
    background: rgba(0, 0, 0, 0.2); /* Fond semi-transparent */
    padding: 2px 6px;
    border-radius: 3px;
}

/* Ajustement des léments enfants dans le background */
.timeline-element.background-track .timeline-element {
    position: relative;
    margin-top: 2px;
    margin-bottom: 2px;
    min-height: 20px;
}

.timeline-element.visual {
    background: rgba(46, 204, 113, 0.2);
    border: 1px solid rgba(46, 204, 113, 0.4);
}

.timeline-element.message {
    background: rgba(241, 196, 15, 0.2);
    border: 1px solid rgba(241, 196, 15, 0.3);
}

.timeline-element.panel {
    background: rgba(155, 89, 182, 0.2);
    border: 1px solid rgba(155, 89, 182, 0.3);
}

.timeline-element.footer {
    background: rgba(231, 76, 60, 0.2);
    border: 1px solid rgba(231, 76, 60, 0.3);
}

.timeline-element.audio {
    background: rgba(52, 152, 219, 0.2);
    border: 1px solid rgba(52, 152, 219, 0.3);
}

/* Labels de séquence */
.sequence-label-marker {
    position: absolute;
    top: -15px;
    transform: translateX(-50%);
    color: #fff;
    font-size: 8px;
    z-index: 5;
}

.label-marker {
    display: block;
    text-align: center;
    line-height: 1;
}

.label-start .label-marker {
    color: #4caf50;
}
.label-end .label-marker {
    color: #f44336;
}
.label-transition .label-marker {
    color: #ff9800;
}
.label-custom .label-marker {
    color: #9e9e9e;
}

:deep(.timeline-visualizer) {
    --z-base: 50;
    --z-markers: var(--z-base);
    --z-track: calc(var(--z-base) + 1);
    --z-header: calc(var(--z-base) + 2);
    --z-content: calc(var(--z-base) + 3);
    --z-element: calc(var(--z-base) + 4);
    --z-element-hover: calc(var(--z-base) + 5);
    --z-playhead: calc(var(--z-base) + 50);
}
/* Playhead */
.playhead {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 0;
    z-index: 1000;
    cursor: ew-resize;
    z-index: 100;
}

.playhead-line {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 2px;
    background: #fffce1;
    box-shadow: 0 0 4px rgba(255, 252, 225, 0.5);
}

.playhead-handle {
    position: absolute;
    top: 0;
    left: -5px;
    width: 12px;
    height: 20px;
    background: #fffce1;
    border-radius: 2px;
    box-shadow: 0 0 4px rgba(255, 252, 225, 0.5);
}

/* Tooltips */
[title] {
    position: relative;
}

[title]:hover::after {
    content: attr(title);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    border-radius: 4px;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
    margin-bottom: 5px;
    pointer-events: none;
}

/* États spéciaux */
.has-sequence-duration {
    border-bottom: 2px dashed rgba(255, 255, 255, 0.4);
}

.has-out-timeline {
    border-right: 2px dashed rgba(255, 255, 255, 0.4);
}

/* Responsive */
@media (max-width: 768px) {
    .timeline-header {
        flex-direction: column;
        height: auto;
        gap: 8px;
        padding: 8px;
    }

    .zoom-controls {
        order: 2;
    }

    .timeline-controls {
        order: 3;
        flex-wrap: wrap;
        justify-content: center;
    }
}

/* Ajout des styles pour les poignées de redimensionnement */
.resize-handle {
    position: absolute;
    background: transparent;
    z-index: 9999;
}

.resize-handle.right {
    right: -5px;
    top: 0;
    width: 10px;
    height: 100%;
    cursor: ew-resize;
}

.resize-handle.bottom {
    bottom: -5px;
    left: 0;
    height: 10px;
    width: 100%;
    cursor: ns-resize;
}

.resize-handle.bottom-right {
    right: -5px;
    bottom: -5px;
    width: 15px;
    height: 15px;
    cursor: nwse-resize;
}

.resize-handle.bottom-left {
    left: -5px;
    bottom: -5px;
    width: 15px;
    height: 15px;
    cursor: nesw-resize;
}

.resize-handle.top-right {
    right: -5px;
    top: -5px;
    width: 15px;
    height: 15px;
    cursor: nesw-resize;
}

.resize-handle.top-left {
    left: -5px;
    top: -5px;
    width: 15px;
    height: 15px;
    cursor: nwse-resize;
}

/* Modification du style du conteneur principal */
.timeline-visualizer {
    position: fixed;
    min-width: 40px;
    min-height: 20px;
    background: #2f2f2f;
    border-radius: 4px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    z-index: 9999;
    cursor: move;
    resize: both;
    overflow: hidden;
}

.auto-scroll-button {
    padding: 4px 12px;
    background: #444;
    border: none;
    border-radius: 4px;
    color: white;
    cursor: pointer;
    font-size: 12px;
    transition: background-color 0.2s;
}

.auto-scroll-button.active {
    background: #3498db;
}

.auto-scroll-button:hover {
    background: #555;
}

.auto-scroll-button.active:hover {
    background: #2980b9;
}

.auto-scroll-icon {
    font-size: 14px;
}

.element-end-timing {
    position: absolute;
    top: 0;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    border-left: 1px dashed rgba(255, 255, 255, 0.5);
}

.has-out-timeline {
    border-right: 2px dashed rgba(255, 255, 255, 0.5);
}

/* Ajout d'un indicateur de type */
.timeline-element::before {
    content: attr(data-type);
    position: absolute;
    left: 4px;
    top: 2px;
    font-size: 9px;
    color: rgba(255, 255, 255, 0.7);
    background: rgba(0, 0, 0, 0.3);
    padding: 1px 4px;
    border-radius: 2px;
}
</style>
