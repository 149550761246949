<!-- TimeDisplay.vue -->
<template>
    <div class="time-container">
        <div class="time-container-content">
            <div class="time-layout">
                <span class="formattedTime">
                    {{ formattedTime }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { formatTime } from '@/js/videos/utils/time';

interface Props {
    elapsedTime: number;
}

const props = defineProps<Props>();

const formattedTime = computed(() => formatTime(props.elapsedTime));
</script>

<style scoped>
.time-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.time-container-content {
    display: flex;
    justify-content: center;
    width: 64px;
}

.time-container {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.formattedTime {
    color: white;
    font-size: 14px;
    font-weight: bold;
}

.formattedEndTime {
    color: white;
    font-size: 14px;
}
</style>
