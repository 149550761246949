<template>
    <div
        v-if="video.permissions.submit && needsValidation"
        class="video-action-button ui-simple-button ui-simple-button-select-reversed"
        :class="{ disabled: isProducing }"
        @click="askValidation"
    >
        <fa-icon icon="fa-regular fa-user-check" class="icon" />
        {{ $t('Ask validation') }}
    </div>
    <template v-else-if="video.permissions.validate && validatingUserIds.includes(Number(video.ownerId))">
        <div class="video-action-button ui-simple-button ui-simple-button-select-reversed" @click="approveVideo">
            <fa-icon icon="fa-regular fa-check" class="icon" />
            {{ $t('Approve') }}
        </div>

        <div class="video-action-button ui-simple-button ui-simple-button-select-red" @click="rejectVideo">
            <fa-icon icon="fa-regular fa-xmark" class="icon" />
            {{ $t('Reject') }}
        </div>
    </template>
    <div
        v-else-if="video.validationStatus !== VALIDATION_STATUS_NONE"
        :class="`video-validation-information validation-status-${video.validationStatus}`"
    >
        <fa-icon :icon="validationStatusIcon[video.validationStatus]" class="icon" />
        {{ $t(`video.validation.info-status-${video.validationStatus}`) }}
    </div>
</template>

<script setup>
import { useModal } from '@/js/composables/useModal.js';
import {
    VALIDATION_STATUS_NONE,
    VALIDATION_STATUS_REJECTED,
    VALIDATION_STATUS_SUBMITTED,
    VALIDATION_STATUS_VALIDATED
} from '@/js/constants/index.js';

const emit = defineEmits(['status-updated']);
const props = defineProps({
    video: Object,
    isProducing: Boolean,
    needsValidation: Boolean,
    validatingUserIds: Array
});

const { openModal } = useModal();

const validationStatusIcon = {
    1: ['far', 'hourglass'],
    2: ['far', 'check'],
    3: ['far', 'xmark']
};

const askValidation = () =>
    openModal('SubmitVideoValidationModal', {
        videoId: props.video.id,
        successCallback: () => emit('status-updated', VALIDATION_STATUS_SUBMITTED)
    });
const approveVideo = () =>
    openModal('ActionVideoValidationModal', {
        videoId: props.video.id,
        action: 'approve',
        successCallback: () => emit('status-updated', VALIDATION_STATUS_VALIDATED)
    });
const rejectVideo = () =>
    openModal('ActionVideoValidationModal', {
        videoId: props.video.id,
        action: 'reject',
        successCallback: () => emit('status-updated', VALIDATION_STATUS_REJECTED)
    });
</script>
