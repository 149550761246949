import ToastNotificationService from '@/js/application/services/Notification/ToastNotificationService.js';
import i18n from '@/libs/i18n.js';

export function useNotification(type) {
    let notifyService;
    const { t } = i18n.global;

    switch (type) {
        case 'toast':
            notifyService = new ToastNotificationService();
            break;
        default:
            notifyService = new ToastNotificationService();
            break;
    }

    const loading = (message, duration) => {
        notifyService.loading(t(message), duration);
    };

    const success = (message, duration) => {
        notifyService.success(t(message), duration);
    };

    const error = (message, duration) => {
        notifyService.error(t(message), duration);
    };

    const warning = (message, duration) => {
        notifyService.warning(t(message), duration);
    };

    const info = (message, duration) => {
        notifyService.info(t(message), duration);
    };

    return {
        loading,
        success,
        error,
        warning,
        info
    };
}
