<template>
    <Teleport to=".preview-layout">
        <div
            v-if="Object.values(availableActions).some((action) => !!action)"
            class="studio-editing-frame-actions-container"
            :style="{ top: actionMenuTop }"
        >
            <div v-if="!scaling" class="studio-editing-frame-actions">
                <div
                    v-if="availableActions.crop && !cropping"
                    class="studio-editing-frame-action"
                    v-tooltip.light="$t('editing-frame.action.crop')"
                    @click="$emit('init-crop')"
                >
                    <fa-icon icon="fa-regular fa-crop-simple" />
                </div>
                <div
                    v-if="availableActions.scale && !cropping"
                    class="studio-editing-frame-action"
                    v-tooltip.light="$t('editing-frame.action.scale')"
                    @click="scaling = true"
                >
                    <fa-icon icon="fa-regular fa-arrows-maximize" />
                </div>
                <div
                    v-if="availableActions.quickCut && !cropping"
                    class="studio-editing-frame-action"
                    v-tooltip.light="
                        isConverting ? $t('media.preparation.warning') : $t('editing-frame.action.quick-cut')
                    "
                    v-bind="{ disabled: isConverting || null }"
                    @click="!isConverting ? $emit('open-quickcut') : null"
                >
                    <fa-icon icon="fa-regular fa-scissors" />
                </div>
                <div
                    v-if="availableActions.flip && !cropping"
                    :class="['studio-editing-frame-action', { active: flipH }]"
                    v-tooltip.light="$t('editing-frame.action.horizontal-flip')"
                    @click="flipHorizontally"
                >
                    <fa-icon icon="fa-regular fa-reflect-horizontal" />
                </div>
                <div
                    v-if="availableActions.flip && !cropping"
                    :class="['studio-editing-frame-action', { active: flipV }]"
                    v-tooltip.light="$t('editing-frame.action.vertical-flip')"
                    @click="flipVertically"
                >
                    <fa-icon icon="fa-regular fa-reflect-vertical" />
                </div>

                <div
                    v-if="cropping"
                    v-for="aspectRatio in aspectRatios"
                    :class="['studio-editing-frame-action', { active: selectedRatio === aspectRatio.ratio }]"
                    v-tooltip.light="$t(aspectRatio.tooltipKey)"
                    @click="changeAspectRatio(aspectRatio.ratio)"
                >
                    <fa-icon :icon="aspectRatio.icon" />
                </div>

                <div v-if="cropping" class="studio-editing-frame-divider"></div>

                <div
                    v-if="cropping"
                    class="studio-editing-frame-action"
                    v-tooltip.light="$t('Cancel')"
                    @click="cancelCrop"
                >
                    <fa-icon icon="fa-xmark" />
                </div>
                <div
                    v-if="cropping"
                    class="studio-editing-frame-action"
                    v-tooltip.light="$t('confirm')"
                    @click="$emit('save-crop')"
                >
                    <fa-icon icon="fa-check" />
                </div>
            </div>

            <template v-else>
                <div class="studio-editing-frame-actions scale-input">
                    <span>{{ $t('Scale') }}</span>
                    <input
                        type="range"
                        class="scale-range"
                        v-model="scale"
                        max="250"
                        min="100"
                        step="1"
                        @input="updateScale(false)"
                        @change="updateScale(true)"
                    />
                    <span class="value">{{ Math.round(scale) }}%</span>
                </div>

                <div class="studio-editing-frame-actions">
                    <button class="validate-button" @click="close">{{ $t('confirm') }}</button>
                </div>
            </template>

            <div v-if="availableActions.scale && !cropping && !scaling" class="studio-editing-frame-actions">
                <button class="validate-button" @click="$emit('close')">{{ $t('confirm') }}</button>
            </div>
        </div>
    </Teleport>
</template>

<script>
import { computed, defineComponent, inject, ref, watch } from 'vue';
import { Dimension, Edition } from '../constants';
import { useHistory } from '@/js/videos/composables/useHistory';
import UsesTooltip from '@/js/mixins/UsesTooltip.js';
import { throttle } from 'lodash';
import { useEditingElement } from '@/js/videos/composables/useEditingElement.js';

export default defineComponent({
    name: 'EditingFrameActions',
    compilerOptions: {
        whitespace: 'preserve'
    },

    emits: ['init-crop', 'aspect-ratio', 'save-crop', 'cancel-crop', 'open-quickcut', 'update-scale', 'close'],

    props: {
        cropping: Boolean,
        editingElement: Object
    },

    mixins: [UsesTooltip],

    data() {
        return {
            aspectRatios: Edition.CROPPING_RATIOS
        };
    },

    setup(props, { emit }) {
        const { saveHistoryStep } = useHistory();
        const { isConverting } = useEditingElement();
        const videoStudio = inject('$videoStudio');
        const stage = inject('$stage');
        const actionMenuTop = ref('0px');

        const computeScale = (value, ref) => ((value || 1) * 100) / (ref || 1);
        const scale = ref(computeScale(props.editingElement.state.scale?.value, props.editingElement.state.scale?.ref));
        watch(
            () => props.editingElement.state.scale?.value,
            (newScale) => (scale.value = computeScale(newScale, props.editingElement.state.scale?.ref))
        );

        // calculate position of actions menu relative to edit-preview container
        const stageResizeObserver = new ResizeObserver(([entry]) => {
            const wrapperRect = entry.target.getBoundingClientRect();
            const stageRect = stage.$el.getBoundingClientRect();

            // manually add 10px
            actionMenuTop.value = stageRect.top - wrapperRect.top + 10 + Dimension.PIXEL_UNIT;
        });
        stageResizeObserver.observe(videoStudio.value.$el.closest('.preview-layout'));

        // determine which actions are available
        const availableActions = computed(() => ({
            scale: !!props.editingElement.getAnimatedRef,
            crop: !!props.editingElement.visualSelector && (props.editingElement.image || props.editingElement.video),
            quickCut:
                (!!props.editingElement.visualSelector && props.editingElement.video) ||
                (!!props.editingElement.getAnimatedRef && !!props.editingElement.$refs.$bgVideo),
            flip:
                (!!props.editingElement.visualSelector && (props.editingElement.image || props.editingElement.video)) ||
                !!props.editingElement.$refs.$lottieContainer
        }));

        // only Background
        const canScale = computed(() => !!props.editingElement.getAnimatedRef);

        // functions to flip asset on X and Y axis
        const flipHorizontally = () =>
            saveHistoryStep(() => props.editingElement.setHorizontalFlip(!props.editingElement.state.flip.horizontal));
        const flipVertically = () =>
            saveHistoryStep(() => props.editingElement.setVerticalFlip(!props.editingElement.state.flip.vertical));

        // force aspect ratio of EditingFrame
        const selectedRatio = ref(null);
        const changeAspectRatio = (ratio) => {
            if (ratio !== selectedRatio.value) {
                selectedRatio.value = ratio;
                emit('aspect-ratio', ratio);
            }
        };

        // cancel crop state - reset selected aspect ratio
        const cancelCrop = () => {
            emit('cancel-crop');
            selectedRatio.value = null;
        };

        const scaling = ref(false);
        const updateScale = throttle(
            (save) => emit('update-scale', { save, value: (scale.value * props.editingElement.state.scale.ref) / 100 }),
            Edition.MAXIMUM_REFRESH_TIME
        );
        const close = () => (scaling.value = false);

        return {
            availableActions,
            actionMenuTop,
            canScale,
            scale,
            scaling,
            isConverting,

            flipHorizontally,
            flipVertically,

            selectedRatio,
            changeAspectRatio,

            cancelCrop,
            updateScale,
            close,

            flipH: computed(() => props.editingElement.state.flip.horizontal),
            flipV: computed(() => props.editingElement.state.flip.vertical)
        };
    }
});
</script>
