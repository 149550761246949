import SaveVideoApiService from '@/js/infrastructure/api/video/SaveVideoApiService.js';

export default class SaveVideoService {
    constructor(store) {
        this.saveVideoApiService = new SaveVideoApiService(store);
    }

    async handle(params) {
        return this.saveVideoApiService.handle(params);
    }
}
