<template>
    <ui-dropdown
        :id="'card-seq-' + cardElementId + '-video-duration'"
        :menu="false"
        :caret="false"
        :scrollable="false"
        menu-placement="bottom"
        menu-strategy="fixed"
        button-class="ui-speed-selector-button"
        :disabled="enableDropDown"
        :toggle-style="toggleStyle"
        :icon-only="iconOnly"
        :tooltip="tooltip || (enableDropDown ? null : $t('Speed'))"
    >
        <template #dropdown-toggle>
            <fa-icon class="icon" icon="fa-regular fa-gauge-high" />
        </template>
        <ui-number-input
            :id="cardElementId + '-speed'"
            class="ui-content-row"
            v-model="playbackRate"
            :default-value="playbackRateDefault"
            :min="0.25"
            :max="4"
            :step="0.25"
            :label="$t('Speed')"
            :disabled="readOnly"
        />
    </ui-dropdown>
</template>

<script>
import { computed, defineComponent } from 'vue';
import UiIcon from '../../../../../../components/UiIcon.vue';
import UiNumberInput from '../../../../../../components/UiNumberInput.vue';
import UiDropdown from '../../../../../../components/UiDropdown.vue';
import { useElement } from '../../../../../composables/useElement';
import { Duration } from 'cte-video-studio';

export default defineComponent({
    name: 'UiVideoSpeedSelector',
    components: {
        UiIcon,
        UiNumberInput,
        UiDropdown
    },
    props: {
        seqId: String,
        cardElementId: String,
        elementId: String,
        readOnly: Boolean,
        iconOnly: Boolean,
        tooltip: String
    },
    setup(props) {
        const { videoState, playbackRate } = useElement(props.seqId, props.elementId);

        const playbackRateDefault = Duration.PLAY_BACK_RATE_DEFAULT;

        const toggleStyle = computed(() => {
            return playbackRate.value != Duration.PLAY_BACK_RATE_DEFAULT ? { color: 'var(--edit-mode-color)' } : {};
        });

        const enableDropDown = computed(() => {
            return props.readOnly || !videoState.value?.src;
        });

        return {
            playbackRate,
            playbackRateDefault,
            toggleStyle,
            enableDropDown
        };
    }
});
</script>
