import { toast, updateGlobalOptions } from 'vue3-toastify';
import NotificationInterface from '@/js/application/services/Notification/NotificationInterface.js';

export default class ToastNotificationService extends NotificationInterface {
    constructor() {
        super();

        this.defaultDuration = 5000;

        // Define the default options for the toast notifications.
        updateGlobalOptions({
            position: toast.POSITION.BOTTOM_CENTER,
            transition: 'slide',
            theme: 'colored',
            hideProgressBar: true
        });
    }
    loading(message, duration = this.defaultDuration) {
        const tid = toast.loading(message);

        setTimeout(() => {
            toast.remove(tid);
        }, duration);
    }

    success(message, duration = this.defaultDuration) {
        toast.success(message, {
            autoClose: duration
        });
    }

    error(message, duration = this.defaultDuration) {
        toast.error(message, {
            autoClose: duration
        });
    }

    warning(message, duration = this.defaultDuration) {
        toast.warn(message, {
            autoClose: duration
        });
    }

    info(message, duration = this.defaultDuration) {
        toast.info(message, {
            autoClose: duration
        });
    }
}
