import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

export default class GeneratePlayerService {
    constructor(store) {
        this.store = store;
        this.editionUrl = import.meta.env.VITE_EDITION_API_URL;
    }

    generate(videoUuid, params) {
        return executeRequest(
            this.store,
            {
                request: async (client) => {
                    return await client.post(`${this.editionUrl}/api/videos/${videoUuid}/player`, params);
                },
                onSuccess: async (response) => response.data,
                actionType: 'GenerateVideoPlayer'
            },
            false
        );
    }
}
