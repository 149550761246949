<template>
    <div v-if="!isOpenQuickCut" class="preview-actions">
        <SequencePreviewButton v-if="enableSequencePreview" />
        <FullPreviewButton v-if="!sequenceMode" />
        <ExternalPreviewButton v-if="showSharePreviewButton" />
        <ValidationButtons />
        <ProductionButton v-if="enableActionsMenu && !isTemplate" />
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import SequencePreviewButton from './actions/SequencePreviewButton.vue';
import FullPreviewButton from './actions/FullPreviewButton.vue';
import ExternalPreviewButton from './actions/ExternalPreviewButton.vue';
import ProductionButton from './actions/ProductionButton.vue';
import { useAuth } from '@/js/composables/useAuth.js';
import { useStoreManagement } from './VideoEditor/composables/useStoreManagement.js';
import ValidationButtons from '@/js/videos/components/controls/actions/ValidationButtons.vue';

const props = defineProps({
    enableSequencePreview: {
        type: Boolean,
        default: true
    },
    enableActionsMenu: {
        type: Boolean,
        default: true
    },
    isTemplate: {
        type: Boolean,
        default: false
    }
});

const { user } = useAuth();
const store = useStore();
const { isOpenQuickCut } = useStoreManagement(store);

const sequenceMode = computed(() => store.state.ui.sequenceMode);
const showSharePreviewButton = computed(() => props.enableActionsMenu && user.value.client.enableExternalLinks);
</script>
