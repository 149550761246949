class NotificationInterface {
    loading(message, duration) {
        throw new Error('Method "loading" must be implemented');
    }
    success(message, duration) {
        throw new Error('Method "success" must be implemented');
    }
    error(message, duration) {
        throw new Error('Method "error" must be implemented');
    }
    warning(message, duration) {
        throw new Error('Method "warning" must be implemented');
    }
    info(message, duration) {
        throw new Error('Method "info" must be implemented');
    }
}

export default NotificationInterface;
