import { Duration, Sound } from '@/js/video-studio/constants';
import { conversions, types } from '@/js/video-studio/utils';
import _cloneDeep from 'lodash/cloneDeep';
import simpleMedia from '@/js/store/modules/commons/simple-media.js';

const state = () => ({
    ...simpleMedia.state(),

    captions: false,
    captioning: false,
    captioningStatus: null,

    volume: Sound.VOLUME_DEFAULT,
    start: Duration.START_DEFAULT,
    playbackRate: Duration.PLAY_BACK_RATE_DEFAULT,
    timerange: {
        start: Duration.START_DEFAULT,
        end: Duration.END_DEFAULT
    },
    timerangeSegments: [],
    totalDuration: () => 0,

    fade: {
        value: Sound.BACKGROUND_FADE_DEFAULT,
        custom: Sound.FADE_EMPTY
    }
});

const getters = {
    track: (state) => {
        return state.timerange &&
            state.timerange.start !== Duration.START_DEFAULT &&
            state.timerange.end !== Duration.END_DEFAULT
            ? conversions.mediaFragmentAssetSrc(state.src, state.totalDuration(), state.timerange)
            : state.src;
    },

    volume: (state) => {
        return state.volume;
    },

    start: (state) => {
        return state.start;
    },

    playbackRate: (state) => {
        return state.playbackRate;
    },

    timerange: (state) => {
        return state.timerange;
    },

    captions: (state) => {
        return state.captions;
    },

    captioning: (state) => {
        return state.captioning;
    },

    captioningStatus: (state) => {
        return state.captioningStatus;
    },

    volumeFade: (state) => conversions.optionValue(state.fade, [], Sound.BACKGROUND_FADE_DEFAULT),

    totalDuration: (state) => {
        return state.totalDuration();
    }
};

const mutations = {
    ...simpleMedia.mutations,

    setVolume(state, volume) {
        state.volume = volume;
    },

    setStart(state, start) {
        state.start = start;
    },

    setPlaybackRate(state, playbackRate) {
        state.playbackRate = playbackRate;
    },

    setTimerange(state, timerange) {
        state.timerange = Object.assign({ start: 0, end: -1 }, timerange || {});
    },

    setTimerangeSegments(state, segments) {
        state.timerangeSegments = Array.isArray(segments) && !!segments.length ? _cloneDeep(segments) : [];
    },

    setTotalDuration(state, totalDuration) {
        state.totalDuration = () => totalDuration;
    },

    setCaptions(state, captions) {
        state.captions = Array.isArray(captions) && !!captions.length && captions;
    },

    setCaptioning(state, captioning) {
        state.captioning = !!captioning;
    },

    setCaptioningStatus(state, status) {
        state.captioningStatus = status;
    },

    setVolumeFade(state, fade) {
        if (types.isZeroPositive(fade.value)) {
            state.fade.value = parseFloat(fade.value);
            state.fade.custom = Sound.FADE_EMPTY;
        } else if (types.isZeroPositive(fade.custom)) {
            state.fade.value = Sound.FADE_CUSTOM;
            state.fade.custom = parseFloat(fade.custom);
        } else {
            state.fade.value = Sound.BACKGROUND_FADE_DEFAULT;
            state.fade.custom = Sound.FADE_EMPTY;
        }
    }
};

const actions = {
    init({ commit, dispatch }, data) {
        simpleMedia.actions.init({ commit }, data);

        if (data.volume !== Sound.VOLUME_DEFAULT) commit('setVolume', data.volume);
        if (data.start && data.start !== Duration.START_DEFAULT) commit('setStart', data.start);
        if (data.playbackRate && data.playbackRate !== Duration.PLAY_BACK_RATE_DEFAULT)
            commit('setPlaybackRate', data.playbackRate);
        if (data.timerange) commit('setTimerange', data.timerange);
        if (data.timerangeSegments) commit('setTimerangeSegments', data.timerangeSegments);
        if (data.captions) commit('setCaptions', data.captions);
        if (data.fade) commit('setVolumeFade', data.fade);

        if (data.captioning) dispatch('startCaptioning');
    },

    update({ commit, state }, data) {
        simpleMedia.actions.update({ commit }, data);

        if (!data.src__id) {
            commit('setTotalDuration', 0);
        }
        if (state.src__id !== data.src__id) {
            commit('setTimerange', { start: 0, end: -1 });
        }
    },

    startCaptioning(context) {
        let { commit, dispatch, state, getters } = context;

        if (!!state.src__id) {
            commit('setCaptioning', true);
            dispatch(
                'ui/processes/startMediaCaptioningProcess',
                {
                    mediaId: state.src__id,
                    subscriberId: getters.path + '/setCaptions',
                    success: (captions) => {
                        dispatch('ui/history/startStep', null, { root: true });
                        commit('setCaptions', captions);
                        commit('setCaptioning', false);
                        commit('setCaptioningStatus', null);
                        dispatch('ui/saveVideo', null, { root: true });
                    },
                    error: (error) => {
                        commit('setCaptioning', false);
                        commit('setCaptioningStatus', error);
                        dispatch('ui/saveVideo', null, { root: true });
                    }
                },
                { root: true }
            );
        }
    },

    cancelCaptioning({ commit, dispatch, state, getters }) {
        if (!!state.src__id && state.captioning) {
            dispatch(
                'ui/processes/cancelMediaCaptioningProcess',
                {
                    mediaId: state.src__id,
                    subscriberId: getters.path + '/setCaptions'
                },
                { root: true }
            );
            commit('setCaptioning', false);
            commit('setCaptioningStatus', null);
            dispatch('ui/saveVideo', null, { root: true });
        }
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
