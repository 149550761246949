export default class TemplateDTO {
    constructor(data) {
        this.id = data.id;
        this.name = data.name;
        this.organizationId = data.organization_id;
        this.brandId = data.brand_id;
        this.isMadeByCte = data.is_made_by_cte;
        this.ownerId = data.owner_id;
        this.format = {
            size: data.format?.size,
            width: data.format?.width,
            height: data.format?.height
        };
        this.language = data.language;
        this.categories = data.categories;
        this.compatibleFormats = data.compatible_formats;
        this.description = data.description;
        this.sequencesTotal = data.sequences_total;
        this.needsMigration = data.needs_migration;
        this.thumbnails = {
            staticSrc: data.thumbnails?.static_src,
            previewSrc: data.thumbnails?.preview_src,
            videoSrc: data.thumbnails?.video_src
        };
        this.hasPendingProduction = data.has_pending_production;
        this.isProducing = data.is_producing;
        this.producedAt = data.produced_at;
        this.latestProducedUrl = data.latest_produced_url;
        this.latestProducedPosterUrl = data.latest_produced_poster_url;
        this.latestProducedIsOutdated = data.latest_produced_is_outdated;
        this.createdAt = data.created_at;
        this.updatedAt = data.updated_at;
        this.readableCreatedAt = data.readable_created_at;
        this.readableUpdatedAt = data.readable_updated_at;
        this.permissions = {
            edit: data.permissions?.edit,
            copy: data.permissions?.copy,
            move: data.permissions?.move,
            createFrom: data.permissions?.create_from
        };
    }
}
