<template>
    <splash-screen v-if="loading" />
    <template v-else>
        <div class="router-view-wrapper" :class="{ 'with-navigation': !hideNavigation }">
            <RouterView />
        </div>
        <div class="view-frame" v-if="!hideNavigation">
            <ui-nav />
            <div class="view-frame-border" />
        </div>
        <new-update-toast />
        <ui-main-modal />
    </template>
</template>

<script setup>
import { computed, onMounted, watch } from 'vue';
import { RouterView, useRoute } from 'vue-router';
import UiNav from '@/js/components/UiNav.vue';
import UiMainModal from '@/js/components/modals/UiMainModal.vue';
import SplashScreen from '@/js/components/SplashScreen.vue';
import { useModal } from '@/js/composables/useModal.js';
import { cgsData, loading } from '@/router/index.js';
import NewUpdateToast from '@/js/components/NewUpdateToast.vue';
import { useStore } from 'vuex';
import SubscribeNotificationsChannelService from '@/js/application/services/Notification/SubscribeNotificationsChannelService.js';

const store = useStore();
const route = useRoute();
const { openModal } = useModal();
import { watchEffect } from 'vue';

// prevents loading of ui-nav at first load when route metadata are not yet loaded
const hideNavigation = computed(() => (route.meta.hasOwnProperty('hideNavigation') ? route.meta.hideNavigation : true));

const trackView = () => {
    let _hsq = (window._hsq = window._hsq || []);
    _hsq.push(['setPath', route.fullPath]);
    _hsq.push(['trackPageView']);
    console.log('Tracked view: ', route.fullPath);
};

watch(route, () => {
    trackView();
});

const initializeWebsocket = (store) => {
    const userId = computed(() => store.state.auth.user.id);

    watchEffect(() => {
        if (userId.value !== undefined) {
            new SubscribeNotificationsChannelService().subscribe(userId.value);
        }
    });
};

onMounted(() => {
    trackView();
    initializeWebsocket(store);

    watch(cgsData, () => {
        // open CGS modal if any
        if (!!cgsData.value.length) openModal('CGSModal', { cgs: cgsData.value[0] });
    });
});
</script>
