<template>
    <div class="export-video-integration-export">
        <div class="export-video-integration-player">
            <fa-icon v-if="isGenerating" class="icon" icon="fa-solid fa-spinner-third" spin />
            <div v-else v-html="embedded" />
        </div>

        <div class="export-video-integration-data">
            <div class="integration-block">
                <strong>{{ $t('export-video-integration-button-title') }}</strong>

                <div class="integration-field">
                    <ui-text-input id="integration-title" :label="$t('Title')" v-model="values.title" />

                    <ui-text-input id="integration-label" :label="$t('Button label')" v-model="values.buttonLabel" />

                    <ui-text-input
                        id="integration-link"
                        :label="$t('Button link')"
                        placeholder="https://"
                        v-model="values.buttonLink"
                    />

                    <ui-color-selector
                        id="player-color"
                        v-model:color="color"
                        default-color="color1"
                        :label="$t('Player color')"
                    />
                </div>
            </div>
        </div>

        <button class="ui-simple-button copy-player-embed" @click="copyEmbedded" :disabled="isGenerating">
            <fa-icon class="icon" :icon="`fa-regular ${copied ? 'fa-check' : 'fa-copy'}`" />
            <span>{{ copied ? $t('Copied') : $t('export-video-integration-copy-code') }}</span>
        </button>
    </div>
</template>

<script setup>
import { ref, watch, onMounted, shallowReactive } from 'vue';
import { useStore } from 'vuex';
import { debounce } from 'lodash';
import GeneratePlayerService from '@/js/infrastructure/api/video/GeneratePlayerService.js';
import UiTextInput from '@/js/components/UiTextInput.vue';
import UiColorSelector from '@/js/components/UiColorSelector.vue';
import Color from '@/js/video-studio/constants/color.js';
import FetchBrandsDataService from '@/js/application/services/brands/FetchBrandsDataService.js';
import { INTEGRATION_VIDEO_PLAYER_SIZES } from '@/js/constants/index.js';

const props = defineProps({
    videoId: String,
    folderId: String
});

const store = useStore();
const generatePlayerService = new GeneratePlayerService(store);
const brandService = new FetchBrandsDataService(store);

const color = ref({ ref: Color.NONE, value: Color.NONE });
const values = shallowReactive({
    width: INTEGRATION_VIDEO_PLAYER_SIZES.width,
    height: INTEGRATION_VIDEO_PLAYER_SIZES.height,
    title: '',
    buttonLabel: '',
    buttonLink: ''
});

const embedded = ref('');
const isGenerating = ref(true);
const generatePlayer = async () => {
    isGenerating.value = true;

    const response = await generatePlayerService.generate(props.videoId, { ...values, color: color.value.value });
    embedded.value = response.data.embed;

    isGenerating.value = false;
};
const debouncedGeneration = debounce(generatePlayer, 700);

const copied = ref(false);
const copyEmbedded = () => {
    copied.value = true;
    navigator.clipboard.writeText(embedded.value);

    setTimeout(() => (copied.value = false), 3000);
};

onMounted(async () => {
    const brandColors = await brandService.fetchBrandColorsFromFolderId(props.folderId);
    color.value = { ref: brandColors.color1, value: brandColors.color1 };

    generatePlayer();
    watch([values, color], debouncedGeneration); // create watcher after first load since we change the color after HTTP request
});
</script>

<style lang="scss" scoped>
.export-video-integration-player {
    display: flex;
    margin: auto;
    width: 640px;
    height: 360px;

    :deep(iframe) {
        width: 640px;
        height: 360px;
        border-radius: 10px;
    }

    svg {
        width: 50px;
        margin: auto;
    }
}

.export-video-integration-data {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 20px;

    .integration-block {
        display: flex;
        flex-direction: column;
        row-gap: 10px;

        .integration-field {
            display: flex;
            flex-direction: column;
            row-gap: 15px;
            margin-left: 20px;

            :deep(label) {
                width: 160px;
                margin-right: 10px !important;
            }

            :deep(input) {
                width: 50%;
            }
        }
    }

    :deep(button#player-color-selector) {
        width: 3rem;
        height: 3rem;
    }
}

.copy-player-embed {
    margin: auto;
}
</style>
