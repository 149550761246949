import TranslateApiService from '@/js/infrastructure/api/translate/TranslateApiService.js';

import i18n from '@/libs/i18n.js';
import { useStore } from 'vuex';
import { ref, onMounted } from 'vue';

export function useTranslateOptions() {
    const store = useStore();

    const templateOptionsIsLoading = ref(true);

    const translateOptions = ref([]);

    const selectedTranslateOption = ref(null);

    const { t } = i18n.global;

    const fetchTranslateOptions = async () => {
        templateOptionsIsLoading.value = true;

        let translateOptions = await new TranslateApiService(store).fetchTranslateOptions();

        if (!translateOptions) return;

        translateOptions = translateOptions.data.map(localizeLabels);

        addTranslateOptions(translateOptions);
    };

    const localizeLabels = (option) => {
        option.label = t('languages.' + option.db_video_language);
        return option;
    };

    const addTranslateOptions = (options) => {
        translateOptions.value = options;
        templateOptionsIsLoading.value = false;
    };

    const resetDataForTranslateOptions = () => {
        translateOptions.value = [];
        selectedTranslateOption.value = null;
    };

    onMounted(() => {
        fetchTranslateOptions();
    });

    return {
        templateOptionsIsLoading,
        translateOptions,
        selectedTranslateOption,
        fetchTranslateOptions,
        resetDataForTranslateOptions
    };
}
