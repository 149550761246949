<template>
    <div class="segment-editor-container">
        <transition name="slide-up" mode="out-in">
            <div class="segment-editor" v-if="selectedSegment" :key="selectedSegmentIndex">
                <div class="time-code-input-container">
                    <QuickCutTimecodeInput
                        v-model="startTime"
                        :rate="rate"
                        :label="$t('start-of-clip')"
                        :min="0"
                        :max="endTime - 0.01"
                        :step="0.1"
                        @update:modelValue="updateSegment('start')"
                    />
                    <QuickCutTimecodeInput
                        v-model="endTime"
                        :rate="rate"
                        :label="$t('end-of-clip')"
                        :min="startTime + 0.01"
                        :step="0.1"
                        @update:modelValue="updateSegment('end')"
                    />
                    <div class="remove-container">
                        <button @click="removeSegment" :disabled="!canRemoveSegment" class="remove-item">
                            <fa-icon class="icon" icon="fa-regular fa-trash-can" />
                        </button>
                    </div>
                </div>
                <div class="time-media-container">
                    <fa-icon class="icon" :icon="['fas', 'circle-info']" />
                    <span>{{ $t('Total duration') }} : </span>
                    <span>{{ formatMediaDuration }}</span>
                </div>
            </div>
        </transition>
    </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import QuickCutTimecodeInput from './QuickCutTimecodeInput.vue?inline';
import { Duration } from '@/js/video-studio/studio';
import { formatTime } from '@/js/videos/utils/time';
import type { Segment } from '@/js/videos/types/segments';

interface Props {
    segments: Segment[];
    selectedSegmentIndex: number;
    rate: number;
    mediaDuration: number;
    mediaMaxDuration: number;
    playbackRate?: number;
}

const props = withDefaults(defineProps<Props>(), {
    playbackRate: Duration.PLAY_BACK_RATE_DEFAULT
});

const emit = defineEmits<{
    (event: 'update-segment', index: number, segment: Segment): void;
    (event: 'remove-segment', index: number): void;
}>();

const startTime = ref<number>(0);
const endTime = ref<number>(0);

const selectedSegment = computed(() =>
    props.selectedSegmentIndex !== -1 ? props.segments[props.selectedSegmentIndex] : null
);

const canRemoveSegment = computed(() => {
    if (!props.segments.length) return false;
    const firstSegment = props.segments[0];
    return props.segments.length > 1 || firstSegment.start !== 0 || firstSegment.end !== props.mediaMaxDuration;
});

const formatMediaDuration = computed(() => {
    const time = props.mediaDuration * props.playbackRate;
    const frames = Math.round((time % 1) * props.rate);
    return formatTime(time, props.rate, frames);
});

watch(
    selectedSegment,
    (newSegment) => {
        if (newSegment) {
            startTime.value = newSegment.start;
            endTime.value = newSegment.end;
        }
    },
    { immediate: true }
);

const updateSegment = (type: 'start' | 'end') => {
    if (selectedSegment.value) {
        const updatedSegment: Segment = { ...selectedSegment.value };
        if (type === 'start') {
            updatedSegment.start = startTime.value;
            updatedSegment.end = Math.max(endTime.value, startTime.value + 0.01);
        } else {
            if (endTime.value > props.mediaMaxDuration) {
                endTime.value = props.mediaMaxDuration;
            }
            updatedSegment.end = endTime.value;
            updatedSegment.start = Math.min(startTime.value, endTime.value - 0.01);
        }
        emit('update-segment', props.selectedSegmentIndex, updatedSegment);
    }
};

const removeSegment = () => {
    if (selectedSegment.value) {
        emit('remove-segment', props.selectedSegmentIndex);
    }
};

watch(startTime, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        updateSegment('start');
    }
});

watch(endTime, (newValue, oldValue) => {
    if (newValue !== oldValue) {
        updateSegment('end');
    }
});
</script>

<style scoped>
span {
    color: white;
}

.remove-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

button {
    display: flex;
    padding: 8px 9px;
    align-items: center;
    gap: 5px;
    font-size: rfs-value(14px);
    /*cte-light-dark*/
    background-color: #3a4452;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition:
        opacity 0.3s ease,
        background-color 0.3s ease;
    height: 34px;
}

button:hover {
    opacity: 0.8;
}

button:disabled {
    background-color: #2a3240;
    color: #ffffff;
    cursor: not-allowed;
    opacity: 0.6;
}

.time-code-input-container {
    display: flex;
    gap: 16px;
    align-items: center;
}

.time-media-container {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    justify-content: center;
    text-align: center;
}
.icon {
    width: 16px;
    height: 16px;
    color: white;
}
.segment-editor-container {
    display: flex;
    justify-content: center;
    height: 40px;
}

.segment-editor {
    display: flex;
    gap: 42px;
    margin-top: 10px;
    align-items: center;
}

.ui-content-row {
    width: 100%;
}

.slide-up-enter-active,
.slide-up-leave-active {
    transition: all 0.3s ease-out;
}

.slide-up-enter-from {
    opacity: 0;
    transform: translateY(20px);
}

.slide-up-leave-to {
    opacity: 0;
    transform: translateY(-20px);
}

.remove-item {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: white;
}
</style>
