<template>
    <div class="studio-stage" :class="stageClasses" :style="stageStyles" v-on="mouseListeners">
        <!-- <Teleport to="#timeline-visualiser">
            <TimeLineVisualiser
                v-if="showTimeLineVisualiser && _timeline"
                :timeline="_timeline"
                :currentTime="elapsedTime"
                @reloadTimeline="reloadTimeLineVisualiser"
            >
            </TimeLineVisualiser>
        </Teleport> -->
        <AssetAudio
            v-if="!audioIsFromLibrary && settings.music.src"
            ref="$mainMusic"
            name="$mainMusic"
            seqId="stage"
            :src="musicSrc"
            :volume="mainMusicVolume"
            :loop="0"
            :timerange="settings.music.timerange"
            @update="requestTimelineUpdate"
        />
        <AssetAudio
            v-else-if="audioIsFromLibrary && settings.music.library_src"
            ref="$libraryMusic"
            name="$libraryMusic"
            seqId="stage"
            :src="libraryMusicSrc"
            :volume="mainMusicVolume"
            :loop="0"
            :timerange="settings.music.timerange"
            @update="requestTimelineUpdate"
        />
        <AssetAudio
            v-if="settings.track.enabled && settings.track.src"
            ref="$mainTrack"
            name="$mainTrack"
            seqId="stage"
            :src="settings.track.src"
            :volume="mainTrackVolume"
            :captions="settings.track.captions"
            @update="requestTimelineUpdate"
        />
        <AssetAudio
            v-if="trackTTS"
            ref="$mainTTS"
            name="$mainTTS"
            seqId="stage"
            :src="trackTTS"
            :playback-rate="trackTTSPlaybackRate"
            :basisVolume="trackTTSVolume"
            :volume="ttsTrackVolume"
            :captions="trackTTSCaptions"
            :timerange="trackTTSTimerange"
            @update="requestTimelineUpdate"
        />
        <template v-for="(seq, index) in sequencesVisible" :key="seq.id">
            <component
                :is="getTransitionComponent(seq.id)"
                :id="seq.id"
                :ref="getPrefixedKey(seq.id, 'transition')"
                :detection="detection"
                :format="format"
                :current-sequences="currentSequences"
            />
            <background
                :id="seq.id"
                :ref="getPrefixedKey(seq.id, 'bg')"
                :format="format"
                :detection="detection"
                :current-sequences="currentSequences"
                :preview="preview"
                :capture-mode="captureMode"
            ></background>
            <sequence
                :id="seq.id"
                :ref="getPrefixedKey(seq.id, 'content')"
                :detection="detection"
                :current-sequences="currentSequences"
                :settings="settings"
                :preview="preview"
                :capture-mode="captureMode"
                :edit="edit"
                @click.self="startEditingSequence(seq.id)"
            ></sequence>
        </template>
        <cover />
        <logo-animation ref="$logo" />
        <borders :preview="preview" :capture-mode="captureMode" :current-label="currentLabel" />
        <captions ref="$captions" />
        <editing-frame v-if="editingElementActive && canResize" :can-resize="canResize"></editing-frame>
        <controls
            v-if="!warning.active && preview"
            :format="format"
            :preview-start="previewStart"
            :playing="playing"
            :muted="muted"
            :elapsed-time="elapsedTime"
            :sequences="sequences"
        />
        <warning :warning="warning" :preview="preview" />
        <loading-animation
            :detection="detection"
            :preview="preview"
            :playing="playing"
            :capture-mode="captureMode"
            :active="loading"
        />
    </div>
</template>

<script>
import {
    Color,
    Dimension,
    Duration,
    Production,
    Sound,
    Timeline,
    Transition,
    Visual,
    Background as BgConstants
} from '../constants';
import { STAGE_LOADING_ENDED_EVENT, STAGE_LOADING_STARTED_EVENT, STAGE_TIMELINE_UPDATED_EVENT } from '../utils';
import LoadingAnimation from './LoadingAnimation.vue';
import Warning from './Warning.vue';
import Controls from './Controls.vue';
import Captions from './Captions.vue';
import Borders from './Borders.vue';
import Cover from './Cover.vue';
import LogoAnimation from './LogoAnimation.vue';
import AssetAudio from './assets/AssetAudio.vue';
import Sequence from './Sequence.vue';
import Background from './Background.vue';
import Transitions from './transitions';
import EditingFrame from './EditingFrame.vue';
import TimeLineVisualiser from './TimeLineVisualiser.vue';

import gsap from 'gsap';
import _debounce from 'lodash/debounce';
import _throttle from 'lodash/throttle';
import { mapGetters, mapState } from 'vuex';
import { nextTick } from 'vue';

gsap.defaults({ overwrite: 'auto', ease: 'none' });
gsap.config({ nullTargetWarn: process.env.NODE_ENV == 'development' });

export default {
    components: {
        LoadingAnimation,
        Warning,
        Controls,
        Captions,
        Borders,
        Cover,
        LogoAnimation,
        AssetAudio,
        Sequence,
        Background,
        EditingFrame,
        TimeLineVisualiser,
        ...Transitions
    },

    provide() {
        return {
            $stage: this.$stage
        };
    },

    props: {
        autoplay: {
            type: Boolean,
            default: false
        },
        preview: {
            type: Boolean,
            default: false
        },
        captureMode: {
            type: [Boolean, String],
            default: false
        }
    },

    data() {
        return {
            firstPlay: true,
            willUpdateTimeline: false,
            mainMusicVolume: Sound.VOLUME_DEFAULT,
            mainTrackVolume: Sound.VOLUME_DEFAULT,
            ttsTrackVolume: Sound.VOLUME_DEFAULT,
            scale: 1,
            mouseActive: false,
            editing: false,
            showTimeLineVisualiser: true,
            gsapContext: null,
            sequencesDuration: new Map()
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.display.readOnly,
            edit: (state) => state.display.edit,
            editingElement: (state) => state.edition.editingElement,
            detection: (state) => state.detection,
            warning: (state) => state.display.warning,
            previewStart: (state) => state.display.previewStart,
            format: (state) => state.display.format,
            playing: (state) => state.display.timeline.playing,
            muted: (state) => state.display.timeline.muted,

            currentEditedItemId: (state) => state.ui.currentEditedItemId,
            currentLabel: (state) => state.display.timeline.currentLabel,
            fallbackLabel: (state) => state.display.timeline.fallbackLabel,
            currentSequences: (state) => state.display.timeline.currentSequences,
            sequenceElapsedTime: (state) => state.display.timeline.sequenceElapsedTime,
            elapsedTime: (state) => state.display.timeline.elapsedTime,
            brandingPalette: (state) => state.branding.palette,
            settings: (state) => state.settings,
            canResize: (state) => state.ui.permissions.canResize,
            avoidTimelineReflow: (state) => state.display.avoidTimelineReflow
        }),

        ...mapGetters({
            sequences: 'sequences/all',
            sequencesVisible: 'sequences/allVisible',
            loading: 'loading/active',
            mobile: 'detection/mobile',
            seeking: 'display/seeking',
            musicSrc: 'settings/musicSrc',
            libraryMusicSrc: 'settings/libraryMusicSrc'
        }),

        stageClasses() {
            return {
                'studio-chrome-mode': this.detection.browser.chrome,
                'studio-firefox-mode': this.detection.browser.firefox,
                'studio-edge-mode': this.detection.browser.edge,
                'studio-safari-mode': this.detection.browser.safari,
                'studio-mobile-mode': this.mobile,
                'studio-read-only': this.readOnly,
                'studio-mouse-active': this.mouseActive,
                'studio-editing': this.editing,
                'studio-loading': this.loading,
                'studio-playing': this.playing,
                'studio-paused': !this.playing,
                'studio-seeking': this.seeking,
                'studio-audio-capture': this.captureMode == Production.AUDIO_CAPTURE_MODE
            };
        },

        stageStyles() {
            let brandColorVars = Object.keys(this.brandingPalette).reduce((vars, color) => {
                if (this.brandingPalette[color] != Color.NONE) {
                    vars['--brand-palette-' + color] = this.brandingPalette[color];
                }
                return vars;
            }, {});

            return {
                ...brandColorVars,
                ...this.size
            };
        },

        size() {
            return {
                width: this.format.width + Dimension.PIXEL_UNIT,
                height: this.format.height + Dimension.PIXEL_UNIT
            };
        },

        mouseListeners() {
            return this.detection.browser.mobile
                ? {}
                : {
                      mousemove: this.setMouseActive,
                      mouseleave: this.setMouseInactive
                  };
        },

        hasLoadingProductionSequence() {
            return !this.preview && this.captureMode != Production.FRAME_CAPTURE_MODE;
        },

        editingElementActive() {
            return (
                this.edit &&
                this.editingElement?.state &&
                ((this.editingElement.seqId && this.currentSequences.indexOf(this.editingElement.seqId) !== -1) ||
                    (this.editingElement.id &&
                        !!this.editingElement.getAnimatedRef &&
                        this.currentSequences.indexOf(this.editingElement.id) !== -1))
            );
        },

        audioIsFromLibrary() {
            return this.settings.music.type !== 'my';
        },

        trackTTS() {
            return this.$store.getters['settings/tts/track'];
        },
        trackTTSStart() {
            return this.$store.getters['settings/tts/start'];
        },
        trackTTSVolume() {
            return this.$store.getters['settings/tts/volume'];
        },
        trackTTSPlaybackRate() {
            return this.$store.getters['settings/tts/playbackRate'];
        },
        trackTTSTimerange() {
            return this.$store.getters['settings/tts/timerange'];
        },
        trackTTSCaptions() {
            return this.$store.getters['settings/tts/captions'];
        }
    },

    watch: {
        currentEditedItemId(newValue, oldValue) {
            this._timeline.seek('start+=0.1', false);

            const time = this.getSequenceTimelineDefaultTime(newValue);

            if (this.loading) {
                // Attendre que le chargement soit terminé
                const unwatch = this.$watch('loading', (isLoading) => {
                    if (!isLoading) {
                        this.seekSequenceTimeline(newValue, time);
                        unwatch(); // Arrêter de surveiller loading
                    }
                });
            } else {
                this.seekSequenceTimeline(newValue, time);
            }
        },
        format(newValue, oldValue) {
            if (newValue.width != oldValue.width || newValue.height != oldValue.height) {
                window.dispatchEvent(new Event('resize'));
                const currentTime = this._timeline.time();

                setTimeout(() => {
                    this._timeline.seek('start+=0.1', false);

                    nextTick(() => {
                        this._timeline.seek(currentTime, false);
                    });
                }, 1000); // 1 second delay to ensure that frame fit the new format, this is a workaround for the timeline reflow, it's not a good solution
            }
        },

        sequencesVisible: {
            deep: true,
            handler(newValue, oldValue) {
                if (!newValue.length || (this.previewStart != -1 && this.sequences[this.previewStart].options.hidden))
                    this.$store.commit('display/addWarning', 'no-sequences');
                else this.$store.commit('display/removeWarning', 'no-sequences');
            }
        },

        loading(newValue, oldValue) {
            if (!newValue && !this.warning.active) {
                if (this.firstPlay) {
                    this.firstPlay = false;
                    if (this.autoplay) this.playTimeline();
                    else this._timeline.seek(this.currentLabel + '+=0.01');
                    if (!this.preview) {
                        if (this.captureMode == Production.FRAME_CAPTURE_MODE)
                            this.dispatchCaptureEvent('startFrameCapture');
                        else this.dispatchCaptureEvent('startCapture');
                    }
                }
                this.$el.dispatchEvent(new Event(STAGE_LOADING_ENDED_EVENT));
            } else if (!!newValue) {
                this.$el.dispatchEvent(new Event(STAGE_LOADING_STARTED_EVENT));
            }
        },

        playing(newValue, oldValue) {
            this.toggleEdit(!newValue);
            if (newValue && !this.seeking && !this.warning.active) {
                if (this.currentLabel == 'end') {
                    if (this.previewStart == -1) this._timeline.restart();
                    else this._timeline.play(this.sequences[this.previewStart].id);
                    this.updateTimelineData();
                } else this._timeline.play();
            } else this._timeline.pause();
        },

        seeking(newValue, oldValue) {
            if (newValue) this._timeline.pause();
            else if (this.playing && !this.warning.active) {
                if (this.currentLabel == 'end') {
                    if (this.previewStart == -1) this._timeline.restart();
                    else this._timeline.play(this.sequences[this.previewStart].id);
                    this.updateTimelineData();
                } else this._timeline.play();
            }
        },

        trackTTSStart() {
            this.requestTimelineUpdate();
        },

        trackTTSTimerange() {
            this.requestTimelineUpdate();
        },

        trackTTSPlaybackRate() {
            this.requestTimelineUpdate();
        }
    },

    methods: {
        reloadTimeLineVisualiser() {
            this.showTimeLineVisualiser = false;
            this.$nextTick(() => {
                this.showTimeLineVisualiser = true;
            });
        },
        toggleEdit(value) {
            this.$store.commit('display/setEdit', !this.readOnly && value);
            if (!value && this.editingElement) this.unsetEditingElement();
        },

        unsetEditingElement() {
            this.editingElement?.setAnimationStyles?.();
            this.$store.commit('edition/setEditingElement', null);
        },

        getPrefixedKey(prefix, key) {
            return prefix + '-' + key;
        },

        getSequenceElement(id) {
            let component = this.$refs[this.getPrefixedKey(id, 'content')];
            return component && component[0];
        },

        getBackgroundElement(id) {
            let component = this.$refs[this.getPrefixedKey(id, 'bg')];
            return component && component[0];
        },

        startEditingSequence(id) {
            const backgroundState = this.$store.state.sequences[id].background;

            let editedElement = this.getSequenceElement(id);

            // if background is image or video, set it as editing element
            if (
                ([BgConstants.IMAGE_TYPE, BgConstants.CARTOON_TYPE].includes(backgroundState.type) &&
                    backgroundState.image.src) ||
                ([BgConstants.VIDEO_TYPE, BgConstants.ANIMATED_TYPE, BgConstants.RECORDING_TYPE].includes(
                    backgroundState.type
                ) &&
                    backgroundState.video.src)
            ) {
                editedElement = this.getBackgroundElement(id);
                if (editedElement.state.cover) editedElement.enableEditing();
                editedElement.removeAnimationStyles();
            }

            this.$nextTick(() => this.$store.commit('edition/setEditingElement', editedElement));
        },

        getTransitionComponent(id) {
            return Transition.PREFIX_CLASS + this.$store.state.sequences[id].transition.type;
        },

        getTransitionElement(id) {
            let component = this.$refs[this.getPrefixedKey(id, 'transition')];
            return component && component[0];
        },

        requestTimelineUpdate(showLoader = true) {
            if (this.avoidTimelineReflow) return;

            if (!this.willUpdateTimeline) {
                if (showLoader) this.$store.commit('loading/prepare', this);
                this.willUpdateTimeline = true;
            }
            //clearTimeout(this._updateTimelineTimeoutID);
            //this._updateTimelineTimeoutID = setTimeout(() => {
            //this.$nextTick(() => {
            //this.willUpdateTimeline = false;
            //this.updateTimeline();
            //this.$nextTick(() => {
            //if(this.editingElement && this.editingElement.setFinalState) this.editingElement.setFinalState();
            //});
            //this.$store.commit('loading/prepared', this);
            //});
            //}, 0);

            // debounce timeline update until first loading has occurred, then throttle next executions
            if (this.firstPlay) this._debouncedProcessTimelineUpdate();
            else this._throttledProcessTimelineUpdate();
        },

        processTimelineUpdate() {
            this.$nextTick(() => {
                this.willUpdateTimeline = false;
                this.updateTimeline();
                this.$nextTick(() => {
                    if (this.editingElement && this.editingElement.setFinalState) this.editingElement.setFinalState();
                });
                this.$store.commit('loading/prepared', this);
            });
        },

        findTimelineById(timeline, id) {
            if (!timeline) return null;

            // Cherche d'abord directement dans la timeline
            if (timeline.getById) {
                const direct = timeline.getById(id);
                if (direct) return direct;
            }

            // Cherche dans les enfants
            const children = timeline.getChildren?.() || [];
            for (const child of children) {
                // Vérifie l'ID du child
                if (child.vars?.id === id) return child;

                // Vérifie les targets du child
                const targetTimeline = child.targets?.()[0];
                if (targetTimeline?.vars?.id === id) return targetTimeline;

                // Recherche récursive dans les enfants
                const found = this.findTimelineById(child, id);
                if (found) return found;
            }

            return null;
        },

        async updateTimeline() {
            const previousTimelines = new Map();
            if (this._timeline) {
                this.$refs?.$logo?.releaseTimeline();

                this.sequencesVisible.forEach((seqState) => {
                    this.getTransitionElement(seqState.id)?.releaseTimeline();

                    const seqTimeline = this._timeline.getById(seqState.id);

                    const sequenceTimeline = seqTimeline?.targets?.()[0] || seqTimeline;
                    const bgTimeline = this.findTimelineById(sequenceTimeline, 'background_' + seqState.id);

                    if (seqTimeline) {
                        const originalSeqTimeline = seqTimeline.timeline || seqTimeline;
                        previousTimelines.set(seqState.id, {
                            timeline: originalSeqTimeline
                        });
                    }
                    if (bgTimeline) {
                        const originalBgTimeline = bgTimeline.timeline || bgTimeline;
                        previousTimelines.set(`background_${seqState.id}`, {
                            timeline: originalBgTimeline
                        });
                    }
                });
            }

            let t,
                transition,
                background,
                sequence,
                startTime,
                endTime,
                d = 0,
                backgroundGroups = [],
                timelines = [];

            const currentTime = this._timeline?.time() || 0;

            if (this.firstPlay) {
                this._timeline = gsap.timeline({ paused: !this.playing });
            } else {
                this._timeline.clear();
                this._timeline.seek(0.01);
            }

            if (this.sequencesVisible.length) {
                this.sequencesVisible.forEach((seqState) => {
                    t = gsap.timeline({ paused: true });

                    if (seqState.id !== this.currentEditedItemId && !this.firstPlay) {
                        const existingTimeline = previousTimelines.get(seqState.id);
                        if (existingTimeline && existingTimeline.timeline.vars) {
                            sequence = existingTimeline.timeline;
                            sequence.vars = { ...existingTimeline.timeline.vars, id: `sequence_${seqState.id}` };

                            this.checkAndRestoreReferences(sequence, seqState);

                            if (existingTimeline.timeline.labels) {
                                Object.assign(sequence.labels, existingTimeline.timeline.labels);
                            }
                        }

                        const existingBackground = previousTimelines.get(`background_${seqState.id}`);
                        if (existingBackground && existingBackground.timeline.vars) {
                            background = existingBackground.timeline;
                            background.vars = { ...existingBackground.timeline.vars, id: `background_${seqState.id}` };

                            const targets = existingBackground.timeline.targets?.() || [];
                            targets.forEach((target, index) => {
                                if (target && target.element) {
                                    background.targets()[index] = target.element;
                                }
                            });
                        }

                        transition = seqState.transition.timeline();

                        t.add(transition, 0);
                        t.add(background.play(), 0);
                        t.add(sequence, 0);
                        startTime = 0;
                        //endTime = this.getSequenceTimelineEndTimeForTweens(t, seqState);
                        endTime = this.sequencesDuration.get(seqState.id);
                    } else {
                        transition = seqState.transition.timeline();
                        background = seqState.background.timeline();
                        background.vars = { ...background.vars, id: `background_${seqState.id}` };
                        sequence = seqState.timeline(d);
                        sequence.vars = { ...sequence.vars, id: `sequence_${seqState.id}` };

                        t.add(transition, 0);
                        t.add(background.play(), 0);
                        t.add(sequence, 0);
                        startTime = 0;
                        endTime = this.getSequenceTimelineEndTime(t, seqState);
                        this.sequencesDuration.set(seqState.id, endTime);
                    }

                    let hasPreviousBackground =
                            this.$store.getters['sequences/' + seqState.id + '/hasPreviousBackground'],
                        backgroundTimeOffset = 0;

                    if (!hasPreviousBackground || this.$store.getters['sequences/' + seqState.id + '/isFirstVisible'])
                        backgroundGroups.push([]);

                    if (hasPreviousBackground) {
                        let previousWithBackground =
                                this.$store.getters['sequences/' + seqState.id + '/previousWithBackground'],
                            previousHasVideoBackground =
                                !previousWithBackground ||
                                this.$store.getters['sequences/' + previousWithBackground.id + '/hasVideoBackground'],
                            previousHasAnimatedBackground =
                                !previousWithBackground ||
                                this.$store.getters[
                                    'sequences/' + previousWithBackground.id + '/hasAnimatedBackground'
                                ],
                            previousHasMapZoomBackground =
                                !previousWithBackground ||
                                this.$store.getters['sequences/' + previousWithBackground.id + '/hasMapZoomBackground'],
                            previousHasBackgroundInnerAnimation =
                                !previousWithBackground ||
                                ((this.$store.getters[
                                    'sequences/' + previousWithBackground.id + '/hasImageBackground'
                                ] ||
                                    previousHasVideoBackground ||
                                    previousHasAnimatedBackground) &&
                                    previousWithBackground.background.animation.type != Background.ANIMATION_NONE),
                            nextState = this.$store.getters['sequences/' + seqState.id + '/nextVisible'],
                            nextHasPreviousBackground =
                                nextState &&
                                this.$store.getters['sequences/' + nextState.id + '/hasPreviousBackground'];

                        if (
                            previousHasVideoBackground ||
                            previousHasAnimatedBackground ||
                            previousHasMapZoomBackground ||
                            previousHasBackgroundInnerAnimation
                        ) {
                            backgroundTimeOffset = backgroundGroups[backgroundGroups.length - 1].reduce(
                                (total, current) => total + current.duration,
                                0
                            );
                            transition.startTime(backgroundTimeOffset);
                            sequence.startTime(backgroundTimeOffset);
                            let pbt = background.getById(Timeline.PANEL_BG_TIMELINE_ID);
                            if (pbt) pbt.startTime(pbt.startTime() + backgroundTimeOffset);
                        }

                        startTime += backgroundTimeOffset;
                        endTime =
                            seqState.options.duration ||
                            nextHasPreviousBackground ||
                            !(previousHasVideoBackground || previousHasAnimatedBackground)
                                ? endTime + backgroundTimeOffset
                                : background.endTime();

                        if (endTime <= startTime) endTime = startTime + Duration.READING_DEFAULT; // most likely happen when background video has ended on previous sequence
                    }

                    t.addLabel('start', startTime);
                    t.addLabel('end', endTime);
                    backgroundGroups[backgroundGroups.length - 1].push({
                        index: timelines.length,
                        duration: endTime - startTime
                    });

                    timelines.push({
                        timeline: t,
                        id: seqState.id,
                        backgroundGroupIndex: backgroundGroups.length - 1,
                        backgroundIndex: backgroundGroups[backgroundGroups.length - 1].length - 1,
                        start: d,
                        transition: transition.totalDuration(),
                        mainMusicVolume: this.$store.getters['sequences/' + seqState.id + '/mainMusicVolume'],
                        mainTrackVolume: this.$store.getters['sequences/' + seqState.id + '/mainTrackVolume'],
                        mainVolumeFade: this.$store.getters['sequences/' + seqState.id + '/mainVolumeFade'],
                        volumeFadeIn: this.$store.getters['sequences/' + seqState.id + '/video/volumeFade'],
                        volumeFadeOut: this.$store.getters['sequences/' + seqState.id + '/video/volumeFade']
                    });
                    d += endTime - startTime;

                    if (backgroundGroups[backgroundGroups.length - 1].length > 1) {
                        timelines[timelines.length - 1].volumeFadeIn = 0;
                        timelines[timelines.length - 1].volumeFadeOut = timelines[timelines.length - 2].volumeFadeOut;
                        timelines[timelines.length - 2].volumeFadeOut = 0;
                    }
                });
            }

            if (timelines.length) {
                timelines.forEach((data, index) => {
                    t = data.timeline;

                    // Calculer la durée de transition pour la séquence suivante
                    let nextTransitionDuration =
                        index + 1 < timelines.length
                            ? Math.min(timelines[index + 1].transition, timelines[index + 1].timeline.labels['end'])
                            : 0;

                    // Ajouter le label de fin de transition en respectant la durée de la séquence
                    const sequenceDuration = t.labels['end'] - t.labels['start'];
                    t.addLabel('end_transition', 'start+=' + (sequenceDuration + nextTransitionDuration));

                    // Ajouter la timeline à la timeline principale avec la durée correcte
                    this._timeline.addLabel(data.id, data.start);
                    this._timeline.add(
                        t.tweenFromTo('start', 'end_transition', {
                            id: data.id,
                            immediateRender: true,
                            duration: sequenceDuration + nextTransitionDuration
                        }),
                        data.start
                    );

                    // Background inner animation
                    let bt = t.getById(`background_${data.id}`);
                    if (bt) {
                        let ibt = bt.getById(Timeline.INNER_BACKGROUND_TIMELINE_ID);
                        if (ibt) {
                            let backgroundDuration = t.labels['end_transition'],
                                backgroundGroupTimes = backgroundGroups[data.backgroundGroupIndex];
                            if (backgroundGroupTimes.length > 1) {
                                backgroundDuration = backgroundGroupTimes.reduce(
                                    (total, current) => total + current.duration,
                                    0
                                );
                                if (data.backgroundGroupIndex + 1 < backgroundGroups.length)
                                    backgroundDuration +=
                                        timelines[backgroundGroups[data.backgroundGroupIndex + 1][0].index].transition;
                            }
                            ibt.timeScale(1 / backgroundDuration);
                            bt.to({}, {}, 0).remove(bt.recent());
                        }
                    }

                    // Elements based on sequence duration
                    let seqt = t.getById(`sequence_${data.id}`);
                    //check if seqt is Tween or Timeline
                    if (seqt && seqt.getChildren) {
                        let seqelt = seqt.getChildren(false, false);
                        seqelt.forEach((elt) => {
                            if (
                                elt.data &&
                                elt.data.hasSequenceDuration &&
                                [Timeline.VISUAL_TIMELINE_ID, Timeline.MESSAGE_TIMELINE_ID].indexOf(elt.vars.id) !=
                                    -1 &&
                                elt.startTime() < t.labels['end']
                            )
                                elt.timeScale(elt.totalDuration() / (t.labels['end'] - elt.startTime()));
                        });
                        seqt.to({}, {}, 0).remove(seqt.recent()); // Workaround for GSAP issue https://github.com/greensock/GSAP/issues/488
                    }
                    // Footer animation
                    let ft = t.getById(Timeline.FOOTER_TIMELINE_ID);
                    if (ft && ft.startTime() > t.labels['end']) {
                        ft.startTime(Math.max(t.labels['start'], t.labels['end'] - Duration.FOOTER_READING_DEFAULT));
                    }

                    // Sequence sounds
                    if (data.volumeFadeIn) {
                        let sequenceFadeIn = Math.min(
                            data.volumeFadeIn,
                            t.labels['end_transition'] - t.labels['start']
                        );
                        t.fromTo(
                            [this.getSequenceElement(data.id), this.getBackgroundElement(data.id)],
                            { volume: 0 },
                            { duration: sequenceFadeIn, volume: 1 },
                            'start'
                        );
                    }
                    if (data.volumeFadeOut) {
                        let sequenceFadeOut = Math.min(
                            data.volumeFadeOut,
                            t.labels['end_transition'] - t.labels['start']
                        );
                        t.fromTo(
                            [this.getSequenceElement(data.id), this.getBackgroundElement(data.id)],
                            { volume: 1 },
                            { duration: sequenceFadeOut, volume: 0 },
                            'end_transition-=' + sequenceFadeOut
                        );
                    }

                    // Stage sounds
                    if (!this.hasLoadingProductionSequence || index > 0) {
                        let isFirstSequence = index == 0 || (this.hasLoadingProductionSequence && index == 1),
                            mainFade = Math.min(
                                isFirstSequence ? Sound.FADE_NONE : data.mainVolumeFade,
                                t.labels['end'] - t.labels['start'] - 0.0001
                            );
                        this._timeline.to(
                            this,
                            {
                                duration: mainFade,
                                mainMusicVolume: data.mainMusicVolume,
                                mainTrackVolume: data.mainTrackVolume
                            },
                            data.id
                        );
                        if (index == timelines.length - 1 && this.settings.fade.end.enabled) {
                            let mainEndFade = Math.min(
                                Sound.END_FADE_DEFAULT,
                                t.labels['end_transition'] - t.labels['start']
                            );
                            this._timeline.to(
                                this,
                                { duration: mainEndFade, mainMusicVolume: 0, mainTrackVolume: 0 },
                                '-=' + mainEndFade
                            );
                        }
                    }
                });
            }

            let timelineMusicDuration = !this.hasLoadingProductionSequence
                    ? this._timeline.totalDuration()
                    : this._timeline.totalDuration() - Production.LOADING_SEQUENCE_DURATION,
                timelineTTSDuration = timelineMusicDuration,
                timelineMusicStart = !this.hasLoadingProductionSequence ? 0 : Production.LOADING_SEQUENCE_DURATION;

            // handle music starting time in timeline (set to 0 if starting time greater than timeline duration)
            const musicStartTime =
                this.settings.music.start >= timelineMusicDuration ? 0 : Number(this.settings.music.start) || 0;

            timelineMusicDuration =
                this.settings.music.start >= timelineMusicDuration
                    ? 0
                    : timelineMusicDuration - this.settings.music.start;

            timelineTTSDuration =
                this.trackTTSStart >= timelineTTSDuration ? 0 : timelineTTSDuration - this.trackTTSStart;

            const musicTimelinePosition = timelineMusicStart + musicStartTime;

            if (this.$refs.$mainMusic) {
                this._timeline.add(
                    this.$refs.$mainMusic.timeline().tweenFromTo(0, timelineMusicDuration),
                    musicTimelinePosition
                );
                this.$store.commit('settings/setMusicTotalDuration', this.$refs.$mainMusic.totalDuration);
            } else if (this.$refs.$libraryMusic) {
                this._timeline.add(
                    this.$refs.$libraryMusic.timeline().tweenFromTo(0, timelineMusicDuration),
                    musicTimelinePosition
                );
                this.$store.commit('settings/setMusicTotalDuration', this.$refs.$libraryMusic.totalDuration);
            }

            if (this.$refs.$mainTrack)
                this._timeline.add(
                    this.$refs.$mainTrack.timeline().tweenFromTo(0, timelineMusicDuration),
                    timelineMusicStart
                );

            // TTS (Voice Over)
            if (this.$refs.$mainTTS) {
                this._timeline.add(
                    this.$refs.$mainTTS.timeline().tweenFromTo(0, timelineTTSDuration),
                    timelineMusicStart + this.trackTTSStart
                );
                this.$store.dispatch('settings/tts/setTotalDuration', this.$refs.$mainTTS.totalDuration);
            }

            this._timeline.addLabel('start', 0);
            this._timeline.addLabel('end');
            this._timeline.call(this.endTimeline, null, 'end');

            if (currentTime > 0) {
                this._timeline.seek(currentTime + 0.001);
            }
            this.setTimelinePlayhead();

            this._timeline.eventCallback('onUpdate', this.updateTimelineData);
            this.updateTimelineLabels();
            this.updateTimelineData();
            this.$el.dispatchEvent(new Event(STAGE_TIMELINE_UPDATED_EVENT));
        },

        releaseTimeline() {
            this._timeline.clear();
            this._timeline.kill();
            this.firstPlay = true;

            this._timeline = gsap.timeline({ paused: true }); // Créer une nouvelle timeline vide

            this.$refs?.$logo?.releaseTimeline();

            if (this.sequencesVisible.length) {
                this.sequencesVisible.forEach((seqState) => {
                    this.getTransitionElement(seqState.id)?.releaseTimeline();
                });
            }
        },

        setTimelinePlayhead() {
            let labels = this._timeline.labels;
            if (labels.hasOwnProperty(this.currentLabel)) {
                let nextLabel = this._timeline.nextLabel(this._timeline.labels[this.currentLabel]);
                this._timeline.seek(
                    this.sequenceElapsedTime <
                        this._timeline.labels[nextLabel] - this._timeline.labels[this.currentLabel]
                        ? this.currentLabel + '+=' + this.sequenceElapsedTime
                        : this.currentLabel + '+=' + this.getSequenceTimelineDefaultTime(this.currentLabel)
                );
            } else {
                this._timeline.seek(
                    this.fallbackLabel + '+=' + this.getSequenceTimelineDefaultTime(this.fallbackLabel)
                );
            }
        },

        getSequenceTimelineDefaultTime(seqId) {
            let seqState = this.sequencesVisible.find((seq) => seq.id == seqId);
            if (!seqState) return 0;

            let timeline = this._timeline.getById(seqId);
            if (!timeline) return 0;

            // Gérer le cas où timeline est un Tween
            let sequenceTimeline;
            if (timeline.targets && typeof timeline.targets === 'function') {
                sequenceTimeline = timeline.targets()[0];
            } else {
                sequenceTimeline = timeline;
            }

            // Si la séquence est un Tween, retourner une valeur par défaut
            if (!sequenceTimeline.getById) {
                return sequenceTimeline.totalDuration() / 2;
            }

            let transitionTimeline = sequenceTimeline.getById(Timeline.TRANSITION_TIMELINE_ID);
            let transitionTime = transitionTimeline ? transitionTimeline.totalDuration() : 0;
            let time = Duration.INFINITE_DURATION;

            // Modification du calcul du temps pour les éléments de séquence
            time = seqState.order.reduce((start, elementId) => {
                try {
                    let endTime = this.getSequenceElementTimelineDefaultTime(seqId, elementId);
                    return endTime < sequenceTimeline.labels['end'] && endTime < start ? endTime : start;
                } catch (e) {
                    console.warn('Error calculating element time:', e);
                    return start;
                }
            }, time);

            if (transitionTime <= time && time < Duration.INFINITE_DURATION) return time;
            if (transitionTime < sequenceTimeline.labels['end']) return transitionTime;

            return (sequenceTimeline.labels['end'] - sequenceTimeline.labels['start']) / 2;
        },

        getSequenceElementTimelineDefaultTime(seqId, elementId) {
            let timeline = this._timeline.getById(seqId);
            if (!timeline) return 0;

            // Récupérer la timeline de séquence, qui peut être soit directement la timeline soit à l'intérieur d'un Tween
            let sequenceTimeline;
            if (timeline.targets && typeof timeline.targets === 'function') {
                sequenceTimeline = timeline.targets()[0];
            } else {
                sequenceTimeline = timeline;
            }

            // Récupérer la timeline de contenu
            let contentTimeline;
            if (sequenceTimeline.getById) {
                contentTimeline = sequenceTimeline.getById(`sequence_${seqId}`);
            } else if (sequenceTimeline.vars && sequenceTimeline.vars.id === `sequence_${seqId}`) {
                contentTimeline = sequenceTimeline;
            } else {
                return 0;
            }

            // Si contentTimeline est un Tween, on ne peut pas aller plus loin dans la hiérarchie
            if (!contentTimeline.getById) {
                return contentTimeline.totalDuration() / 2; // Valeur par défaut
            }

            // Pour les timelines normales, continuer comme avant
            const elementTimelineId = RegExp('^' + Visual.PREFIX_ID).test(elementId)
                ? Timeline.VISUAL_TIMELINE_ID
                : Timeline.MESSAGE_TIMELINE_ID;
            const elementTimeline = contentTimeline.getById(elementTimelineId);

            if (!elementTimeline) return 0;

            let elementComponent = this.getSequenceElement(seqId).getElement(elementId);
            let elementEndTime =
                elementComponent.editingData.finalStatePosition ??
                (elementTimeline.labels['reading_time']
                    ? elementTimeline.startTime() + elementTimeline.labels['reading_time']
                    : elementTimeline.endTime());

            return Math.min(
                elementEndTime,
                contentTimeline.labels[elementId + '_end'] || Duration.INFINITE_DURATION,
                sequenceTimeline.labels['end']
            );
        },

        getSequenceTimelineEndTime(timeline, state) {
            if (state.options.duration) return state.options.duration;

            let hasVideoBackground = this.$store.getters['sequences/' + state.id + '/hasVideoBackground'],
                hasAnimatedBackground = this.$store.getters['sequences/' + state.id + '/hasAnimatedBackground'],
                hasPreviousBackground = this.$store.getters['sequences/' + state.id + '/hasPreviousBackground'],
                nextState = this.$store.getters['sequences/' + state.id + '/nextVisible'],
                nextHasPreviousBackground =
                    nextState && this.$store.getters['sequences/' + nextState.id + '/hasPreviousBackground'];

            let children = [
                    ...timeline.getChildren(false),
                    ...timeline.getById(`sequence_${state.id}`).getChildren(false)
                ],
                excludedTimelines = [
                    `sequence_${state.id}`,
                    Timeline.TRANSITION_TIMELINE_ID,
                    Timeline.FOOTER_TIMELINE_ID,
                    Timeline.TTS_TIMELINE_ID
                ],
                maxEndTime = 0;
            if (hasPreviousBackground || nextHasPreviousBackground) excludedTimelines.push(`background_${state.id}`);

            children.forEach((child) => {
                if (
                    excludedTimelines.indexOf(child.vars.id) == -1 &&
                    child.totalDuration() < Duration.INFINITE_DURATION
                ) {
                    if (
                        [Timeline.VISUAL_TIMELINE_ID, Timeline.MESSAGE_TIMELINE_ID].indexOf(child.vars.id) == -1 ||
                        !child.data ||
                        !child.data.hasOutTimeline
                    )
                        maxEndTime = Math.max(maxEndTime, child.endTime());
                }
            });

            return Math.max(Duration.READING_DEFAULT, maxEndTime);
        },

        updateTimelineLabels() {
            let labels = this._timeline.labels,
                sequencesLabels = {};

            this.sequencesVisible.forEach((seqState) => {
                if (seqState.id) {
                    const sequenceTimeline = this._timeline.getById(seqState.id);
                    if (sequenceTimeline) {
                        const targetTimeline = sequenceTimeline.targets?.()[0];
                        if (targetTimeline) {
                            const contentTimeline = targetTimeline.getById?.(`sequence_${seqState.id}`);
                            if (contentTimeline?.labels) {
                                sequencesLabels[seqState.id] = contentTimeline.labels;
                            }
                        }
                    }
                }
            });

            this.$store.dispatch('display/updateTimelineLabels', { labels, sequencesLabels });
        },

        updateTimelineData() {
            let currentLabel = this._timeline.currentLabel() || 'start',
                currentSequences = [],
                currentTransition = false,
                sequenceElapsedTime = 0,
                sequenceTotalTime = 0,
                sequences = this._timeline.getChildren(false, true, true);

            sequences.forEach((tl) => {
                if (
                    tl.vars.id &&
                    tl.startTime() <= this._timeline.totalTime() &&
                    this._timeline.totalTime() <= tl.endTime()
                ) {
                    currentSequences.push(tl.vars.id);
                    currentTransition = this.getTransitionElement(tl.vars.id)?.active ? tl.vars.id : false;
                    if (tl.vars.id == this._timeline.currentLabel()) {
                        sequenceElapsedTime = tl.totalTime();
                        sequenceTotalTime = tl.targets()[0].labels.end - tl.targets()[0].labels.start;
                    }
                }
            });

            this.$store.dispatch('display/updateTimeline', {
                currentSequences,
                currentTransition,
                currentLabel,
                fallbackLabel:
                    this._timeline.previousLabel(currentLabel) || this._timeline.nextLabel(currentLabel) || 'start',
                sequenceElapsedTime,
                sequenceTotalTime,
                elapsedTime: this._timeline.totalTime(),
                totalTime: this._timeline.totalDuration()
            });
        },

        getTimeline() {
            return this._timeline;
        },

        playTimeline() {
            this.$store.commit('display/setPlaying', true);
        },
        pauseTimeline() {
            this.$store.commit('display/setPlaying', false);
        },
        toggleTimeline() {
            if (!this.loading) {
                if (!this.playing) this.playTimeline();
                else this.pauseTimeline();
            }
        },

        previousSequenceTimeline() {
            let cl = this.currentLabel,
                pl = this.previewStart == -1 ? this._timeline.previousLabel(cl) : this.sequences[this.previewStart].id,
                ct = this.elapsedTime;

            if (ct > this._timeline.labels[cl] + (!this.playing ? 0.01 : 0.5))
                this._timeline.seek(cl + '+=0.01', false);
            else this._timeline.seek(!pl ? 0.01 : pl + '+=0.01', false);
        },
        nextSequenceTimeline() {
            let cl = this.currentLabel,
                nl = this._timeline.nextLabel(cl);

            this._timeline.seek(!nl ? 'end' : nl + '+=0.01', false);
        },
        isCurrentSequenceTimeline(seqId) {
            return this.currentSequences.indexOf(seqId) != -1 || seqId == 'stage';
        },

        seekSequenceTimeline(seqId, time, force) {
            if ((!this.loading || force) && !!this.sequencesVisible.find((seq) => seq.id == seqId)) {
                time = time != undefined ? time : this.getSequenceTimelineDefaultTime(seqId);
                this._timeline.seek(seqId + '+=' + time, false);
                this.$nextTick(() => {
                    if (this.editingElement?.setFinalState) this.editingElement.setFinalState();
                });
            }
        },

        seekSequenceElementTimeline(seqId, elementId, force) {
            if (!this.loading || force) {
                let seqState = this.sequencesVisible.find((seq) => seq.id == seqId);
                if (!!seqState && !!seqState[elementId]) {
                    let time = this.getSequenceElementTimelineDefaultTime(seqId, elementId);
                    this._timeline.seek(seqId + '+=' + time, false);
                }
            }
        },

        muteTimeline() {
            this.$store.commit('display/setMuted', true);
        },
        unmuteTimeline() {
            this.$store.commit('display/setMuted', false);
        },
        toggleMuteTimeline() {
            if (!this.loading) {
                if (!this.muted) this.muteTimeline();
                else this.unmuteTimeline();
            }
        },

        seekTimeline(time) {
            if (!this.loading) {
                this._timeline.seek(Math.max(0.01, time), false);
            }
        },
        progressTimeline(progress) {
            if (!this.loading) {
                this._timeline.progress(progress, false);
            }
        },

        endTimeline() {
            if (!this.preview && this.captureMode != Production.FRAME_CAPTURE_MODE) {
                this.dispatchCaptureEvent('stopCapture');
            } else if (this.preview) this.pauseTimeline();
        },

        updateActiveCaptions(captions, sourceID) {
            if (this.$refs.$captions) this.$refs.$captions.updateActiveCaptions(captions, sourceID);
        },

        setMouseActive() {
            clearTimeout(this._mouseTimeoutID);
            this.mouseActive = true;
            this._mouseTimeoutID = setTimeout(this.setMouseInactive, Duration.CONTROLS_HIDE_DELAY);
        },

        setMouseInactive() {
            clearTimeout(this._mouseTimeoutID);
            this.mouseActive = false;
        },

        captureFrame(time) {
            if (this._timeline.currentLabel() != 'end') {
                this._timeline.seek(Math.floor(time * 10000) / 10000 + 1 / 10000, false);
                gsap.delayedCall(0.1, this.dispatchCaptureEvent, ['startFrameCapture']);
            } else {
                this._timeline.seek(0.01);
                this.$store.commit('display/setCaptureMode', Production.AUDIO_CAPTURE_MODE);
                this.dispatchCaptureEvent('stopFrameCapture');
            }
        },

        dispatchCaptureEvent(type) {
            if (!this.preview) {
                window.top.postMessage(type, Production.ORIGIN_URL);
            }
        },

        clickOutside(e) {
            const path = e.composedPath();
            if (
                !path.includes(this.$stage.$el) &&
                e.target.closest('.ui-card') === null &&
                !path.find((el) => el.classList?.contains('studio-editing-frame-actions'))
            ) {
                this.unsetEditingElement();
            }
        },

        checkAndRestoreReferences(timeline, seqState) {
            if (!timeline || !timeline.getChildren) return;

            const children = timeline.getChildren();
            children.forEach((child) => {
                if (child.targets && typeof child.targets === 'function') {
                    const targets = child.targets();
                    targets.forEach((target, index) => {
                        if (!target || !target.element) {
                            // Tenter de retrouver l'élément par son ID
                            const elementId = child.vars?.targetId;
                            if (elementId) {
                                const element = this.getSequenceElement(seqState.id)?.querySelector(`#${elementId}`);
                                if (element) {
                                    child.targets()[index] = element;
                                }
                            }
                        }
                    });
                }
            });
        }
    },

    beforeCreate() {
        this.$stage = this;
        this._timeline = gsap.timeline({ paused: true });
        //this._updateTimelineTimeoutID = -1;
        this._mouseTimeoutID = -1;
    },

    created() {
        this._debouncedProcessTimelineUpdate = _debounce(this.processTimelineUpdate, 1000);
        this._throttledProcessTimelineUpdate = _throttle(this.processTimelineUpdate, 300);
    },

    mounted() {
        this.$store.commit('loading/prepare', this);
        this.updateTimeline();
        // Subscribe to store mutations that affect the timeline, Todo : remove this when we have a better way to handle timeline updates maybe not subscribing to all mutations
        this.$store.subscribe((mutation) => {
            const timelineUpdateMutations = [
                'sequences/remove',
                'sequences/move',
                '^sequences/[^/]+/setBackgroundTimeline$',
                '^sequences/[^/]+/setTimeline$',
                '^sequences/[^/]+/setTransitionTimeline$',
                '^sequences/[^/]+/setDuration$',
                '^sequences/[^/]+/setMainVolume$',
                '^sequences/[^/]+/setMainVolumeFade$',
                '^sequences/[^/]+/setBackgroundVolumeFade$',
                '^sequences/[^/]+/hide$',
                'settings/setMusicStart',
                'settings/setLogoTimeline',
                'settings/setLogoTimelineOut',
                'settings/enabledLogoRefresh',
                'settings/enableLogo',
                'settings/overrideLogo',
                'settings/setLogo',
                'settings/setLogoAnimation'
            ];

            const isTimelineUpdate = timelineUpdateMutations.some((pattern) => {
                return new RegExp(pattern).test(mutation.type);
            });

            if (!isTimelineUpdate) return;

            const releaseTimelineMutations = [
                'sequences/move',
                'settings/setLogoTimeline',
                'settings/setLogoTimelineOut',
                'settings/enabledLogoRefresh',
                'settings/enableLogo',
                'settings/overrideLogo',
                'settings/setLogo',
                'settings/setLogoAnimation',
                '^sequences/[^/]+/hide$'
            ];

            const needsRelease = releaseTimelineMutations.some((pattern) => {
                return new RegExp(pattern).test(mutation.type);
            });

            if (needsRelease) {
                this.releaseTimeline();
            }

            this.requestTimelineUpdate();
        });

        if (
            !this.sequencesVisible.length ||
            (this.previewStart != -1 && this.sequences[this.previewStart].options.hidden)
        ) {
            this.$store.commit('display/addWarning', 'no-sequences');
            this.$store.commit('loading/prepared', this);
        }

        document.addEventListener('click', this.clickOutside);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.clickOutside);
    }
};
</script>
