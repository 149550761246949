import FetchBrandsApiService from '@/js/infrastructure/api/brands/FetchBrandsApiService.js';
import PaginatedResultDTO from '@/js/dto/PaginatedResultDTO.js';

export default class FetchBrandsDataService {
    constructor(store) {
        this.fetchBrandsApiService = new FetchBrandsApiService(store);
    }

    async fetchBrands(options) {
        let data = (await this.fetchBrandsApiService.fetchBrands(options)).data;
        return new PaginatedResultDTO(data);
    }

    async fetchBrandResources(brandId) {
        return (await this.fetchBrandsApiService.fetchBrandResources(brandId)).data;
    }

    async fetchBrandColorsFromFolderId(folderId) {
        return (await this.fetchBrandsApiService.fetchBrandColors(folderId)).data;
    }
}
