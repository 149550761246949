// WebSockets constants

export const MAIN_CHANNEL = 'main';
export const BRAND_CHANNEL = 'brand.{id}';
export const FOLDER_CHANNEL = 'folder.{id}';
export const FOLDER_VIDEOS_CHANNEL = '.videos';

export const VIDEO_CHANNEL = 'video.{id}';

export const VIDEO_PRODUCTION_UPDATED = '.video.production.updated';
export const VIDEO_VALIDATION_UPDATED = '.video.validation.updated';

export const USER_CHANNEL = 'user.{id}';
export const USER_NOTIFICATION = '.notification';

export const MEDIA_VARIATION_OPTIMIZED_READY = '.media.variation.optimized.ready';
export const MEDIA_VARIATION_THUMBNAIL_READY = '.media.variation.thumbnail.ready';

export const AI_PROMPT_CREATED = '.ai.prompt.created';
export const AI_PROMPT_UPDATED = '.ai.prompt.updated';

export const NEW_BUILD_DEPLOYED = '.new-build-deployed';
