<template>
    <component
        :is="sequenceContainerTagName"
        class="studio-sequence-bg studio-container"
        :class="classes"
        :id="containerID"
    >
        <component :is="sequenceInnerTagName" width="100%" height="100%" class="studio-container">
            <div
                class="studio-container"
                :class="containerClasses"
                :style="containerStyles"
                xmlns="http://www.w3.org/1999/xhtml"
            >
                <div ref="$bgAssets" class="studio-bg-assets studio-container" :style="blurStyles">
                    <AssetImage
                        v-if="isImage || isCartoon"
                        ref="$bgImage"
                        name="$bgImage"
                        :src="state.image.src"
                        width="100%"
                        :height="state.cover ? '100%' : null"
                        :cover="state.cover"
                        classes="studio-editable-container"
                        :styles="assetStyles"
                        :position="state.position"
                        :force-size="true"
                    />
                    <AssetVideo
                        v-else-if="isVideo || isAnimated || isRecording"
                        ref="$bgVideo"
                        name="$bgVideo"
                        classes="studio-editable-container"
                        :styles="assetStyles"
                        :position="state.position"
                        :seqId="id"
                        :syncBy="previousBackgroundSyncBy"
                        :src="videoSrc"
                        width="100%"
                        :height="state.cover ? '100%' : null"
                        :timerange="state.video.timerange"
                        :segments="state.video.timerangeSegments"
                        :playback-rate="state.video.playbackRate"
                        :basisVolume="videoVolume"
                        :volume="volume"
                        :captions="state.video.captions"
                        :cover="state.cover"
                        :force-size="true"
                        @update="update($event)"
                    />

                    <map-zoom
                        v-else-if="isMapZoom"
                        :marker-enabled="state.mapZoom.marker.enabled"
                        :marker-src="state.mapZoom.marker.src"
                        :format="format"
                        :coords="state.mapZoom.coords"
                    />

                    <div v-else-if="isCollage" ref="$bgCollage" class="studio-container" :class="getCollageClass()">
                        <div
                            v-for="(collageImage, index) in collageImages"
                            :key="index"
                            class="studio-bg-collage-element"
                            :class="'studio-bg-collage-element-' + index"
                        >
                            <AssetImage :src="collageImage" :width="collageImagesWidth" height="100%" :cover="true" />
                        </div>
                    </div>

                    <loading-production
                        v-else-if="isLoadingProduction"
                        :stage-scale="$stage.scale"
                        :is-safari="detection.browser.safari"
                    />

                    <div v-if="state.blur" class="studio-bg-blur studio-container"></div>

                    <overlay
                        v-if="!isColor && rawState.overlay.enabled"
                        ref="$bgOverlay"
                        :id="id + '-bg-overlay'"
                        :seqId="id"
                        :resolvedSeqId="resolvedID"
                    />
                </div>

                <div
                    v-if="!!editingElement?.getAnimatedRef"
                    v-for="gridLine in ['v-first', 'v-second', 'h-first', 'h-second']"
                    :key="gridLine"
                    :class="['studio-grid-line', gridLine]"
                ></div>
            </div>
        </component>
    </component>
</template>

<script>
import { Align, Background, Dimension, Duration, Timeline } from '../constants';
import SequenceMixin from '../mixins/Sequence';
import Backgrounds from '../animations/background';
import AssetImage from './assets/AssetImage.vue';
import AssetVideo from './assets/AssetVideo.vue';
import Overlay from './Overlay.vue';
import gsap from 'gsap';
import CustomEase from 'gsap/CustomEase';
import { mapState } from 'vuex';
import MapZoom from '@/js/video-studio/components/background/MapZoom.vue';
import LoadingProduction from '@/js/video-studio/components/background/LoadingProduction.vue';

gsap.registerPlugin(CustomEase);

export default {
    components: {
        LoadingProduction,
        MapZoom,
        AssetImage,
        AssetVideo,
        Overlay
    },

    mixins: [SequenceMixin],
    inject: ['$stage'],

    props: {
        format: Object
    },

    data() {
        return {
            editingData: {
                corners: [],
                borders: [],
                movement: true,
                initFrameBeforeTransform: false,
                homotheticOnCorners: true,
                disableSizePreview: false,
                clearnessPreview: false,
                clearBeforePreview: false,
                previewAttribute: null,
                disableFinalState: true,
                homotheticMinimalSize: true
            },
            assetPosition: {
                top: '0',
                left: '0'
            }
        };
    },

    computed: {
        ...mapState({
            sequence(state) {
                return state.sequences[this.id];
            },
            rawState(state) {
                return state.sequences[this.id].background;
            },
            collage(state) {
                return state.sequences[this.id].background.collage;
            },

            editingElement: (state) => state.edition.editingElement,
            sequenceElapsedTime: (state) => state.display.timeline.sequenceElapsedTime
        }),

        isPrevious() {
            return this.$store.getters['sequences/' + this.id + '/hasPreviousBackground'];
        },

        previousWithBackground() {
            return this.$store.getters['sequences/' + this.id + '/previousWithBackground'];
        },

        previousBackgroundSyncBy() {
            if (!this.isPrevious || (!this.isVideo && !this.isAnimated)) return null;
            return this.$stage.getBackgroundElement(this.previous.id).$refs.$bgVideo;
        },
        resolvedID() {
            return !this.isPrevious || !this.previousWithBackground ? this.id : this.previousWithBackground.id;
        },
        state() {
            return !this.isPrevious || !this.previousWithBackground
                ? this.rawState
                : this.previousWithBackground.background;
        },

        isColor() {
            return (
                (this.isPrevious && !this.previousWithBackground) ||
                this.$store.getters['sequences/' + this.resolvedID + '/hasColorBackground']
            );
        },
        isImage() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasImageBackground'];
        },
        isVideo() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasVideoBackground'];
        },
        isCartoon() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasCartoonBackground'];
        },
        isCollage() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasCollageBackground'];
        },
        isAnimated() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasAnimatedBackground'];
        },
        isRecording() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasRecordingBackground'];
        },
        isMapZoom() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasMapZoomBackground'];
        },

        isLoadingProduction() {
            return this.$store.getters['sequences/' + this.resolvedID + '/hasLoadingProductionBackground'];
        },

        innerAnimationType() {
            return this.state.animation.type != Background.ANIMATION_NONE
                ? Backgrounds[this.state.animation.type]
                : null;
        },

        panelHasBgMove() {
            return this.$store.getters['sequences/' + this.id + '/panelHasBgMove'];
        },

        videoVolume() {
            return this.$store.getters['sequences/' + this.resolvedID + '/video/volume'];
        },

        videoSrc() {
            return this.$store.getters['sequences/' + this.resolvedID + '/backgroundVideoSrc'];
        },

        containerID() {
            return this.id + '-bg';
        },

        containerStyles() {
            return {
                backgroundColor: this.isColor ? this.state.color.start : null
            };
        },

        blurStyles() {
            return !this.state.blur
                ? {}
                : {
                      backdropFilter: 'blur(' + Background.BLUR_FILTER_AMOUNT + Dimension.PIXEL_UNIT + ')'
                  };
        },

        collageImages() {
            let images_url = [];
            Object.keys(this.collage.images)
                .filter((image) => {
                    return !/(?:__ref|__id)$/.test(image);
                })
                .forEach((attr) => {
                    if (this.collage.images[attr] !== '') images_url.push(this.collage.images[attr]);
                });
            return images_url;
        },

        collageImagesWidth() {
            return this.state.animation.type === Background.COLLAGE_INCLINED_TYPE ? '130%' : '100%';
        },

        assetStyles() {
            return this.state.cover ? null : { ...this.assetPosition, transform: `scale(${this.state.scale.value})` };
        }
    },

    watch: {
        format(newValue, oldValue) {
            if (newValue.width != oldValue.width || newValue.height != oldValue.height) {
                this.$store.dispatch('sequences/' + this.id + '/resetPosition');
                this.updateTimeline();
            }
        },

        'rawState.type': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.type': {
            handler() {
                if (this.state != this.rawState) this.updateTimeline();
            }
        },

        'state.image.src': {
            handler() {
                this.updateTimeline();
            }
        },

        'state.animation.type': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.animation.zoomTarget': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.animation.zoomTargetX': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.animation.zoomTargetY': {
            handler() {
                this.updateTimeline();
            }
        },
        'state.collage.animated': {
            handler() {
                this.updateTimeline();
            }
        },

        'state.mapZoom.marker.enabled': {
            handler() {
                this.updateTimeline();
            }
        }
    },

    methods: {
        setEl() {
            let selector =
                '#' +
                this.containerID +
                ' > ' +
                this.sequenceInnerTagName +
                (!this.detection.browser.edge ? ' > div' : '');
            this.$store.commit('sequences/' + this.id + '/setBackgroundEl', document.querySelector(selector));
        },

        update(event) {
            this.updateTimeline();
        },

        updateTimeline() {
            this.$store.commit('sequences/' + this.id + '/setBackgroundTimeline', () => this.getTimeline());
        },

        getTimeline() {
            this._timeline.seek(0);
            this._timeline.clear();
            this._timeline.kill();

            // clear properties of inner background animation
            const animatedRef = this.getAnimatedRef();
            if (this.$refs[animatedRef]) {
                gsap.set(this.getAnimatedAsset(), { clearProps: 'transform,transformOrigin' });
            }

            this._timeline = gsap.timeline({ paused: true, id: Timeline.BACKGROUND_TIMELINE_ID });

            if (this.$refs.$bgVideo) {
                this._timeline.add(this.$refs.$bgVideo.timeline().play(), 0);
                if (
                    this.$refs.$bgVideo.totalDuration !==
                    this.$store.getters['sequences/' + this.id + '/video/totalDuration']
                )
                    this.$store.commit(
                        'sequences/' + this.id + '/video/setTotalDuration',
                        this.$refs.$bgVideo.totalDuration
                    );
            }

            if (
                this.isColor ||
                this.isImage ||
                ((this.isVideo || this.isAnimated || this.isRecording) && this.state.video.src === '')
            )
                this._timeline.to({}, { duration: Duration.READING_DEFAULT });

            if (
                (this.isImage || this.isVideo || this.isAnimated || this.isCartoon || this.isRecording) &&
                this.innerAnimationType
            ) {
                let ts = this._timeline.totalDuration() ? 1 / this._timeline.totalDuration() : 10000;
                this._timeline.add(this.getInnerAnimationTimeline().timeScale(ts), 0);
            } else this._innerAnimationTimeline.clear();

            if (this.isCollage && this.collage.animated) {
                let sequenceDuration = this.sequence.timeline().totalDuration();
                if (this.sequence.options.duration) sequenceDuration = this.sequence.options.duration;

                this.collageImages.forEach((collageElement, index) => {
                    let collageElementSelector = '#' + this.containerID + ' .studio-bg-collage-element-' + index,
                        animationDuration = sequenceDuration / this.collageImages.length,
                        animationStart = index * animationDuration;

                    this._timeline.fromTo(
                        collageElementSelector,
                        { zIndex: 0 },
                        { zIndex: 1, duration: 0.001 },
                        animationStart
                    );
                    this._timeline.fromTo(
                        collageElementSelector + ' .image-container',
                        { scale: 1 },
                        { scale: 1.3, transformOrigin: 'center', duration: animationDuration, ease: 'power1.in' },
                        animationStart
                    );
                    this._timeline.to(
                        collageElementSelector,
                        { zIndex: 0, duration: 0.001 },
                        animationStart + animationDuration
                    );
                });
            }

            if (this.isMapZoom) {
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-image:not(.studio-map-zoom-image-roadmap)',
                    { autoAlpha: 1 },
                    0
                );
                this._timeline.fromTo(
                    '#' + this.containerID + ' .studio-map-zoom-level-1',
                    { scale: 1 },
                    {
                        duration: 5,
                        scale: 1030,
                        force3D: this.detection.browser.safari ? false : 'auto',
                        ease: CustomEase.create('custom', 'M0,0,C0.512,0,0.642,0.131,0.75,0.352,0.87,0.598,0.902,1,1,1')
                    }
                );
                this._timeline.fromTo(
                    '#' + this.containerID + ' .studio-map-zoom-level-1',
                    { rotation: 0 },
                    {
                        duration: 4.2,
                        rotation: 12.5,
                        ease: CustomEase.create('custom', 'M0,0,C0.23,0,0.174,1,0.4,1,0.634,1,0.598,0,1,0')
                    },
                    0.8
                );
                this._timeline.fromTo(
                    '#' + this.containerID + ' .studio-map-zoom-image-roadmap',
                    { autoAlpha: 0 },
                    { duration: 0.4, autoAlpha: 1 }
                );

                this._timeline.add(
                    [
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-1',
                            { scale: 1.3, rotation: 0 },
                            { duration: 0.5, scale: 4.7, rotation: 5 }
                        ),
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-1',
                            { autoAlpha: 0 },
                            { duration: 0.3, autoAlpha: 1 }
                        ),
                        gsap.to('#' + this.containerID + ' .studio-map-zoom-clouds-1', {
                            delay: 0.4,
                            duration: 0.1,
                            autoAlpha: 0
                        })
                    ],
                    1.6
                );
                this._timeline.add(
                    [
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { scale: 0.75, rotation: 17 },
                            { duration: 0.6, scale: 3.8, rotation: 33 }
                        ),
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { autoAlpha: 0 },
                            { duration: 0.3, autoAlpha: 1 }
                        ),
                        gsap.to('#' + this.containerID + ' .studio-map-zoom-clouds-2', {
                            delay: 0.5,
                            duration: 0.1,
                            autoAlpha: 0
                        })
                    ],
                    1.8
                );
                this._timeline.add(
                    [
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { scale: 0.75, rotation: -30 },
                            { duration: 0.4, scale: 3.8, rotation: -40 }
                        ),
                        gsap.fromTo(
                            '#' + this.containerID + ' .studio-map-zoom-clouds-2',
                            { autoAlpha: 0 },
                            { duration: 0.2, autoAlpha: 1 }
                        ),
                        gsap.to('#' + this.containerID + ' .studio-map-zoom-clouds-2', {
                            delay: 0.3,
                            duration: 0.1,
                            autoAlpha: 0
                        })
                    ],
                    2.7
                );

                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-1 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    0.7
                );
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-2 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    2
                );
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-3 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    3.2
                );
                this._timeline.set(
                    '#' + this.containerID + ' .studio-map-zoom-level-4 > .studio-map-zoom-image',
                    { autoAlpha: 0 },
                    5
                );
                this._timeline.set(
                    '#' +
                        this.containerID +
                        ' .studio-map-zoom-level-4 > .studio-map-zoom-image:not(.studio-map-zoom-image-roadmap)',
                    { autoAlpha: 0 }
                );

                if (this.state.mapZoom.marker.enabled) {
                    let mr = Background.MAP_ZOOM_WIDTH / Background.MAP_ZOOM_HEIGHT,
                        fr = this.format.width / this.format.height,
                        mh = (fr >= mr ? this.format.height : this.format.height / (mr / fr)) + 10;
                    this._timeline.fromTo(
                        '#' + this.containerID + ' .studio-map-zoom-marker-shadow',
                        { scale: 3, xPercent: -50, yPercent: 50, autoAlpha: 0 },
                        { duration: 0.5, scale: 1, xPercent: -50, yPercent: 50, autoAlpha: 1, ease: 'bounce.out' },
                        '+=0.15'
                    );
                    this._timeline.fromTo(
                        '#' + this.containerID + ' .studio-map-zoom-marker-image',
                        { scale: 1, y: -mh / 2 },
                        { duration: 0.5, scale: 1, y: 0, ease: 'bounce.out' },
                        '<'
                    );
                }

                this._timeline.set({}, {}, '+=' + Duration.BG_MAP_ZOOM_READING_DEFAULT);
            }

            if (!this.isColor && this.rawState.overlay.enabled) {
                this.$refs.$bgOverlay.releaseTimeline();
                this._timeline.add(this.$refs.$bgOverlay.timeline(), 0);
            }

            if (this.panelHasBgMove) {
                this._timeline.add(this.getPanelBackgroundAnimationTimeline(), 0.0001);
            } else {
                this._panelBackgroundTimeline.clear();
            }

            this._timeline.addLabel('end');
            return this._timeline;
        },

        getInnerAnimationTimeline() {
            this._innerAnimationTimeline.seek(0);
            this._innerAnimationTimeline.clear();
            this._innerAnimationTimeline.kill();

            this._innerAnimationTimeline = gsap.timeline({ id: Timeline.INNER_BACKGROUND_TIMELINE_ID });
            this._innerAnimationTimeline.add(
                this.innerAnimationType.animate(this.getAnimatedAsset(), this.state.animation),
                0
            );
            return this._innerAnimationTimeline;
        },

        getPanelBackgroundAnimationTimeline() {
            let panelStart = this.sequence.panel.animation.start,
                panelEnd = this.sequence.panel.animation.end;

            this._panelBackgroundTimeline.seek(0);
            this._panelBackgroundTimeline.clear();
            this._panelBackgroundTimeline.kill();

            this._panelBackgroundTimeline.set(this.$refs.$bgAssets, { clearProps: 'transform' }, 0);
            this._panelBackgroundTimeline.add(
                this.$stage.getSequenceElement(this.id).getPanel().getBackgroundTimeline(this.$refs.$bgAssets),
                Math.max(0.0001, panelStart)
            );

            if (panelEnd != Duration.END_DEFAULT) {
                this._panelBackgroundTimeline.add(
                    this.$stage.getSequenceElement(this.id).getPanel().getBackgroundTimelineOut(this.$refs.$bgAssets),
                    Math.max(0.0001, panelEnd)
                );
            }

            return this._panelBackgroundTimeline;
        },

        getCollageClass() {
            let animationClassName = (
                !!this.state.animation.type && Background.COLLAGE_TYPES.includes(this.state.animation.type)
                    ? this.state.animation.type
                    : Background.COLLAGE_DEFAULT_TYPE
            ).toLowerCase();
            return 'studio-bg-' + animationClassName;
        },

        getAnimatedRef() {
            return this.isVideo || this.isAnimated || this.isRecording ? '$bgVideo' : '$bgImage';
        },

        getAnimatedAsset() {
            const ref = this.getAnimatedRef();
            const innerAssetRef = this.$refs[ref].imageReference || this.$refs[ref].videoReference;

            return this.$refs[ref].$refs[innerAssetRef];
        },

        editScale(scaleValue) {
            this.$store.commit('sequences/' + this.id + '/setScaleValue', scaleValue);
        },

        editPosition(position, alignH, alignV) {
            this.$store.commit('sequences/' + this.id + '/setPosition', {
                alignH: alignH || Align.CUSTOM,
                alignV: alignV || Align.CUSTOM,
                alignX: position.left,
                alignY: position.top
            });
        },

        // only called once when clicking on the background to disable the cover mode and apply CSS style properties on it
        // replicates the cover mode while enabling the ability to move and scale the original asset
        enableEditing() {
            this.$store.commit('sequences/' + this.id + '/setCover', false);

            this.$nextTick(() => {
                const animatedRef = this.getAnimatedRef();

                const stageRect = this.$stage.$el.getBoundingClientRect();
                const assetRect = this.$refs[animatedRef].$el.getBoundingClientRect();

                const highestRatio = Math.max(stageRect.height / assetRect.height, stageRect.width / assetRect.width);
                const top = (stageRect.height - assetRect.height) / 2 / stageRect.height;

                this.$store.dispatch('sequences/' + this.id + '/initializeScale', highestRatio);
                this.assetPosition.top = top * 100 + Dimension.PERCENT_UNIT;
                this.editPosition({ left: '0%', top: this.assetPosition.top }, null, null);
            });
        },

        removeAnimationStyles() {
            const asset = this.getAnimatedAsset();
            if (asset) {
                this._innerAnimationStyles = asset.style.cssText;
                asset.style = '';
            } else {
                this._innerAnimationStyles = '';
            }
        },

        setAnimationStyles() {
            if (
                (this.isImage || this.isVideo || this.isAnimated || this.isCartoon || this.isRecording) &&
                this.innerAnimationType
            ) {
                const asset = this.getAnimatedAsset();
                if (asset) asset.style = this._innerAnimationStyles;
            }
        }
    },

    created() {
        this._timeline = gsap.timeline({ paused: true, id: Timeline.BACKGROUND_TIMELINE_ID });
        this._innerAnimationTimeline = gsap.timeline({ id: Timeline.INNER_BACKGROUND_TIMELINE_ID });
        this._panelBackgroundTimeline = gsap.timeline({ id: Timeline.PANEL_BG_TIMELINE_ID });
        this._innerAnimationStyles = '';
    },

    mounted() {
        this.updateTimeline();
    },

    beforeUnmount() {
        this._panelBackgroundTimeline.kill();
        this._innerAnimationTimeline.kill();
        this._timeline.kill();
    }
};
</script>
