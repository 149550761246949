import { ref } from 'vue';

export function useVideoSegments(propsSegments, sequenceProps, displayLimitDuration) {
    const segments = ref(propsSegments);
    const isDragging = ref(false);
    const isUpdating = ref(false);
    const SEGMENT_TIME_BUFFER = 0.14;

    const removeSegment = (index) => {
        if (segments.value.length === 1) {
            resetSegments();
            return;
        }
        segments.value.splice(index, 1);
    };

    const resetSegments = () => {
        segments.value = [
            {
                start: 0,
                end: sequenceProps.endTime
            }
        ];
    };

    const updateSegmentEnd = (duration) => {
        try {
            if (segments.value[0].end == 0) segments.value[0].end = duration;
        } catch (error) {
            segments.value = [{ start: 0, end: duration }];
        }
    };

    const splitVideo = (currentTime) => {
        const newSegments = [];

        for (const segment of segments.value) {
            if (currentTime >= segment.start && currentTime <= segment.end) {
                if (currentTime > segment.start) {
                    newSegments.push({ start: segment.start, end: currentTime });
                }
                if (currentTime < segment.end) {
                    newSegments.push({ start: currentTime, end: segment.end });
                }
            } else {
                newSegments.push(segment);
            }
        }

        segments.value = newSegments;
    };

    const mergeSegments = () => {
        segments.value.sort((a, b) => a.start - b.start);

        for (let i = 0; i < segments.value.length - 1; ) {
            const current = segments.value[i];
            const next = segments.value[i + 1];

            if (current.end > next.start) {
                current.end = next.end;
                segments.value.splice(i + 1, 1);
                isDragging.value = false;
            } else {
                i++;
            }
        }
    };

    const mergeAdjacentSegments = (currentIndex) => {
        if (currentIndex < segments.value.length - 1) {
            const currentSegment = segments.value[currentIndex];
            const nextSegment = segments.value[currentIndex + 1];
            if (currentSegment.end > nextSegment.start) {
                currentSegment.end = nextSegment.end;
                segments.value.splice(currentIndex + 1, 1);
                isDragging.value = false;
            }
        }
    };

    const updateSegmentPosition = async (newTime, minSegmentSizeTime, currentSegment, isStartHandle) => {
        if (isUpdating.value) return;

        if (sequenceProps.sequenceEndTime != 0) {
            const sumOfSegments = segments.value.reduce((acc, segment) => acc + (segment.end - segment.start), 0);
            const currentSegmentDuration = currentSegment.end - currentSegment.start;
            const newSegmentSize = isStartHandle
                ? currentSegmentDuration - (newTime - currentSegment.start)
                : currentSegmentDuration - (currentSegment.end - newTime);
            const newSumOfSegments = sumOfSegments - currentSegmentDuration + newSegmentSize;
            const adjustedLimit =
                (sequenceProps.sequenceEndTime - sequenceProps.startTime) * sequenceProps.playbackRate;

            // Enhanced magnetic effect with stricter threshold
            const SNAP_THRESHOLD = 0.2; // Increased threshold for better detection
            if (Math.abs(newSumOfSegments - adjustedLimit) <= SNAP_THRESHOLD) {
                // Force exact alignment to the limit
                if (isStartHandle) {
                    // Calculate exact start position to match the limit
                    const otherSegmentsDuration = sumOfSegments - currentSegmentDuration;
                    const requiredDuration = adjustedLimit - otherSegmentsDuration;
                    newTime = currentSegment.end - requiredDuration;
                } else {
                    // Calculate exact end position to match the limit
                    const otherSegmentsDuration = sumOfSegments - currentSegmentDuration;
                    const requiredDuration = adjustedLimit - otherSegmentsDuration;
                    newTime = currentSegment.start + requiredDuration;
                }
            } else if (newSumOfSegments > adjustedLimit + SEGMENT_TIME_BUFFER) {
                displayLimitDuration();
                return;
            }
        }

        try {
            isUpdating.value = true;

            if (isStartHandle) {
                const newStart = Math.min(newTime, currentSegment.end - minSegmentSizeTime);
                if (currentSegment.end - newStart >= minSegmentSizeTime) {
                    currentSegment.start = newStart;
                }
            } else {
                const newEnd = Math.max(newTime, currentSegment.start + minSegmentSizeTime);
                if (newEnd - currentSegment.start >= minSegmentSizeTime) {
                    currentSegment.end = newEnd;
                }
            }
        } finally {
            isUpdating.value = false;
        }
    };

    function findCurrentSegmentIndex(time) {
        return segments.value.findIndex((segment) => time >= segment.start && time <= segment.end);
    }

    const startDrag = () => {
        isDragging.value = true;
    };

    const stopDrag = () => {
        isDragging.value = false;
    };

    return {
        segments,
        updateSegmentEnd,
        splitVideo,
        removeSegment,
        mergeSegments,
        mergeAdjacentSegments,
        resetSegments,
        updateSegmentPosition,
        findCurrentSegmentIndex,
        isDragging,
        startDrag,
        stopDrag
    };
}
