<template>
    <div class="video-saving-error-modal">
        <div class="video-saving-error-title">
            <fa-icon class="icon" icon="fa-regular fa-circle-exclamation" />
            <span>{{ $t('video.saving.error.title') }}</span>
        </div>
        <p v-if="remainingSeconds">
            <span>{{ printf($t('video.saving.error.attempt'), remainingSeconds) }}</span>
        </p>
        <p>
            {{ $t('video.saving.error.description') }}<br />
            <i18n-t keypath="video.saving.error.support">
                <a href="mailto:support@2emotion.com" target="_blank">{{ $t('video.saving.error.link') }}</a>
            </i18n-t>
        </p>
        <div @click="refresh()" class="ui-simple-button ui-simple-button-border">
            {{ $t('video.saving.error.refresh') }}
        </div>
    </div>
</template>

<script setup>
import '@/sass/ui/modals/video-saving-error-modal.scss';
import { useStore } from 'vuex';
import { onMounted, onUnmounted, ref } from 'vue';
import { printf } from 'fast-printf';

const store = useStore();

const savingAttemptDelay = 4000,
    checkTimestampDelay = 1000;

let remainingSeconds = ref(0),
    intervalId,
    lastSavingTimestamp;

const saveVideo = () => {
    store.dispatch('ui/saveVideo', true);
};

const setSavingTimestamp = () => {
    lastSavingTimestamp = Date.now();
};

const checkTimestamp = () => {
    remainingSeconds.value = Math.round((savingAttemptDelay - (Date.now() - lastSavingTimestamp)) / 1000);

    if (remainingSeconds.value <= 0) {
        setSavingTimestamp();
        saveVideo();
    }
};

const refresh = () => {
    location.reload();
};

onMounted(() => {
    setSavingTimestamp();
    intervalId = setInterval(checkTimestamp, checkTimestampDelay);
});

onUnmounted(() => {
    clearInterval(intervalId);
});
</script>
