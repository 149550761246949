<template>
    <div class="duration-limit-alert" :class="{ 'fade-out': isClosing }">
        <div class="duration-limit-content">
            <p class="duration-limit-text primary">
                {{ limitMessage }}
            </p>
            <p class="duration-limit-text secondary">
                {{ actionMessage }}
            </p>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

// Define limit type options as a const map for better type safety
const LIMIT_TYPES = {
    sequence: 'sequence',
    element: 'element',
    background: 'background'
} as const;

// Define props with TypeScript interface
interface Props {
    sequenceEndTime: number;
    isClosing?: boolean;
    limitType: keyof typeof LIMIT_TYPES;
}

// Define props with withDefaults
const props = withDefaults(defineProps<Props>(), {
    isClosing: false
});

// Use computed with proper typing
const limitMessage = computed(() => {
    const messages = {
        sequence: t('duration-limit.message-sequence'),
        element: t('duration-limit.message-element'),
        background: t('duration-limit.message-background')
    };
    return messages[props.limitType] ?? '';
});

const actionMessage = computed(() => {
    const messages = {
        sequence: t('duration-limit.action-sequence'),
        element: t('duration-limit.action-element'),
        background: t('duration-limit.action-background')
    };
    return messages[props.limitType] ?? '';
});
</script>

<style scoped>
.duration-limit-alert {
    background-color: var(--edit-mode-color);
    color: white;
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    animation: slideIn 0.3s ease-out;
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1000;
}

.duration-limit-icon {
    font-size: 20px;
}

.duration-limit-text {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
}

.fade-out {
    animation: fadeOut 0.3s ease-out forwards;
}

@keyframes slideIn {
    from {
        transform: translate(-50%, -80px);
        opacity: 0;
    }
    to {
        transform: translate(-50%, 0);
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        transform: translate(-50%, 0);
        opacity: 1;
    }
    to {
        transform: translate(-50%, -80px);
        opacity: 0;
    }
}

.duration-limit-content {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.duration-limit-text.primary {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
}

.duration-limit-text.secondary {
    margin: 0;
    font-size: 12px;
    font-weight: 400;
}
</style>
