// Video constants

export const PRODUCTION_STATUS_NONE = 0;
export const PRODUCTION_STATUS_PENDING = 1;
export const PRODUCTION_STATUS_PRODUCING = 2;
export const PRODUCTION_STATUS_PRODUCED = 3;
export const PRODUCTION_STATUS_FAILED = 9;

export const VALIDATION_STATUS_NONE = 0;
export const VALIDATION_STATUS_SUBMITTED = 1;
export const VALIDATION_STATUS_VALIDATED = 2;
export const VALIDATION_STATUS_REJECTED = 3;

export const DEFAULT_TEMPLATE_LANGUAGE = 'en';
