import { subscribeToChannel } from '@/js/utils.js';
import { USER_CHANNEL, USER_NOTIFICATION } from '@/js/constants/index.js';
import { useNotification } from '@/js/composables/useNotification.js';

export default class SubscribeNotificationsChannelService {
    subscribe(userId) {
        subscribeToChannel(USER_CHANNEL, { id: userId }, 'private', {
            [USER_NOTIFICATION]: (notification) => {
                this.dispatchNotification(notification.type, notification.style, notification.message);
            }
        });
    }

    dispatchNotification(type, style, message) {
        const { success, error, warning, info, loading } = useNotification(type);

        switch (style) {
            case 'success':
                success(message);
                break;
            case 'error':
                error(message);
                break;
            case 'warning':
                warning(message);
                break;
            case 'info':
                info(message);
                break;
            case 'loading':
                loading(message);
                break;
            default:
                info(message);
                break;
        }
    }
}
