<template>
    <div class="edit-client-users-list">
        <div class="edit-client-users-list-header text-light-gray-alt">
            <div />
            <div class="edit-client-users-list-header-title">{{ $t('User') }}</div>
            <div class="edit-client-users-list-header-title text-center">{{ $t('License type') }}</div>
            <div class="edit-client-users-list-header-title text-center">{{ $t('Video number') }}</div>
            <div class="edit-client-users-list-header-title text-center">{{ $t('Last time active') }}</div>
            <div />
        </div>
        <ui-infinite-scroll-paginator v-model="page" :max-page="totalPages" :loading="isLoading">
            <edit-client-users-list-item v-for="user in users" :key="user.uuid" :user="user" :client="client" />
            <template v-slot:loading>
                <div class="brands-loader-container">
                    <LoadingMarker v-if="isLoading" />
                </div>
            </template>
        </ui-infinite-scroll-paginator>
    </div>
</template>

<script setup>
import ClientDTO from '@/js/dto/ClientDTO.js';
import { useUsers } from '@/js/composables/useUsers.js';
import { computed, onMounted, ref, watch } from 'vue';
import EditClientUsersListItem from '@/views/EditClientView/components/EditClientUsersListItem.vue';
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
import LoadingMarker from '@/js/components/LoadingMarker.vue';
import { debounce } from 'lodash';

const props = defineProps({
    client: {
        type: ClientDTO,
        required: true
    },
    searchText: String
});

const clientId = ref(props.client.id);

const options = computed(() => ({
    page: page.value,
    search: props.searchText
}));

const { page, totalPages, isLoading, users, fetchClientUsers, resetDataForUsers } = useUsers(clientId);

const debouncedFetchClientUsers = debounce(() => {
    resetDataForUsers();
    fetchClientUsers(options.value);
}, 500);
watch(() => props.searchText, debouncedFetchClientUsers);

watch(
    () => props.client,
    () => {
        clientId.value = props.client.id;
        resetDataForUsers();
        fetchClientUsers(options.value);
    }
);

watch(page, () => {
    fetchClientUsers(options.value);
});

onMounted(() => {
    fetchClientUsers(options.value);
});

defineExpose({ resetDataForUsers, fetchClientUsers });
</script>
