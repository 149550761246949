// Brand utilities

import _isArray from 'lodash/isArray';
import _isPlainObject from 'lodash/isPlainObject';
import _merge from 'lodash/merge';
import _transform from 'lodash/transform';

const filterState = (state, onlyKeys, excludeKeys) => {
    onlyKeys = _isArray(onlyKeys) ? onlyKeys : [];
    excludeKeys = (_isArray(excludeKeys) ? excludeKeys : []).concat([
        'libraries.zoomTargets',
        'libraries.tagOrders',
        'libraries.loaded',
        'libraries.filters',
        'libraries.sortTypes',
        'libraries.languages',
        'libraries.selections'
    ]);

    return _transform(_merge({}, state), (filtered, value, key, object) => {
        if ((!onlyKeys.length || onlyKeys.indexOf(key) != -1) && excludeKeys.indexOf(key) == -1) {
            let only = onlyKeys.reduce((filteredOnly, onlyKey) => {
                let matches = onlyKey.match(/^([^\.]+)\.(.+)$/);
                if (matches && (matches[1] == key || matches[1] == '*')) filteredOnly.push(matches[2]);
                return filteredOnly;
            }, []);

            let exclude = excludeKeys.reduce((filteredExclude, excludeKey) => {
                let matches = excludeKey.match(/^([^\.]+)\.(.+)$/);
                if (matches && (matches[1] == key || matches[1] == '*')) filteredExclude.push(matches[2]);
                return filteredExclude;
            }, []);

            filtered[key] = _isPlainObject(value) ? filterState(value, only, exclude) : value;
        }
    });
};

const formatStateForSave = (state) => {
    let { libraries, ...settings } = state;

    return _merge(
        {},
        {
            settings,
            libraries: Object.entries(libraries).reduce((formattedLibraries, [name, library]) => {
                formattedLibraries[name] = library.reduce((items, item) => {
                    items[item.id] = item.status;
                    return items;
                }, {});
                return formattedLibraries;
            }, {})
        }
    );
};

export { filterState, formatStateForSave };
