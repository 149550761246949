<template>
    <button class="preview-action preview-action-play-sequence" :disabled="!canPreviewSequence" @click="playSequence">
        <span class="action-icon">
            <svg-id-randomized-icon icon="cte-icon" />
        </span>
        <span class="action-label">{{ $t('Sequence preview') }}</span>
    </button>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    name: 'SequencePreviewButton',

    inject: ['$videoStudio'],

    props: {},

    computed: {
        ...mapState({
            sequenceMode: (state) => state.ui.sequenceMode,
            currentEditedItemId: (state) => state.ui.currentEditedItemId
        }),

        ...mapGetters({
            canPreviewSequence: 'ui/canPreviewSequence'
        }),

        firstSequenceId() {
            return this.$store.getters['sequences/childAt'](0).id;
        }
    },

    methods: {
        playSequence() {
            let timelineId = !this.sequenceMode ? this.currentEditedItemId : this.firstSequenceId;

            this.$store.commit('preview/setCurrentTimelineId', timelineId);
            this.$videoStudio.studio.$stage.seekSequenceTimeline(timelineId, 0.001);
            this.$videoStudio.studio.$stage.playTimeline();
        }
    }
};
</script>

<style scoped></style>
