<template>
    <div :class="['current-time', positionClass]" :style="style">
        {{ formattedTime }}
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { formatTime } from '@/js/videos/utils/time';
// Types
interface Props {
    currentTime: number;
    position: 'left' | 'right' | 'center';
    x: number;
    y: number;
    rate?: number;
}

// Constants
const FRAME_RATE_THRESHOLD = 100;
const DEFAULT_FRAME_RATE = 100; // 100 is the frame rate of the video to feat with gsap (should be 25 / 30 / 60)

// Props definition
const props = withDefaults(defineProps<Props>(), {
    rate: DEFAULT_FRAME_RATE
});

// Utility functions
function padNumber(num: number, minLength: number = 2): string {
    return num.toString().padStart(minLength, '0');
}

// Computed properties
const formattedTime = computed(() => {
    return formatTime(props.currentTime || 0, props.rate, undefined, {
        includeFrames: true,
        padFrames: props.rate > FRAME_RATE_THRESHOLD
    });
});

const positionClass = computed(() => `current-time-${props.position}`);

const style = computed(() => ({
    position: 'fixed',
    left: `${props.x}px`,
    top: `${props.y}px`
}));
</script>

<style scoped>
.current-time {
    --triangle-size: 4px;

    position: relative;
    color: white;
    font-size: 12px;
    white-space: nowrap;
    background-color: var(--edit-mode-color);
    padding: 2px 4px;
    border-radius: 4px;
    user-select: none;
    pointer-events: none;
    z-index: 9999;
}

.current-time::after {
    content: '';
    position: absolute;
    bottom: calc(-1 * var(--triangle-size));
    left: 50%;
    transform: translateX(-50%);
    border: var(--triangle-size) solid transparent;
    border-top-color: var(--edit-mode-color);
}

.current-time-right,
.current-time-left,
.current-time-center {
    transform: translateX(-50%);
}
</style>
