import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class ProduceVideoApiService {
    constructor(store) {
        this.store = store;
    }

    handle(videoId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${EDITION_URL}/api/videos/${videoId}/produce`);
            },
            onSuccess: async (response) => response.data,
            actionType: 'produceVideo'
        });
    }

    handleTemplates(templateIds, type, brandId) {
        return executeRequest(this.store, {
            request: async (client) => {
                return await client.post(`${EDITION_URL}/api/brands/${brandId}/produce-templates`, {
                    template_ids: templateIds,
                    type: type
                });
            },
            onSuccess: async (response) => response.data,
            actionType: 'produceVideo'
        });
    }
}
