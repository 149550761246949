<template>
    <dropdown v-model="selectedBrandId" class="basic-dropdown">
        <template #dropdown-selected-value>
            <image-fallback v-if="selectedBrand" :src="selectedBrand.logoUrl" class="dropdown-list-item-image" />
        </template>
        <UiTextInput
            id="brand-selector-search"
            type="search"
            :placeholder="$t('Search')"
            :show-label="false"
            icon="search-icon"
            v-model="searchFilter"
        />
        <ui-infinite-scroll-paginator v-model="page" :max-page="totalPages" :loading="isLoading">
            <dropdown-item v-for="brand in brands" :key="brand.id" :value="brand.id">
                <image-fallback :src="brand.logoUrl" class="dropdown-list-item-image" />
                <span class="dropdown-list-item-label">{{ brand.name }}</span>
            </dropdown-item>
            <template v-slot:loading>
                <LoadingMarker v-if="isLoading" />
            </template>
        </ui-infinite-scroll-paginator>
    </dropdown>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue';
import { useBrands } from '@/js/composables/useBrands.js';
import DropdownItem from '@/js/components/dropdown/DropdownItem.vue';
import Dropdown from '@/js/components/dropdown/Dropdown.vue';
import ImageFallback from '@/js/components/ImageFallback.vue';
import { useAuth } from '@/js/composables/useAuth.js';
import UiInfiniteScrollPaginator from '@/js/components/UiInfiniteScrollPaginator.vue';
import LoadingMarker from '@/js/components/LoadingMarker.vue';
import UiTextInput from '@/js/components/UiTextInput.vue';
import { debounce } from 'lodash';
import { useStore } from 'vuex';

const props = defineProps({
    modelValue: {
        type: [String, null],
        required: true
    }
});

const store = useStore();
const emit = defineEmits(['update:modelValue']);

const { page, totalPages, isLoading, brands, fetchBrands, resetDataForBrands } = useBrands();
const { user } = useAuth();

const selectedBrandId = ref(props.modelValue);
const selectedBrand = ref(null);
const searchFilter = ref('');

const options = computed(() => ({
    page: page.value,
    search: searchFilter.value
}));

const debouncedSearch = debounce(() => {
    resetDataForBrands();
    fetchBrands(options.value);
}, 500);

const updateTemplateLanguages = (languages) => {
    store.commit('branding/enableTemplateLanguages', languages);
};

watch(searchFilter, () => {
    debouncedSearch();
});

watch(brands, () => {
    if (!selectedBrandId.value && brands.value.length > 0) {
        let lastSelectedId = localStorage.getItem(user.value.id + '-selectedBrandId');
        if (lastSelectedId && brands.value.find((brand) => brand.id === lastSelectedId)) {
            selectedBrandId.value = lastSelectedId;
        } else {
            selectedBrandId.value = brands.value[0].id;
        }

        updateTemplateLanguages(
            brands.value.find((brand) => brand.id === selectedBrandId.value).enabledTemplateLanguages
        );
    }
});

watch(page, () => {
    fetchBrands(options.value);
});

watch(selectedBrandId, (newValue) => {
    selectedBrand.value = brands.value.find((brand) => brand.id === newValue);
    localStorage.setItem(user.value.id + '-selectedBrandId', newValue);
    emit('update:modelValue', newValue);
    const brandData = brands.value.find((brand) => brand.id === newValue);
    if (brandData) {
        updateTemplateLanguages(brandData.enabledTemplateLanguages);
    }
});

onMounted(() => {
    fetchBrands(options.value);
});
</script>
