<template>
    <div
        class="studio-sequence-panel studio-container studio-container-align"
        :id="id"
        :class="classes"
        :style="styles"
    >
        <svg class="studio-hidden-svg">
            <defs>
                <filter :id="colorFilterId">
                    <feFlood :flood-color="colorStart" />
                    <feComposite operator="in" in2="SourceAlpha" />
                </filter>
            </defs>
        </svg>
        <div class="studio-sequence-panel-shape" :id="shapeId" :style="shapeStyles" :class="shapeClasses">
            <component
                ref="$panelShape"
                :is="component"
                :id="id"
                :seqId="seqId"
                :class="componentClasses"
                @size-update="refreshShapeStyles"
            />
        </div>
    </div>
</template>

<script>
import { Align, Dimension, Edition, Panel, Timeline } from '../constants';
import { conversions } from '../utils';
import AssetImage from './assets/AssetImage.vue';
import gsap from 'gsap';
import Editable from '../mixins/Editable';
import { mapState } from 'vuex';
import { defineAsyncComponent } from 'vue';

export default {
    inject: ['$stage'],

    mixins: [Editable],

    components: {
        AssetImage
    },

    props: {
        id: String,
        seqId: String
    },

    data() {
        return {
            active: false,
            shapeSize: {},
            editingType: 'panel'
        };
    },

    computed: {
        ...mapState({
            state(state) {
                return state.sequences[this.seqId].panel;
            }
        }),

        component() {
            const componentName = Panel.PREFIX_CLASS + this.state.shape.type;
            return defineAsyncComponent(() => import(`./panels/${componentName}.vue`));
        },

        hasBgMove() {
            return this.$store.getters['sequences/' + this.seqId + '/panelHasBgMove'];
        },

        isSideCategory() {
            return this.state.shape.category == Panel.SIDE_CATEGORY;
        },

        sideName() {
            return this.$store.getters['sequences/' + this.seqId + '/panelSideName'];
        },

        oppositeSideName() {
            return this.$store.getters['sequences/' + this.seqId + '/panelOppositeSideName'];
        },

        color() {
            return this.$store.getters['sequences/' + this.seqId + '/panelColor'];
        },

        colorStart() {
            return this.$store.getters['sequences/' + this.seqId + '/panelColorStart'];
        },

        colorEnd() {
            return this.$store.getters['sequences/' + this.seqId + '/panelColorEnd'];
        },

        classes() {
            return {
                'studio-active': this.active,
                'needs-refresh':
                    this.state.needsRefresh && this.currentSequences.indexOf(this.seqId) != -1 && this.active
            };
        },

        styles() {
            return {
                opacity: this.state.opacity
            };
        },

        shapeStyles() {
            return Object.assign(
                {
                    '--panel-color-start': this.colorStart,
                    '--panel-color-end': this.colorEnd,
                    '--panel-opacity': this.state.opacity
                },
                this.isSideCategory
                    ? conversions.sidePanelPosition(this.state.position.side, this.state.size.width)
                    : {
                          ...conversions.alignment(
                              this.state.position.alignH,
                              this.state.position.alignV,
                              this.state.position.alignX,
                              this.state.position.alignY
                          ),
                          width: this.shapeSize.width || this.state.size.width,
                          height: this.shapeSize.height || this.state.size.height
                      }
            );
        },

        shapeId() {
            return this.id + '-shape';
        },

        shapeClasses() {
            return [
                Edition.EDITABLE_CONTAINER_CLASS,
                {
                    [Edition.EDITING_PREVIEW_CLASS]: this.previewing
                }
            ];
        },

        componentClasses() {
            return [
                'studio-sequence-panel-shape-inner',
                'studio-panel-category-' + this.state.shape.category.toLowerCase(),
                'studio-panel-type-' + this.state.shape.type.toLowerCase(),
                {
                    ['studio-panel-align-' + this.sideName]: this.isSideCategory
                }
            ];
        },

        colorFilterId() {
            return this.id + '-color-filter';
        },

        isHalfPosition() {
            return this.$store.getters['sequences/' + this.seqId + '/isPanelHalfPosition'];
        },

        editingData() {
            let isSide = this.isSideCategory,
                isSideVertical = isSide && (this.sideName === 'top' || this.sideName === 'bottom');

            return Object.assign(this.editingConf, {
                corners: isSide ? [] : Edition.CORNERS,
                borders: isSide ? [this.oppositeSideName] : this.state.size.ratioConstraint ? [] : Edition.BORDERS,
                movement: !isSide,
                initFrameBeforeTransform: isSide,
                homotheticOnCorners: this.state.size.ratioConstraint,
                clearnessPreview: isSide,
                clearBeforePreview: true,
                previewAttribute: isSide ? (isSideVertical ? 'height' : 'width') : null,
                minA: isSide ? 0 : null,
                maxB: isSide ? 1 : null,
                minC: isSide ? 0 : null,
                maxD: isSide ? 1 : null
            });
        },

        refreshingPropertiesString() {
            return [
                ...this.commonRefreshingProperties,
                this.state.size.width,
                this.state.size.height,
                this.state.shape.type,
                this.state.position.side,
                this.state.size.ratioConstraint
            ].toString();
        }
    },

    watch: {
        'state.shape.type': {
            handler() {
                this.shapeSize = {};
                this.update();
                this.$nextTick(this.$stage.requestTimelineUpdate);
            }
        },

        'state.size.width': {
            handler() {
                if (this.hasBgMove && !this.isEditing) this.$stage.requestTimelineUpdate();
            }
        },

        'state.enabled': {
            handler() {
                this.update();
            }
        },

        'state.position.side': {
            handler() {
                this.$stage.requestTimelineUpdate();
            }
        },

        'state.animation.enabled': {
            handler() {
                this.$stage.requestTimelineUpdate();
            }
        },

        'state.animation.start': {
            handler() {
                this.$stage.requestTimelineUpdate(false);
            }
        },

        'state.animation.end': {
            handler() {
                this.$stage.requestTimelineUpdate(false);
            }
        },

        hasBgMove() {
            this.$stage.requestTimelineUpdate();
        },

        shapeSize() {
            this.$stage.requestTimelineUpdate();
        }
    },

    methods: {
        refreshShapeStyles(sizeStyles) {
            this.shapeSize = sizeStyles;
        },

        update(event) {
            this.updateTimeline();
            this.updateTimelineOut();
        },

        releaseTimeline() {
            gsap.set('#' + this.shapeId, { clearProps: 'opacity,visibility,transform' });
        },

        updateTimeline() {
            this.$store.commit('sequences/' + this.seqId + '/setPanelTimeline', () => this.getTimeline());
        },

        getTimeline() {
            this._timeline.clear();
            this._timeline.kill();
            this._timeline = gsap.timeline({ id: Timeline.PANEL_TIMELINE_ID });
            this.active = false;
            this._timeline.set(this, { active: true });
            if (this.$refs.$panelShape) {
                this._timeline.add(this.$refs.$panelShape.getTimeline(), 0.0001);
            }
            // this._timeline.addLabel('end');

            return this._timeline;
        },

        updateTimelineOut() {
            this.$store.commit('sequences/' + this.seqId + '/setPanelTimelineOut', () => this.getTimelineOut());
        },

        getTimelineOut() {
            this._timelineOut.clear();
            this._timelineOut.kill();
            this._timelineOut = gsap.timeline();

            if (this.$refs.$panelShape) {
                this._timelineOut.add(this.$refs.$panelShape.getTimelineOut());
            }
            this._timelineOut.set(this, { active: false });

            return this._timelineOut;
        },

        getBackgroundTimeline(bgElement) {
            return this.$refs.$panelShape ? this.$refs.$panelShape.getBackgroundTimeline(bgElement) : gsap.timeline();
        },

        getBackgroundTimelineOut(bgElement) {
            return this.$refs.$panelShape
                ? this.$refs.$panelShape.getBackgroundTimelineOut(bgElement)
                : gsap.timeline();
        },

        editPosition(position, alignH, alignV) {
            this.$store.commit('sequences/' + this.seqId + '/setPanelPosition', {
                side: 'L',
                alignH: alignH || Align.CUSTOM,
                alignV: alignV || Align.CUSTOM,
                alignX: position.left,
                alignY: position.top
            });
        },

        editSize(size) {
            let isSide = this.isSideCategory,
                isSideVertical = isSide && (this.sideName === 'top' || this.sideName === 'bottom'),
                width = isSideVertical ? size.height : size.width;

            this.$store.commit('sequences/' + this.seqId + '/setPanelWidth', width);
            if (!isSide) this.$store.commit('sequences/' + this.seqId + '/setPanelHeight', size.height);

            if (isSide) {
                this.$nextTick(() => {
                    this.$stage.requestTimelineUpdate(false);
                });
            }
        },

        convertCustomPosition() {
            let stageRect = this.$stage.$el.getBoundingClientRect(),
                containerRect = this.$refs['$panelShape'].$el.getBoundingClientRect();

            this.$nextTick(() => {
                if (this.$store.hasModule('ui')) this.$store.dispatch('ui/history/startStep');
                this.$store.commit('sequences/' + this.seqId + '/setPanelPosition', {
                    side: 'L',
                    alignH: Align.CUSTOM,
                    alignV: Align.CUSTOM,
                    alignX:
                        conversions.roundPercentage((containerRect.left - stageRect.left) / stageRect.width) +
                        Dimension.PERCENT_UNIT,
                    alignY:
                        conversions.roundPercentage((containerRect.top - stageRect.top) / stageRect.height) +
                        Dimension.PERCENT_UNIT
                });
                if (this.$store.hasModule('ui')) this.$store.dispatch('ui/saveVideo');
            });
        }
    },

    created() {
        this._timeline = gsap.timeline({ id: Timeline.PANEL_TIMELINE_ID });
        this._timelineOut = gsap.timeline();
    },

    mounted() {
        this.update();
    },

    beforeUnmount() {
        this._timeline.kill();
        this._timelineOut.kill();
    }
};
</script>
