<template>
    <UiQuickCut
        v-if="isReady"
        :segments="segments"
        :media="media"
        :media-type="mediaType"
        :playback-rate="playbackRate"
        :sequence-id="sequenceId"
        :element-id="elementId"
        :start-time="startTime"
        :sequence-end-time="endTime"
        :limit-type="limitType"
        @update-segments="updateSegments"
        @close="close"
    />
</template>

<script setup>
import { computed, ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useStoreManagement } from '../composables/useStoreManagement';
import { useSequence } from '@video-composables/useSequence';
import { useElement } from '@video-composables/useElement';
import { useVoiceOver } from '@video-composables/useVoiceOver';

import UiQuickCut from '../UiQuickCut.vue';

const store = useStore();
const { sequenceId, elementId, isOpenQuickCut, mediaType, mediaSrc, closeQuickCut } = useStoreManagement(store);

const segments = ref([]);
const media = ref(null);
const playbackRate = ref(1);
const startTime = ref(0);
const endTime = ref(0);
const sequenceEndTime = ref(0);
const needResetSegments = ref(false);

const limitType = ref('sequence');

const sequenceData = computed(() => {
    if (!sequenceId.value) return null;

    const {
        backgroundVideo,
        audioSrc,
        playbackRate: seqPlaybackRate,
        videoTimerangeSegments: backgroundVideoSegments,
        videoRange,
        audioTimerangeSegments,
        sequenceState,
        sequenceDuration,
        maxDuration,
        maxDurationAudio
    } = useSequence(sequenceId.value, store);

    return {
        backgroundVideo,
        audioSrc,
        seqPlaybackRate,
        backgroundVideoSegments,
        videoRange,
        audioTimerangeSegments,
        sequenceState,
        sequenceDuration,
        maxDuration,
        maxDurationAudio
    };
});

const setMediaSrcInStore = (newMedia) => {
    if (newMedia && mediaSrc.value !== newMedia.src) {
        if (mediaSrc.value !== null) {
            needResetSegments.value = true;
        }
        store.dispatch('ui/quickcut/setMediaSrc', newMedia.src);
    }
};

const elementData = computed(() => {
    if (!sequenceId.value || !elementId.value) return null;

    const { sequenceDuration } = useSequence(sequenceId.value, store);
    console.log('sequenceDuration', sequenceDuration.value);
    const {
        elementState,
        playbackRate: elementPlaybackRate,
        videoTimerangeSegments: visualSegments,
        videoRange,
        videoSrc,
        audioSrc,
        startTime,
        endTime,
        maxDuration
    } = useElement(sequenceId.value, elementId.value, store);

    return {
        elementState,
        elementPlaybackRate,
        visualSegments,
        videoRange,
        videoSrc,
        audioSrc,
        sequenceDuration,
        startTime,
        endTime,
        maxDuration
    };
});

const voiceOverData = computed(() => {
    if (!sequenceId.value) return null;
    const useInSequence = ref(true);
    const { voiceOverMediaState, timerange, timerangeSegments } = useVoiceOver(useInSequence, sequenceId, store);
    return { voiceOverMediaState, timerange, timerangeSegments };
});

const videoTimerangeSegments = computed(() => {
    if (['backgroundVideo', 'recording'].includes(mediaType.value) && sequenceData.value) {
        return sequenceData.value.backgroundVideoSegments.value;
    }
    if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        return elementData.value.visualSegments.value;
    }
    if (mediaType.value === 'audio' && sequenceData.value) {
        return sequenceData.value.audioTimerangeSegments.value;
    }
    if (mediaType.value === 'tts' && voiceOverData.value) {
        return voiceOverData.value.timerangeSegments.value;
    }
    return [];
});

const isReady = computed(() => sequenceId.value && media.value && isOpenQuickCut.value);

const initializeData = {
    backgroundVideo: () => {
        if (!sequenceData.value) return false;

        const { backgroundVideo, seqPlaybackRate, backgroundVideoSegments, sequenceDuration, maxDuration } =
            sequenceData.value;
        media.value = backgroundVideo.value;
        playbackRate.value = seqPlaybackRate.value;
        segments.value = backgroundVideoSegments.value;
        sequenceEndTime.value = sequenceDuration.value;
        startTime.value = 0;
        endTime.value = sequenceDuration.value > 0 ? sequenceDuration.value : maxDuration.value;

        setMediaSrcInStore(media.value);
        return true;
    },
    visual: () => {
        if (!elementData.value) return false;

        const { videoSrc, elementState, elementPlaybackRate, visualSegments, sequenceDuration, maxDuration } =
            elementData.value;
        media.value = videoSrc.value;
        playbackRate.value = elementPlaybackRate.value;
        segments.value = visualSegments.value;

        startTime.value = elementState.value.animation.start.value;
        if (elementState.value.animation.end.value !== -1) {
            if (sequenceDuration.value === 0 || sequenceDuration.value === null) {
                endTime.value = elementState.value.animation.end.value;
                limitType.value = 'element';
            } else {
                endTime.value = Math.min(elementState.value.animation.end.value, sequenceDuration.value);
                limitType.value = 'element';
            }
        } else {
            limitType.value = 'sequence';
            endTime.value = sequenceDuration.value ?? maxDuration.value;
        }

        setMediaSrcInStore(media.value);
        return true;
    },
    audio: () => {
        if (!sequenceData.value) return false;

        const { audioSrc, sequenceState, audioTimerangeSegments, sequenceDuration, maxDurationAudio } =
            sequenceData.value;
        media.value = audioSrc.value;
        playbackRate.value = 1;
        segments.value = audioTimerangeSegments.value;

        sequenceEndTime.value = sequenceDuration.value;

        startTime.value = sequenceState.value.audio.track.start.value;
        endTime.value = sequenceDuration.value ?? maxDurationAudio.value;

        setMediaSrcInStore(media.value);
        return true;
    },
    tts: () => {
        if (!voiceOverData.value) return false;

        const { voiceOverMediaState, timerangeSegments, sequenceDuration, maxDuration } = voiceOverData.value;

        media.value = voiceOverMediaState.value;
        playbackRate.value = 1;
        segments.value = timerangeSegments.value;
        sequenceEndTime.value = sequenceDuration.value;
        startTime.value = voiceOverMediaState.value.start;
        endTime.value = sequenceDuration.value ?? maxDuration.value;

        setMediaSrcInStore(media.value);
        return true;
    },
    recording: () => {
        if (!sequenceData.value) return false;

        const { backgroundVideo, seqPlaybackRate, backgroundVideoSegments, sequenceDuration, maxDuration } =
            sequenceData.value;
        media.value = backgroundVideo.value;
        playbackRate.value = seqPlaybackRate.value;
        segments.value = backgroundVideoSegments.value;
        sequenceEndTime.value = sequenceDuration.value;

        startTime.value = 0;
        endTime.value = sequenceDuration.value > 0 ? sequenceDuration.value : maxDuration.value;

        setMediaSrcInStore(media.value);
        return true;
    },
    recordingVisual: () => {
        if (!elementData.value) return false;

        const { videoSrc, elementState, elementPlaybackRate, visualSegments, sequenceDuration, maxDuration } =
            elementData.value;
        media.value = videoSrc.value;
        playbackRate.value = elementPlaybackRate.value;
        startTime.value = elementState.value.animation.start.value;
        startTime.value = elementState.value.animation.start.value;
        if (elementState.value.animation.end.value !== -1) {
            if (sequenceDuration.value === 0 || sequenceDuration.value === null) {
                endTime.value = elementState.value.animation.end.value;
                limitType.value = 'element';
            } else {
                endTime.value = Math.min(elementState.value.animation.end.value, sequenceDuration.value);
                limitType.value = 'element';
            }
        } else {
            limitType.value = 'sequence';
            endTime.value = sequenceDuration.value ?? maxDuration.value;
        }
        segments.value = visualSegments.value;
        sequenceEndTime.value = sequenceDuration.value;
        setMediaSrcInStore(media.value);
        return true;
    }
};

watch(
    isOpenQuickCut,
    (isOpen) => {
        if (!isOpen) return;
        const initializeFunction = initializeData[mediaType.value];

        if (initializeFunction && !initializeFunction()) {
            console.warn('Failed to initialize data for QuickCut');
            close();
            return;
        }

        if (segments.value && segments.value.length > 0) {
            updateSegments(segments.value);
        }
    },
    { immediate: true }
);

watch(
    media,
    (newMedia) => {
        if (newMedia && isOpenQuickCut.value && segments.value) {
            updateSegments(segments.value);
        }
    },
    { immediate: true }
);

watch(
    videoTimerangeSegments,
    (newSegments) => {
        segments.value = newSegments;
    },
    { deep: true }
);

const close = () => {
    segments.value = [];
    media.value = null;
    playbackRate.value = 1;
    startTime.value = 0;
    sequenceEndTime.value = 0;
    closeQuickCut();
};

const updateSegments = (newSegments) => {
    // Supprimer la propriété gapStyle de chaque segment
    newSegments.forEach((segment) => {
        delete segment.gapStyle;
    });

    // Calculer la durée totale disponible
    const totalDuration = (endTime.value - startTime.value) * playbackRate.value;

    // Si aucun segment n'existe, en créer un qui couvre toute la durée
    if (newSegments.length === 0) {
        newSegments = [
            {
                start: startTime.value,
                end: endTime.value
            }
        ];
    } else {
        // Calculer la somme des durées des segments
        const segmentsDuration = newSegments.reduce((sum, segment) => {
            return sum + (segment.end - segment.start);
        }, 0);

        // Si la durée totale des segments dépasse la durée disponible
        if (segmentsDuration > totalDuration) {
            let accumulatedDuration = 0;

            // Filtrer et ajuster les segments
            newSegments = newSegments.filter((segment, index) => {
                const segmentDuration = segment.end - segment.start;

                // Si l'ajout de ce segment ne dépasse pas encore la durée totale
                if (accumulatedDuration + segmentDuration <= totalDuration) {
                    accumulatedDuration += segmentDuration;
                    return true;
                }

                // Si c'est le premier segment qui dépasse
                // et qu'il reste de l'espace disponible
                if (accumulatedDuration < totalDuration) {
                    // Ajuster uniquement la fin du segment
                    const remainingSpace = totalDuration - accumulatedDuration;
                    segment.end = segment.start + remainingSpace;
                    return true;
                }

                return false;
            });
        }
    }

    // Mise à jour des segments selon le type de média
    if (['backgroundVideo', 'recording'].includes(mediaType.value) && sequenceData.value) {
        sequenceData.value.backgroundVideoSegments.value = newSegments;
    } else if (['visual', 'recordingVisual'].includes(mediaType.value) && elementData.value) {
        elementData.value.visualSegments.value = newSegments;
    } else if (mediaType.value === 'audio' && sequenceData.value) {
        sequenceData.value.audioTimerangeSegments.value = newSegments;
    } else if (mediaType.value === 'tts' && voiceOverData.value) {
        voiceOverData.value.timerangeSegments.value = newSegments;
    }

    segments.value = newSegments;
};

const sequenceDuration = computed(() => {
    return sequenceData.value?.sequenceDuration?.value ?? 0;
});

const elementState = computed(() => {
    return (
        elementData.value?.elementState?.value ?? {
            animation: {
                start: { value: 0 },
                end: { value: 0 }
            }
        }
    );
});
</script>
