<template>
    <div class="controls">
        <button class="history-button action-undo" :disabled="!canUndo" @click="undo">
            <svg-icon icon="undo-icon" />
        </button>
        <button class="history-button action-redo" :disabled="!canRedo" @click="redo">
            <svg-icon icon="redo-icon" />
        </button>
    </div>
</template>

<script setup lang="ts">
import { useHistory } from '@/js/videos/composables/useHistory';

// @ts-ignore - Variables are used in template
const { undo, redo, canUndo, canRedo } = useHistory();
</script>

<style lang="scss" scoped>
@import './quickcut-shared-style.scss';

.controls {
    display: flex;
    justify-content: space-between;
}

.history-button {
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
