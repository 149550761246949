import { executeRequest } from '@/js/infrastructure/api/apiClient.js';

const EDITION_URL = import.meta.env.VITE_EDITION_API_URL;

export default class SaveVideoApiService {
    constructor(store) {
        this.store = store;
    }

    handle(params) {
        let url = `${EDITION_URL}/api/videos/${params.videoId}`;

        if (params.type === 'sequence') {
            url = `${EDITION_URL}/api/sequences/${params.videoId}`;
        }

        if (params.type === 'template') {
            url = `${EDITION_URL}/api/templates/${params.videoId}`;
        }

        return executeRequest(
            this.store,
            {
                request: async (client) => {
                    return await client.post(url, params);
                },
                onSuccess: async (response) => response.data,
                actionType: 'SaveVideo'
            },
            true
        );
    }
}
