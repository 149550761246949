<template>
    <div class="current-time-bar-wrapper" :style="wrapperStyle" @pointerdown.stop="startDrag">
        <div class="current-time-bar">
            <div class="grab-area">
                <div class="inverted-house-container">
                    <div class="square"></div>
                    <div class="inverted-triangle"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, computed } from 'vue';

export default defineComponent({
    name: 'CurrentTimeBar',
    props: {
        position: {
            type: Number,
            required: true
        },
        canvasWidth: {
            type: Number,
            required: true
        },
        isDragging: {
            type: Boolean,
            required: true
        },
        isScrolling: {
            type: Boolean,
            required: true
        }
    },
    emits: ['startDrag'],
    setup(props, { emit }) {
        const wrapperStyle = computed(() => ({
            transform: `translateX(${Math.min(Math.max(props.position, 0), props.canvasWidth)}px)`
        }));

        const startDrag = (event) => {
            event.target.setPointerCapture(event.pointerId);
            emit('startDrag', event);
        };

        const stopDrag = () => {
            if (animationFrameId) {
                cancelAnimationFrame(animationFrameId);
            }
            document.removeEventListener('pointermove', onPointerMove);
            document.removeEventListener('pointerup', stopDrag);
        };

        return {
            wrapperStyle,
            startDrag
        };
    }
});
</script>

<style scoped>
.current-time-bar-wrapper {
    position: absolute;
    top: 0;
    width: 2px;
    height: 100%;
    z-index: 60;
    will-change: transform;
}

.current-time-bar {
    position: absolute;
    left: 50%;
    width: 2px;
    height: 60px;
    background-color: white;
    transform: translateX(-50%);
}

.grab-area {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 100px;
    cursor: col-resize;
}

.inverted-house-container {
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
    width: 10px;
    height: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 1;
}

.square {
    width: 10px;
    height: 10px;
    background-color: white;
}

.inverted-triangle {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid white;
}
</style>
