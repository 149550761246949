import TemplateApiService from '@/js/infrastructure/api/templates/TemplateApiService.js';

export default class TemplateDataService {
    constructor(store) {
        this.templateApiService = new TemplateApiService(store);
    }

    async duplicate(template) {
        let response = await this.templateApiService.duplicate(template);
        return response.data; // Return TemplateDTO when it possible
    }
}
