import { ref, watch, nextTick, inject, onMounted, onBeforeUnmount } from 'vue';
import { debounce } from 'lodash';

import { usePreview } from '@video-composables/usePreview';
import { useLoading } from '@video-composables/useLoading';
import { useAssetMedia } from '@video-composables/useAssetMedia';

let currentExtraction = null;

export function useMediaPlayer(
    videoSource,
    mediaType,
    onDurationLoaded,
    onAudioDataLoaded,
    sequenceProps,
    dragHandle,
    sequenceTotalTime,
    sequenceId,
    elementId
) {
    const videoStudio = inject('$videoStudio');
    const { currentTimelineId } = usePreview();
    const { studioLoading } = useLoading();
    const { getAssetMedia } = useAssetMedia();

    const EPSILON = 0.001;
    const isUpdating = ref(false);
    const videoWidth = ref(0);
    const videoHeight = ref(0);
    const videoEndTime = ref(0);
    const hiddenVideoElement = ref(null);

    watch(studioLoading, (newValue) => {
        if (newValue) {
            isUpdating.value = false;
        }
    });

    watch(() => videoSource.value.src, handleSourceChange);

    async function waitForVideoLoad(assetVideo) {
        // If it's an image (img element)
        if (assetVideo instanceof HTMLImageElement) {
            return assetVideo;
        }

        // If it's a video object with stage getBackgroundElement
        const video = assetVideo.getVideoElement?.() || assetVideo;

        // If video is already loaded (readyState >= 2), return directly
        if (video.readyState >= 2) {
            return video;
        }

        // Otherwise, wait for canplay event
        return new Promise((resolve) => {
            const onCanPlay = () => {
                video.removeEventListener('canplay', onCanPlay);
                resolve(video);
            };
            video.addEventListener('canplay', onCanPlay);
        });
    }

    async function handleSourceChange(newSrc) {
        if (!newSrc) return;

        await nextTick();
        const assetMedia = getAssetMedia(mediaType.value, sequenceId, elementId);
        if (!assetMedia) {
            console.error('AssetMedia not found');
            return;
        }

        const video = await waitForVideoLoad(assetMedia);

        videoEndTime.value = video.duration;

        if (['audio', 'tts'].includes(mediaType.value)) {
            videoWidth.value = 1920;
            videoHeight.value = 100;
        } else {
            videoWidth.value = video.videoWidth;
            videoHeight.value = video.videoHeight;
        }

        onDurationLoaded(video.duration);
        onAudioDataLoaded(videoSource.value.src);
        sequenceProps.rate = await calculateMediaRate(video);
    }

    async function calculateMediaRate(media) {
        return 100;

        // // Si c'est un fichier audio, retourner directement 1
        // if (['audio', 'tts'].includes(mediaType.value)) {
        //     return 1;
        // }

        // const videoDuration = media.duration;
        // const frameCount = media.mozDecodedFrames || media.webkitDecodedFrames || media.decodedFrames;
        // const timeBetweenFrames = videoDuration / frameCount;

        // // Si les valeurs sont invalides ou si c'est un fichier audio, retourner 1
        // if (isNaN(timeBetweenFrames) || !isFinite(timeBetweenFrames)) {
        //     return 1;
        // }

        // return Math.max(1, Math.round(timeBetweenFrames / (1 / 30)));
    }

    function estimateFPS(video) {
        if (!video) return 0;

        const commonFrameRates = [24, 30, 60];
        return commonFrameRates.reduce((closestRate, currentRate) => {
            const currentDifference = Math.abs(videoEndTime.value / video.duration - 1 / currentRate);
            const closestDifference = Math.abs(videoEndTime.value / video.duration - 1 / closestRate);
            return currentDifference < closestDifference ? currentRate : closestRate;
        }, commonFrameRates[0]);
    }

    async function calculateBitrate(source, duration) {
        //simplification pour ne pas avoir à charger le fichier audio
        return 100;
    }

    function createHiddenVideoElement() {
        if (!hiddenVideoElement.value) {
            hiddenVideoElement.value = document.createElement('video');
            hiddenVideoElement.value.style.display = 'none';
            document.body.appendChild(hiddenVideoElement.value);
        }
    }

    onBeforeUnmount(() => {
        if (hiddenVideoElement.value) {
            hiddenVideoElement.value.remove();
        }
    });

    const extractFrames = async (canvasElement, canvasWidth, canvasHeight) => {
        if (!canvasElement) return;

        if (currentExtraction) {
            currentExtraction.cancel();
        }

        createHiddenVideoElement();

        const assetMedia = getAssetMedia(mediaType.value, sequenceId, elementId);
        if (!assetMedia) {
            console.error('AssetMedia not found');
            return;
        }

        hiddenVideoElement.value.src = videoSource.value.src;
        const video = await waitForVideoLoad(hiddenVideoElement.value);

        // Vérification adaptée pour inclure les fichiers audio
        if (!['audio', 'tts'].includes(mediaType.value) && (!video.videoWidth || !video.videoHeight)) {
            console.error('Invalid video properties:', {
                width: video.videoWidth,
                height: video.videoHeight,
                duration: video.duration
            });
            return;
        }

        if (!video.duration) {
            console.error('Invalid media duration');
            return;
        }

        const context = canvasElement.getContext('2d');
        if (!context) return;

        const videoAspectRatio = video.videoWidth / video.videoHeight;

        const frameWidth = Math.ceil(canvasHeight * videoAspectRatio);
        const frameHeight = canvasHeight;

        canvasElement.width = canvasWidth;
        canvasElement.height = canvasHeight;

        const frameCount = Math.max(2, Math.ceil(canvasWidth / frameWidth) + 1);

        const spacing = canvasWidth / (frameCount - 1);

        const videoDuration = video.duration;

        const timeBetweenFrames = Math.max(0.1, videoDuration / (frameCount - 1));

        if (!isFinite(timeBetweenFrames) || timeBetweenFrames <= 0) {
            return;
        }

        context.clearRect(0, 0, canvasElement.width, canvasElement.height);

        let isCancelled = false;
        currentExtraction = {
            cancel: () => {
                isCancelled = true;
            }
        };

        function seekVideo(video, time) {
            return new Promise((resolve) => {
                const onSeeked = () => {
                    video.removeEventListener('seeked', onSeeked);
                    resolve();
                };
                video.addEventListener('seeked', onSeeked);
                const clampedTime = Math.max(0, Math.min(time, video.duration));
                video.currentTime = clampedTime;
            });
        }

        // Start by drawing the first frame everywhere
        seekVideo(video, 0)
            .then(() => {
                if (isCancelled) return;
                for (let i = 0; i < frameCount; i++) {
                    drawFrame(i, 0);
                }
                // Then draw the last frame
                return seekVideo(video, Math.min(videoEndTime.value, video.duration));
            })
            .then(() => {
                if (isCancelled) return;
                drawFrame(frameCount - 1, videoEndTime.value);
                processFrame(0);
            });

        function processFrame(frameIndex) {
            if (isCancelled || frameIndex >= frameCount - 1) {
                currentExtraction = null;
                return;
            }

            const frameTime = frameIndex * timeBetweenFrames;

            if (!isFinite(frameTime) || frameTime < 0 || frameTime > video.duration) {
                console.error('Skipping invalid frame time:', {
                    frameTime,
                    frameIndex,
                    timeBetweenFrames,
                    duration: video.duration
                });
                processFrame(frameIndex + 1);
                return;
            }

            seekVideo(video, frameTime).then(() => {
                if (isCancelled) return;
                drawFrame(frameIndex, frameTime);
                // Utilise nextTick pour permettre au navigateur de mettre à jour l'UI
                nextTick(() => processFrame(frameIndex + 1));
            });
        }

        function drawFrame(frameIndex, time) {
            // Calculer la position du centre de la frame
            const centerX = frameIndex * spacing;

            // Calculer la position de départ pour dessiner la frame
            let xPosition = centerX - frameWidth / 2;

            // Ajuster la position pour les frames aux extrémités
            if (frameIndex === 0) {
                xPosition = 0;
            } else if (frameIndex === frameCount - 1) {
                xPosition = canvasWidth - frameWidth;
            }

            // Dessiner la frame
            context.drawImage(video, xPosition, 0, frameWidth, frameHeight);
        }
    };

    const initializeTimeline = () => {
        nextTick(() => {
            if (currentTimelineId.value) {
                executeSeekWhenReady(sequenceProps.startTime + EPSILON);
            }
        });
    };

    function performSeekOperation(elapsedTime) {
        if (isUpdating.value) {
            return;
        }

        const debouncedSeek = debounce((time) => {
            if (!isUpdating.value) {
                executeSeek(time);
            }
        }, 50);

        debouncedSeek(elapsedTime);
    }

    function simpleSeek(elapsedTime) {
        if (isUpdating.value) {
            return;
        }

        if (elapsedTime <= 0) {
            elapsedTime = EPSILON;
        }
        const seekTime = elapsedTime + sequenceProps.startTime;
        executeSeekWhenReady(seekTime);
    }

    function executeSeek(elapsedTime) {
        const seekTime = calculateSeekTime(elapsedTime);

        //verify if the seek time is within the video duration
        if (seekTime > sequenceTotalTime.value) {
            simpleSeek(sequenceTotalTime.value - 1);
            return;
        }

        simpleSeek(seekTime);
    }

    function executeSeekWhenReady(seekTime) {
        if (isUpdating.value) {
            return;
        }

        const performSeek = () => {
            isUpdating.value = true;
            nextTick(() => {
                videoStudio.value.studio.$stage.seekSequenceTimeline(currentTimelineId.value, seekTime);
                // Reset le flag après un court délai pour permettre la stabilisation
                setTimeout(() => {
                    isUpdating.value = false;
                }, 50);
            });
        };

        if (!studioLoading.value) {
            performSeek();
        } else {
            const unwatch = watch(studioLoading, (newValue) => {
                if (!newValue) {
                    performSeek();
                    unwatch();
                }
            });
        }
    }

    function calculateSeekTime(elapsedTime) {
        if (dragHandle.value === 'start' && sequenceTotalTime.value > elapsedTime) {
            return elapsedTime + EPSILON;
        } else if (dragHandle.value === 'end' && elapsedTime > sequenceProps.startTime) {
            return elapsedTime - EPSILON;
        }

        return elapsedTime - EPSILON;
    }

    return {
        videoEndTime,
        videoWidth,
        videoHeight,
        extractFrames,
        performSeekOperation,
        initializeTimeline,
        simpleSeek
    };
}
