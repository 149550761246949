<template>
    <ui-scrollable class="ui-sequence-list ui-sequence-list-items" :class="scrollableClasses">
        <draggable
            :list="sequenceList"
            :disabled="!canMoveSequence || this.lockedScroll"
            handle=".ui-sidebar-item-handle"
            draggable=".ui-sequence-list-item"
            :delay="100"
            :force-fallback="true"
            @start="handleStartDrag"
            @end="handleEndDrag"
            @change="handleChangeSequences"
            item-key="id"
        >
            <template #item="{ element }">
                <ui-sequence-list-item v-on="itemListeners" :id="element" />
            </template>

            <template #footer v-if="canAddEmptySequence">
                <li class="ui-add-sequence-item">
                    <ui-sequence-library-selector
                        v-if="canUseSequenceTemplate"
                        ref="$addSequenceSelector"
                        id="add-sequence-library"
                        key="add-sequence-library"
                        class="ui-add-sequence-button video-onboarding-step-2"
                        :create-index="null"
                        :title="addSequenceLabel"
                        :label="addSequenceLabel"
                        :show-label="false"
                        icon="fa-solid fa-plus"
                        :disabled="readOnly"
                        @[librarySelectorShowEvent]="openSequenceLibrary"
                        @[librarySelectorPreviewChangeEvent]="previewSequenceTemplate"
                        @[librarySelectorChangeEvent]="applySequenceTemplate"
                    />
                    <button
                        v-else
                        class="ui-add-sequence-button video-onboarding-step-2"
                        :title="addSequenceLabel"
                        v-tooltip="$t('Add a sequence')"
                        @click="handleAddEmptySequence($event)"
                    >
                        <span class="visually-hidden">{{ addSequenceLabel }}</span>
                        <fa-icon class="icon" icon="fa-solid fa-plus" />
                    </button>
                </li>
            </template>
        </draggable>
    </ui-scrollable>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import Draggable from 'vuedraggable';
import { DRAGGING_CLASS } from '../../../../constants';
import { getPrefixedUuid } from '../../../../utils';
import { InteractsWithSequenceLibrary, ModifiesHistory, SavesVideo } from '../../../mixins';
import { UiIcon, UiScrollable } from '../../../../components';
import UiSequenceLibrarySelector from '../UiSequenceLibrarySelector.vue';
import UiSequenceListItem, * as UiSequenceListItemEvent from './UiSequenceListItem.vue';
import { UsesTooltip } from '../../../../mixins';

export default {
    mixins: [InteractsWithSequenceLibrary, ModifiesHistory, SavesVideo, UsesTooltip],

    inject: ['$videoStudio'],

    components: {
        Draggable,
        UiIcon,
        UiScrollable,
        UiSequenceListItem,
        UiSequenceLibrarySelector
    },

    props: {
        //
    },

    data() {
        return {
            lockedScroll: 0,

            addSequenceLabel: this.$t('Add a sequence'),
            isSelectingItem: false
        };
    },

    computed: {
        ...mapState({
            readOnly: (state) => state.ui.readOnly,
            editionMode: (state) => state.ui.editionMode,
            currentEditedItemId: (state) => state.ui.currentEditedItemId,
            sequenceIdPrefix: (state) => state.ui.prefixes.sequenceId,
            currentEditedElement: (state) => state.edition.editingElement
        }),

        /*...mapGetters({
            currentEditedItem: 'ui/currentEditedItem',
            canAddEmptySequence: 'ui/canAddEmptySequence',
            canAddSequence: 'ui/canAddSequence',
            canMoveSequence: 'ui/canMoveSequence',
            canCopySequence: 'ui/canCopySequence',
            canPasteSequence: 'ui/canPasteSequence',
            canCreateSequenceTemplate: 'ui/canCreateSequenceTemplate',
            canUseSequenceTemplate: 'ui/canUseSequenceTemplate',
            canRemoveSequence: 'ui/canRemoveSequence',
            sequenceList: 'sequences/allIds'
        }),*/

        ...mapGetters({
            currentEditedItem: 'ui/currentEditedItem',
            canAddEmptySequence: 'ui/canAddEmptySequence',
            canAddSequence: 'ui/canAddSequence',
            canMoveSequence: 'ui/canMoveSequence',
            canCopySequence: 'ui/canCopySequence',
            canPasteSequence: 'ui/canPasteSequence',
            canCreateSequenceTemplate: 'ui/canCreateSequenceTemplate',
            canUseSequenceTemplate: 'ui/canUseSequenceTemplate',
            canRemoveSequence: 'ui/canRemoveSequence',
            sequences: 'sequences/all'
            // sequencesVisible: 'sequences/allVisible',
        }),

        sequenceList() {
            return this.sequences.map((sequence) => sequence.id);
        },

        scrollableClasses() {
            return {
                locked: !!this.lockedScroll
            };
        },

        itemListeners() {
            return {
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_SELECT]: this.handleItemSelect,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_ACTIONS_OPEN]: this.handleItemActionsOpen,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_ACTIONS_CLOSE]: this.handleItemActionsClose,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_DUPLICATE]: this.handleItemDuplicate,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_COPY]: this.handleItemCopy,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_PASTE_AFTER]: this.handleItemPasteAfter,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_TOGGLE_HIDE]: this.handleItemToggleHide,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_SAVE_TEMPLATE]: this.handleItemSaveTemplate,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_DELETE]: this.handleItemDelete,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_ADD]: this.handleAddEmptySequence,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_PREVIEW]: this.handleAddedSequencePreview,
                [UiSequenceListItemEvent.UI_SEQUENCE_ITEM_APPLY_TEMPLATE]: this.handleApplySequenceTemplate
            };
        }
    },

    watch: {
        currentEditedElement(newValue) {
            if (!!newValue && newValue.seqId && newValue.seqId != this.currentEditedItemId) {
                this.startHistoryStep();
                this.$store.dispatch('ui/updateCurrentEditedItem', newValue.seqId);
            } else if (!!newValue && newValue.containerID && newValue.id != this.currentEditedItemId) {
                this.startHistoryStep();
                this.$store.dispatch('ui/updateCurrentEditedItem', newValue.id);
            }
        }
    },

    methods: {
        handleStartDrag() {
            document.body.classList.toggle(DRAGGING_CLASS, true);
        },

        handleEndDrag() {
            document.body.classList.toggle(DRAGGING_CLASS, false);
        },

        handleChangeSequences(change) {
            if (change.moved) {
                this.startHistoryStep();
                this.$store.dispatch('sequences/moveSequence', {
                    id: change.moved.element,
                    index: change.moved.newIndex
                });
                this.saveVideo();
            }
        },

        handleAddEmptySequence(event, item, selector, index) {
            if (this.canAddEmptySequence) {
                if (this.canUseSequenceTemplate) {
                    this.$videoStudio.studio.$stage.pauseTimeline();
                    this.openSequenceLibrary(selector);
                } else {
                    this.$videoStudio.studio.$stage.pauseTimeline();
                    this.startHistoryStep();
                    let id = getPrefixedUuid(this.sequenceIdPrefix);
                    this.$store.dispatch('sequences/addEmptySequence', {
                        id: id,
                        index: index != null ? index + 1 : null
                    });
                    this.saveVideo();
                    this.$store.dispatch('ui/updateCurrentEditedItem', id);
                    event.currentTarget.blur();
                }
            }
        },

        handleAddedSequencePreview(item, selector, templateData, originalState, cancel) {
            this.previewSequenceTemplate(templateData, originalState, cancel);
        },

        handleApplySequenceTemplate(item, selector, templateData, originalState) {
            this.applySequenceTemplate(templateData, originalState);
        },

        handleItemSelect(item) {
            if (this.isSelectingItem || item.id === this.currentEditedItemId) {
                return;
            }

            try {
                this.isSelectingItem = true;
                this.startHistoryStep();

                this.$store.commit('ui/setCurrentEditedItem', item.id);

                this.$nextTick(() => {
                    this.isSelectingItem = false;
                });
            } catch (error) {
                console.warn('Error in handleItemSelect:', error);
                this.isSelectingItem = false;
            }
        },
        handleItemActionsOpen(item) {
            this.lockedScroll++;
        },

        handleItemActionsClose(item) {
            this.lockedScroll--;
        },

        handleItemDuplicate(item) {
            if (this.canAddSequence) {
                //Fix temporary
                this.$videoStudio.studio.$stage.releaseTimeline();
                // this.$videoStudio.studio.$stage.pauseTimeline();
                this.startHistoryStep();
                this.$store.dispatch('sequences/' + item.id + '/duplicateSelf');
                this.saveVideo();
            }
        },

        handleItemCopy(item) {
            if (this.canCopySequence) {
                this.$store.dispatch('sequences/' + item.id + '/copyToClipboard');
            }
        },

        handleItemPasteAfter(item, index) {
            if (this.canPasteSequence) {
                // this.$videoStudio.studio.$stage.pauseTimeline();
                this.startHistoryStep();
                this.$store.dispatch('sequences/' + item.id + '/pasteAfter');
                this.saveVideo();
            }
        },

        handleItemToggleHide(item, currentlyHidden) {
            // this.$videoStudio.studio.$stage.pauseTimeline();
            this.startHistoryStep();
            this.$store.dispatch('sequences/' + item.id + '/toggleHide');
            this.saveVideo();
        },

        handleItemSaveTemplate(item) {
            if (this.canCreateSequenceTemplate) {
                this.$store.commit('ui/modals/add', {
                    id: 'save-sequence-template',
                    type: 'modal-create-sequence-template',
                    sequenceId: item.id
                });
            }
        },

        handleItemDelete(item) {
            if (this.canRemoveSequence) {
                this.startHistoryStep();

                if (item.id === this.currentEditedItemId) {
                    // Find next or previous sequence
                    const currentIndex = this.sequenceList.indexOf(item.id);
                    let nextSequenceId;

                    if (currentIndex < this.sequenceList.length - 1) {
                        nextSequenceId = this.sequenceList[currentIndex + 1];
                    } else if (currentIndex > 0) {
                        nextSequenceId = this.sequenceList[currentIndex - 1];
                    }

                    // If we found an alternative sequence
                    if (nextSequenceId) {
                        // First change current sequence
                        this.$store.dispatch('ui/updateCurrentEditedItem', nextSequenceId).then(() => {
                            // Then delete old sequence
                            this.$store.dispatch('sequences/' + item.id + '/removeSelf');
                            this.saveVideo();
                        });
                    }
                } else {
                    // If not current sequence, direct deletion
                    this.$store.dispatch('sequences/' + item.id + '/removeSelf');
                    this.saveVideo();
                }
            }
        }
    },

    mounted() {
        //
    }
};
</script>
