const state = () => ({
    opened: false,
    disableClose: false,
    currentModal: null,
    modalData: {},
    modalNavActiveTab: null
});

const getters = {
    opened: (state) => state.opened,
    disableClose: (state) => state.disableClose,
    modalData: (state) => state.modalData,
    currentModal: (state) => state.currentModal,
    modalNavActiveTab: (state) => state.modalNavActiveTab
};

const mutations = {
    setOpened(state, opened) {
        state.opened = opened;
    },

    setDisableClose(state, disableClose) {
        state.disableClose = disableClose;
    },

    setCurrentModal(state, modal) {
        state.currentModal = modal;
    },

    setModalData(state, data) {
        state.modalData = data;
    },

    setModalNavActiveTab(state, tab) {
        state.modalNavActiveTab = tab;
    }
};

const actions = {
    openModal({ commit }, modal) {
        commit('setOpened', true);
        commit('setCurrentModal', modal.name);
        commit('setDisableClose', !!modal.disableClose);
        commit('setModalData', modal);
    },

    closeModal({ commit }) {
        commit('setOpened', false);
        commit('setCurrentModal', null);
    },

    closeModalByName({ state, dispatch }, name) {
        if (state.currentModal === name) {
            dispatch('closeModal');
        }
    },

    setModalNavActiveTab({ commit }, tab) {
        commit('setModalNavActiveTab', tab);
    }
};

export default {
    namespaced: true,

    state,
    getters,
    mutations,
    actions
};
